import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { SignalCellularConnectedNoInternet4BarRounded } from '@mui/icons-material';


import { WiRain, WiRaindrops, 
    WiRaindrop, WiThermometerExterior, WiThermometerInternal,
    WiStrongWind, WiWindy, WiBarometer} from "react-icons/wi";

import { BsCloudDrizzleFill, BsCloudDrizzle, BsCloudRain, BsCloudRainFill, 
    BsSun, BsSunFill,
    BsEyeglasses, BsEyeFill, BsEye, BsBinocularsFill, BsBinoculars, BsArrowDownUp, BsArrowLeftRight, BsArrowBarUp, BsArrowUpShort, BsArrowRightShort, BsArrowRight, BsArrowUp,
    BsSnow, BsSnow2, BsSnow3,
    BsSpeedometer,
    BsTsunami, BsArrowDown, BsArrowBarDown
} from "react-icons/bs";


import { GiDew } from "react-icons/gi";

import { MdDewPoint, MdNavigation } from "react-icons/md";
import { HiOutlineArrowPath } from "react-icons/hi2";
// import { TbNavigationFilled, TbNavigation } from "react-icons/tb";

import { FaLocationArrow } from "react-icons/fa";
import { PiNavigationArrowFill, PiNavigationArrowThin, PiWaveSineBold } from "react-icons/pi";


export function Dirsvg(props) {



    let received_at = props.received_at;
    let received_time = props.received_time;
    let received_date = props.received_date;
    let WBGT = Number(props.WBGT);
    let WBT = Number(props.WBT);
    let TG = Number(props.TG);
    let Wind_ave = Number(props.Wind_ave);
    let Wind_3sgust = Number(props.Wind_3sgust);
    let Wind_3smin = Number(props.Wind_3smin);
    let Wind_stdev = Number(props.Wind_stdev);

    // let Dir_ave = Number(props.Dir_ave);
    let Dir_ave = props.Dir_ave;
    // let Dir_3sgust = Number(props.Dir_3sgust);
    let Dir_3sgust = props.Dir_3sgust;
    let Dir_stdev = Number(props.Dir_stdev);
    let gust_3stime = Number(props.gust_3stime);
    let Pressure = Number(props.Pressure);
    let Hum_SHT = Number(props.Hum_SHT);
    let TempC_DS = Number(props.TempC_DS);
    let TempC_SHT = Number(props.TempC_SHT);
    let WTMP = Number(props.WTMP);
    // let Temp_Red = Number(props.Temp_Red);
    // let Temp_White = Number(props.Temp_White);
    let end_device_ids = Number(props.end_device_ids);

    let Type = Number(props.Type);
    let T_min = Number(props.T_min);
    let T_max = Number(props.T_max);
    let Irradiation = Number(props.Irradiation);
    let Irr_max = Number(props.Irr_max);

    let Rain = Number(props.Rain);
    let Rain_time = Number(props.Rain_time);
    let Frost_alert = Number(props.Frost_alert);
    let Heater_on = Number(props.Heater_on);
    let RainIntensityCorrection = Number(props.RainIntensityCorrection);

    let Rain_rate = Number(props.Rain_rate);


    let DewP = Number(props.DewP);
    let Visib = Number(props.Visib);
    let vertVis = Number(props.vertVis);
    let Snow = Number(props.Snow);




    let WVHT = Number(props.WVHT); // - Hauteur significative des vagues  = Number(props.en mètres);
    let DPD = Number(props.DPD); // - Période de vague dominante  = Number(props.en secondes);
    let APD = Number(props.APD); // - Période moyenne de vague  = Number(props.en secondes);
    let MWD = Number(props.MWD); // - Direction moyenne des vagues  = Number(props.en degrés);

    let PTDY = Number(props.PTDY); // - Changement de pression en 3 heures  = Number(props.en hPa);

    let TIDE = Number(props.TIDE); // - Niveau de la marée
    let hw = Number(props.hw); // - marée haute
    let hw_time = Number(props.hw_time); // - heure marée haute
    let lw = Number(props.lw); // - marée basse
    let lw_time = Number(props.lw_time); // - heure marée basse



    // let hours = Number(props.hours);
    let hours = props.hours;

    let M = props.M;
    let CAV = props.CAV;

    let LowLim = props.LowLim;
    let HighLim = props.HighLim;
    let ExtremLim = props.ExtremLim;
    let RiskLim = props.RiskLim;
    
    let ColdLim = props.ColdLim;

    // // console.log('hours')
    // // console.log(hours)

    // console.log('WVHT')
    // console.log(WVHT)
    // console.log('DPD')
    // console.log(DPD)
    // console.log('APD')
    // console.log(APD)
    // console.log('MWD')
    // console.log(MWD)
    // console.log('PTDY')
    // console.log(PTDY)
    // console.log('TIDE')
    // console.log(TIDE)



    Irradiation = (Irradiation!==null) ? Irradiation : 'NaN';
    Irr_max = (Irr_max!==null) ? Irradiation : 'NaN';
    Rain = (Rain!==null) ? Rain : 'NaN';
    Rain_time = (Rain_time!==null) ? Rain_time : 'NaN';
    Pressure = (Pressure!==null) ? Pressure : 'NaN';

    Hum_SHT = (Hum_SHT!==null) ? Hum_SHT : 'NaN';

    TempC_DS = (TempC_DS!==null) ? TempC_DS : 'NaN';
    TempC_SHT = (TempC_SHT!==null) ? TempC_SHT : 'NaN';
    T_min = (T_min!==null) ? T_min : 'NaN';
    T_max = (T_max!==null) ? T_max : 'NaN';
    
    WTMP = (WTMP!==null) ? WTMP : 'NaN';
    // Temp_Red = (Temp_Red!==0) ? Temp_Red : 'NaN';
    // Temp_White = (Temp_White!==0) ? Temp_White : 'NaN';
    
    Rain_rate = (Rain_rate!==null) ? Rain_rate : 'NaN';

    


  //let eau=(WTMP+Temp_Red+Temp_White)/3;
//   let eau=((Number(Temp_Red)+Number(WTMP)+Number(Temp_White))/3).toFixed(1);
  //let eau= Number(Temp_Red);
  //let eau=Temp_White;
  //WTMP":"13.50","Temp_Red":"13.50","Temp_White":"6.40"
  //let eau=(Number(Temp_Red)+Number(WTMP));
  //WTMP":"13.50","Temp_Red":"14.40","Temp_White":"14.50"

  return (
    <Container fluid>
        

        <Row  xs={2} >

                {(() => {
                    if (isNaN(WBGT)==0) {
                        // let fillColor;
                        // if (WBGT >= HighLim) {
                        //     fillColor = 'red';
                        // } else if (WBGT >= LowLim) {
                        //     fillColor = 'orange';
                        // } else {
                        //     fillColor = 'green';
                        // }

                        // "#4CBFC0"
                        // const fillColor = WBGT >= HighLim ? '#FF5733' : WBGT >= LowLim ? '#FFD700' : '#32CD32';
                        // const fillColor = WBGT >= HighLim ? 'red' : WBGT >= LowLim ? 'orange' : 'green';
                        
                        // const fillColor = WBGT >= HighLim ? 'rgba(255, 0, 0, 0.5)' : WBGT >= LowLim ? 'rgba(255, 125, 0, 0.55)' : 'rgba(0, 255, 0, 0.5)';
                        let fillColor;
                        if (WBGT >= RiskLim) {
                            fillColor = '#960000';//'rgba(255, 0, 0, 0.8)'; // '#33CD32'
                        } else if (WBGT >= ExtremLim) {
                            fillColor = '#DC3545';//'rgba(255, 125, 0, 0.85)'; // '#FFC008'
                        } else if (WBGT >= HighLim) {
                            fillColor = '#FFA500';//'rgba(255, 125, 0, 0.85)'; // '#FFC008'
                        } else if (WBGT >= LowLim) {
                            fillColor = '#FFE132';//'rgba(255, 125, 0, 0.85)'; // '#FFC008'
                        } else {
                            fillColor = '#28A745';//'rgba(0, 255, 0, 0.8)'; // #
                        }
                        return (
                            <>
                            {/* <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 120" text-align="center">
                            <text textAnchor="middle" x="125" y="80" fill={fillColor}  fontSize="40" fontWeight="bold">WBGT</text>
                            <text textAnchor="middle" x="220" y="60" fill='#DC3545'  fontSize="20" fontWeight="bold">{HighLim.toFixed(1)}</text>
                            <text textAnchor="middle" x="220" y="90" fill='#FFA500'  fontSize="20" fontWeight="bold">{LowLim.toFixed(1)}</text>
                            </svg>
                            </Col> */}
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 120" text-align="center">
                                <text textAnchor="middle" x="145" y="80" fill={fillColor}  fontSize="80" fontWeight="bold">{WBGT.toFixed(1)}</text>
                                <text textAnchor="middle" x="235" y="80" fill="gray"  fontSize="15" fontWeight="normal">°C</text> 
                                {/* normal bold bolder lighter */}
                                {/* 100 200 300 400 500 600 700 800 900 */}
                                {/* <text textAnchor="middle" x="200" y="80" fill="#2a4999"  fontSize="30" fontWeight="bold">°C</text> */}
                            </svg>
                            </Col>
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 120" text-align="center">
                            <text textAnchor="middle" x="110" y="80" fill={fillColor}  fontSize="60" fontWeight="bold">WBGT</text>
                            {/* <text textAnchor="middle" x="220" y="60" fill='#DC3545'  fontSize="20" fontWeight="bold">{HighLim.toFixed(1)}</text>
                            <text textAnchor="middle" x="220" y="90" fill='#FFA500'  fontSize="20" fontWeight="bold">{LowLim.toFixed(1)}</text> */}
                            </svg>
                            </Col>
                            </>
                        )
                    }
                })()}
        
                {(() => {
                    if (isNaN(Wind_ave)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" text-align="center">
                                <text textAnchor="middle" x="110" y="180" fill=" #46BDFB"  fontSize="80" fontWeight="bold">{Wind_ave.toFixed(1)}</text>
                                <text textAnchor="middle" x="225" y="180" fill="#2a4999"  fontSize="30" fontWeight="bold"> kts</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (Dir_ave!=null) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                {(() => {
                                    if (isNaN(Number(Dir_ave))==0) {
                                        return (
                                        <g  opacity="0.8" transform={`rotate(${Dir_ave} 125 125)`} fill="#46BDFB">
                                            <path  id="fleche" d="M125,50 L75,180 L125,155 L175,180 L125,50 z" transform={`rotate(180 125 125)`}></path>
                                        </g>
                                    )}
                                    else {
                                        return (
                                        <g  opacity="0.8">
                                            <HiOutlineArrowPath size={100}  stroke="#46BDFB" x="75" y="75"/>
                                        </g>
                                    )}
                                })()}

                                {/* <g  opacity="0.8" transform={`rotate(${Dir_ave} 125 125)`} fill="#46BDFB">
                                    <path  id="fleche" d="M125,50 L75,180 L125,155 L175,180 L125,50 z" transform={`rotate(180 125 125)`}></path>
                                </g> */}

                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(0 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(45 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(90 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(135 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(180 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(225 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(270 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(315 125 125)"/>
                                <WiWindy size={50}  x="50" y="210" fill="#2a4999"/>
                                <text textAnchor="left" x="105" y="249" fill="#2a4999"  fontSize="25" fontWeight="bold">{Dir_ave}°</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}


                {(() => {
                    if (isNaN(Wind_3sgust)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                <text textAnchor="middle" x="110" y="180" fill="#eb3434"  fontSize="80" fontWeight="bold">{Wind_3sgust.toFixed(1)}</text>
                                <text textAnchor="middle" x="225" y="180" fill="#2a4999"  fontSize="30" fontWeight="bold"> kts</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (Dir_3sgust!=null) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                <g  opacity="0.8" transform={`rotate(${Dir_3sgust} 125 125)`} fill="#eb3434">
                                    <path  id="fleche" d="M125,50 L75,180 L125,155 L175,180 L125,50 z" transform={`rotate(180 125 125)`}></path>
                                </g>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(0 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(45 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(90 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(135 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(180 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(225 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(270 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(315 125 125)"/>
                                <WiStrongWind size={50}  x="50" y="210" fill="#2a4999"/>
                                <text textAnchor="left" x="105" y="249" fill="#2a4999"  fontSize="25" fontWeight="bold">{Dir_3sgust}°</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(WTMP)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                <g  opacity="0.8" fill="#00abfb">
                                <path d="M120 50 C 170 120, 70 120, 120 50" transform="rotate(0 0 0) translate(-95 -30) scale(1.8 1.8)" />
                                <circle cx="130" cy="135" r="5"  fill="white" />
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Water: {eau} °C</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">Eau: <tspan fontSize="30">{WTMP}</tspan> °C</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}
                
                {(() => {
                    if (isNaN(Pressure)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                <g transform="rotate(0 0 0) translate(80 50) scale(0.2 0.2)" fill="#C9CBCF">
                                	<g>
                                		<path d="M256,0C114.51,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.49,0,256-114.497,256-256
                                			C512,114.509,397.503,0,256,0z M256,478.609c-122.746,0-222.609-99.862-222.609-222.609S133.254,33.391,256,33.391
                                			S478.609,133.254,478.609,256S378.746,478.609,256,478.609z"/>
                                	</g>
                                
                                	<g>
                                		<path d="M256,66.783C151.29,66.783,66.783,151.738,66.783,256c0,48.619,18.872,97.248,55.421,133.797
                                			c6.52,6.52,17.091,6.52,23.611,0l23.611-23.611c6.52-6.519,6.52-17.09,0-23.611c-6.519-6.52-17.09-6.52-23.611,0l-11.177,11.177
                                			c-19.241-23.851-30.408-52.1-33.501-81.056h15.734c9.22,0,16.696-7.475,16.696-16.696c0-9.22-7.475-16.696-16.696-16.696h-15.725
                                			c3.094-28.955,14.261-57.198,33.5-81.05l11.17,11.169c6.52,6.52,17.091,6.52,23.611,0c6.519-6.519,6.519-17.091,0-23.611
                                			l-11.175-11.175c23.276-18.804,51.227-30.356,81.054-33.5v15.732c0,9.22,7.475,16.696,16.696,16.696
                                			c9.22,0,16.696-7.475,16.696-16.696v-15.731c29.827,3.144,57.777,14.698,81.054,33.5l-72.032,72.032
                                			c-7.699-4.03-16.444-6.323-25.719-6.323c-30.687,0-55.652,24.966-55.652,55.652c0,30.687,24.966,55.652,55.652,55.652
                                			c30.687,0,55.652-24.966,55.652-55.652c0-9.275-2.293-18.02-6.323-25.718l72.026-72.026c19.239,23.85,30.406,52.094,33.5,81.05
                                			H395.13c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696h15.734c-3.093,28.956-14.26,57.206-33.501,81.056
                                			l-11.177-11.177c-6.519-6.519-17.091-6.519-23.611,0c-6.52,6.52-6.52,17.091,0,23.611l23.611,23.611
                                			c6.52,6.52,17.091,6.52,23.611,0c36.482-36.483,55.421-85.084,55.421-133.798C445.217,151.681,360.676,66.783,256,66.783z
                                			 M256,278.261c-12.275,0-22.261-9.986-22.261-22.261c0-12.275,9.986-22.261,22.261-22.261c12.275,0,22.261,9.986,22.261,22.261
                                			C278.261,268.275,268.275,278.261,256,278.261z"/>
                                	</g>
                                
                                	<g>
                                		<path d="M272.696,345.043h-33.391c-27.618,0-50.087,22.469-50.087,50.087s22.469,50.087,50.087,50.087h33.391
                                			c27.618,0,50.087-22.469,50.087-50.087S300.314,345.043,272.696,345.043z M272.696,411.826h-33.391
                                			c-9.206,0-16.696-7.49-16.696-16.696s7.49-16.696,16.696-16.696h33.391c9.206,0,16.696,7.49,16.696,16.696
                                			S281.902,411.826,272.696,411.826z"/>
                                	</g>
                                </g>
                                <text textAnchor="middle" x="155" y="170" fill="#C9CBCF"  fontSize="15" fontWeight="normal">QNH</text>

                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{Pressure}</tspan> hPa</text>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">{Pressure} hPa</text> */}
                                
                                {/* {(() => {
                                    if (isNaN(PTDY)==0) {
                                        return (
                                            <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">
                                                <tspan fontSize="30">{PTDY}</tspan> hPa {/* Changement de pression en 3 heures (en hPa) *}
                                            </text>
                                        )
                                    }
                                })()} */}

                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(PTDY)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <WiBarometer size={120} fill="#BACCCC"  x='65' y='50'/>
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">
                                    <tspan fontSize="30">{PTDY}</tspan> hPa {/* Changement de pression en 3 heures (en hPa) */}
                                </text>
                            </svg>
                            </Col>
                        )
                    }
                })()}


                {(() => {
                    if (isNaN(Hum_SHT)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                <g  transform="rotate(0 0 0) translate(80 50) scale(0.3 0.3)" fill="#00abfb">
                                	<path d="M209.306,50.798c-2.452-3.337-7.147-4.055-10.485-1.602c-3.338,2.453-4.055,7.147-1.603,10.485
            		                c54.576,74.266,66.032,123.541,66.032,151.8c0,27.691-8.272,52.794-23.293,70.685c-17.519,20.866-42.972,31.446-75.651,31.446
            		                c-73.031,0-98.944-55.018-98.944-102.131c0-52.227,28.103-103.234,51.679-136.829c25.858-36.847,52.11-61.415,52.37-61.657
            		                c3.035-2.819,3.209-7.565,0.39-10.6c-2.819-3.034-7.565-3.209-10.599-0.39c-1.11,1.031-27.497,25.698-54.254,63.765
            		                c-24.901,35.428-54.586,89.465-54.586,145.71c0,31.062,9.673,59.599,27.236,80.353c20.361,24.061,50.345,36.779,86.708,36.779
            		                c36.794,0,66.926-12.726,87.139-36.801c17.286-20.588,26.806-49.117,26.806-80.33C278.25,156.216,240.758,93.597,209.306,50.798z"
            		                />
                                	<path d="M198.43,148.146l-95.162,95.162c-2.929,2.929-2.929,7.678,0,10.606c1.465,1.464,3.385,2.197,5.304,2.197           
		s3.839-0.732,5.304-2.197l95.162-95.162c2.929-2.929,2.929-7.678,0-10.606C206.107,145.217,201.359,145.217,198.43,148.146z"/>
                                	<path d="M191.965,207.899c-13.292,0-24.106,10.814-24.106,24.106s10.814,24.106,24.106,24.106s24.106-10.814,24.106-24.106         
		S205.257,207.899,191.965,207.899z M191.965,241.111c-5.021,0-9.106-4.085-9.106-9.106s4.085-9.106,9.106-9.106
		s9.106,4.085,9.106,9.106S196.986,241.111,191.965,241.111z"/>
                                	<path d="M125.178,194.162c13.292,0,24.106-10.814,24.106-24.106s-10.814-24.106-24.106-24.106s-24.106,10.814-24.106,24.106            
		S111.886,194.162,125.178,194.162z M125.178,160.949c5.021,0,9.106,4.085,9.106,9.106s-4.085,9.106-9.106,9.106
		c-5.021,0-9.106-4.085-9.106-9.106S120.156,160.949,125.178,160.949z"/>
                                </g>
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">RH: <tspan fontSize="30">{Hum_SHT}</tspan> %</text>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">RH: {Hum_SHT} %</text> */}
                            </svg>
                            </Col> 
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(TempC_SHT)==0) {
                        let fillColor;
                        if (TempC_SHT > ColdLim) {
                            fillColor = '#EB3434';//'rgba(255, 0, 0, 0.8)'; // '#33CD32'
                        } else {
                            fillColor = 'rgba(125, 255, 255, 1)';//'rgba(0, 255, 0, 0.8)'; // #5CC0DE  '#AAFFFF'
                        }
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g stroke="#00abfb">
                                <path stroke="none" d={`M10 13.5a4 4 0 1 0 4 0v-${TempC_SHT/10} a2 2 0 0 0 -4 0v8.5`} fill={fillColor} transform="rotate(0 0 0) translate(50 30) scale(6 6)"/>
                                <path d="M10 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0 -4 0v8.5" transform="rotate(0 0 0) translate(50 30) scale(6 6)" />
                                </g>
                                
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">Air: <tspan fontSize="30">{TempC_SHT}</tspan> °C</text>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Air: {TempC_SHT} °C</text> */}
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(Irradiation)==0 && 1==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(80 50) scale(6 6)" fill="#FFCD56">
                                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                                </g>
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{Irradiation}</tspan> W/m²</text>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">{Irradiation} W/m²</text> */}
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Irradiation: {Irradiation} W/m²</text> */}
                            </svg>
                            {/* <tspan fontSize="15" baselineShift="super">W/m²</tspan> */}
                                {/* <BsSunFill size={150} fill="#FFCD56"/>
                                <BsSun size={150} fill="#35A1EB"/> */}
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(Irradiation)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    {/* <BsSunFill size={100} fill="#FFCD56" opacity={Irradiation/1000}/> */}
                                    <BsSunFill size={110} fill="#FFCD56" opacity={Irradiation/1000} x='70' y='50'/>
                                    <BsSun size={110} fill="#FFCD56" x='70' y='50'/>
                                </g>
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{Irradiation}</tspan> W/m²</text>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">{Irradiation} W/m²</text> */}
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Irradiation: {Irradiation} W/m²</text> */}
                            </svg>
                            {/* <tspan fontSize="15" baselineShift="super">W/m²</tspan> */}
                                
                            </Col>
                        )
                    }
                })()}                                
                {/* {(() => {
                    if (isNaN(Rain_rate)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(90 80) scale(5 5)" fill="#35A1EB">
                                {/* <path d="M4.176 11.032a.5.5 0 0 1 .292.643l-1.5 4a.5.5 0 1 1-.936-.35l1.5-4a.5.5 0 0 1 .644-.293zm3 0a.5.5 0 0 1 .292.643l-1.5 4a.5.5 0 1 1-.936-.35l1.5-4a.5.5 0 0 1 .644-.293zm3 0a.5.5 0 0 1 .292.643l-1.5 4a.5.5 0 1 1-.936-.35l1.5-4a.5.5 0 0 1 .644-.293zm3 0a.5.5 0 0 1 .292.643l-1.5 4a.5.5 0 0 1-.936-.35l1.5-4a.5.5 0 0 1 .644-.293zm.229-7.005a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 10H13a3 3 0 0 0 .405-5.973zM8.5 1a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4.002 4.002 0 0 1 8.5 1z"/> *}
                                <path d="M4.158 12.025a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm3 0a.5.5 0 0 1 .316.633l-1 3a.5.5 0 0 1-.948-.316l1-3a.5.5 0 0 1 .632-.317zm3 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm3 0a.5.5 0 0 1 .316.633l-1 3a.5.5 0 1 1-.948-.316l1-3a.5.5 0 0 1 .632-.317zm.247-6.998a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 11H13a3 3 0 0 0 .405-5.973zM8.5 2a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4.002 4.002 0 0 1 8.5 2z"/>
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain_rate} mm/h</text> *}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">{Rain_rate} mm/h</text>
                            </svg>
                            </Col>
                        )
                    }
                })()} */}
                {(() => {
                    if (isNaN(Rain_rate)==0 && Rain_rate!=0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">

                                    <BsCloudRainFill size={110} fill="#C9CBCF" opacity={Rain_rate/10} x='70' y='50'/>
                                    <BsCloudRain size={110} fill="#35A1EB" opacity={Rain_rate/10} x='70' y='50'/>
                                    <BsCloudDrizzle size={110} fill="#35A1EB" x='70' y='50'/>

                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">{Rain_rate} mm/h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{Rain_rate}</tspan> mm/h</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(Rain_rate)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <WiRaindrops size={250} fill="#9CDCFE"/>
                                <WiRaindrops size={250} fill="#35A1EB" opacity={Rain/100}/>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{Rain}</tspan> mm/{hours}h</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}








                {(() => {
                    if (isNaN(DewP)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                {/* <g transform="rotate(0 0 0) translate(60 20) scale(1 1)" fill="none">
                                    <MdDewPoint size={50} fill="#C0C0C0"/>
                                </g> */}
                                {/* <g transform="rotate(0 0 0) translate(60 20) scale(1 1)" fill="none">
                                    <GiDew size={100} fill="#036ABC"/>
                                </g> */}
                                    <WiRaindrop size={25} fill="#036ABC" x='150' y='80'/>
                                    <WiRaindrop size={20} fill="#036ABC" x='140' y='70'/>
                                    <WiRaindrop size={40} fill="#036ABC" x='120' y='40'/>
                                    <WiRaindrop size={30} fill="#036ABC" x='150' y='40'/>
                                    <WiRaindrop size={35} fill="#036ABC" x='130' y='100'/>
                                    <WiRaindrop size={33} fill="#036ABC" x='150' y='120'/>
                                    <WiRaindrop size={18} fill="#036ABC" x='155' y='110'/>


                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <WiThermometerExterior size={150} fill="#777777" x='30' y='30'/>
                                </g>
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <WiThermometerInternal size={150} fill="#9D0000" x='30' y='30'/>
                                </g>
                                
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{DewP}</tspan> °C</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(Visib)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                            {/* <BsEyeglasses size={250} fill="#9CDCFE"/> */}
                            {/* <BsArrowBarUp size={250} fill="#9CDCFE"/> */}

                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsEye size={90} fill="#FF5F52" x='80' y='20'/>
                                </g>
                                {/* <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsEyeglasses size={250} fill="#9CDCFE"/>
                                </g> */}
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsArrowRight size={80} fill="#FF5F52" x='85' y='110'/>
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{Visib}</tspan> km</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(vertVis)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                {/* <BsArrowBarUp size={250} fill="#9CDCFE"/> */}
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsEye size={90} fill="#4CC0C0"  x='80' y='20'/>
                                </g>
                                {/* <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsEyeglasses size={250} fill="#9CDCFE"/>
                                </g> */}
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsArrowBarUp size={80} fill="#4CC0C0"  x='85' y='110'/>
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{1.852*vertVis}</tspan> km</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(Snow)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsSnow size={120} fill="#BACCCC"  x='65' y='50'/>
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold"><tspan fontSize="30">{Snow}</tspan> mm</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(MWD)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">

                                <g  opacity="0.8" transform={`rotate(${MWD} 125 125)`} fill="#46BDFB">
                                    <BsArrowDown size={120} fill="#BACCCC"  x='65' y='50'/>
                                    {/* <path  id="fleche" d="M125,50 L75,180 L125,155 L175,180 L125,50 z" transform={`rotate(180 125 125)`}></path> */}
                                </g>


                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(0 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(45 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(90 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(135 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(180 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(225 125 125)"/>
                                <ellipse cx="125" cy="210" rx="3" ry="6" stroke="black" strokeWidth="1" fill="none" transform="rotate(270 125 125)"/>
                                <ellipse cx="125" cy="210" rx="2" ry="4" stroke="black" strokeWidth="1" fill="none" transform="rotate(315 125 125)"/>
                                <WiWindy size={50}  x="50" y="210" fill="#2a4999"/>
                                <text textAnchor="left" x="105" y="249" fill="#2a4999"  fontSize="25" fontWeight="bold">{MWD}°</text>
                            </svg>
                            </Col>
                        )
                    }
                })()}





                {(() => {
                    if (isNaN(WVHT)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsTsunami size={120} fill="#BACCCC"  x='65' y='50'/>
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">
                                    <tspan fontSize="30">{WVHT}</tspan> m {/* Hauteur significative des vagues (en mètres) */}
                                </text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(DPD)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <PiWaveSineBold size={120} fill="#BACCCC"  x='65' y='50'/>
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">
                                    <tspan fontSize="30">{DPD}</tspan> s {/* Période de vague dominante (en secondes) */}
                                </text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(APD)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <PiWaveSineBold
                                     size={120} fill="#BACCCC"  x='65' y='50'/>
                                </g>
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">
                                    <tspan fontSize="30">{APD}</tspan> s {/* Période moyenne de vague (en secondes) */}
                                </text>
                            </svg>
                            </Col>
                        )
                    }
                })()}

                {(() => {
                    if (isNaN(TIDE)==0) {
                        return (
                            <Col>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" strokeWidth="1.5"  fill="none" strokeLinecap="round" strokeLinejoin="round">
                                {/* <g transform="rotate(0 0 0) translate(0 0) scale(1 1)" fill="none">
                                    <BsSnow size={120} fill="#BACCCC"  x='65' y='50'/>
                                </g> */}
                                {/* <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="25" fontWeight="bold">Rain: {Rain} mm/{hours}h</text> */}
                                <BsArrowBarUp size={30} fill="#BACCCC"  x='65' y='25'/>
                                <text textAnchor="middle" x="125" y="25" fill="#2a4999"  fontSize="20" fontWeight="bold">
                                    <tspan fontSize="10">{hw }</tspan> {/* marée haute */}
                                    <tspan fontSize="10">{hw_time}</tspan> {/* heure marée haute */}
                                </text>
                                <text textAnchor="middle" x="125" y="125" fill="#2a4999"  fontSize="20" fontWeight="bold">
                                    <tspan fontSize="30">{TIDE}</tspan> {/* Niveau de la marée */}
                                    <tspan fontSize="30">{hw }</tspan> {/* marée haute */}
                                    <tspan fontSize="30">{hw_time}</tspan> {/* heure marée haute */}
                                    <tspan fontSize="30">{lw}</tspan> {/* marée basse */}
                                    <tspan fontSize="30">{lw_time}</tspan> {/* heure marée basse */}
                                </text>
                                <BsArrowBarDown size={30} fill="#BACCCC"  x='65' y='225'/>
                                <text textAnchor="middle" x="125" y="225" fill="#2a4999"  fontSize="20" fontWeight="bold">
                                    <tspan fontSize="10">{lw}</tspan> {/* marée basse */}
                                    <tspan fontSize="10">{lw_time}</tspan> {/* heure marée basse */}
                                </text>
                            </svg>
                            </Col>
                        )
                    }
                })()}
                
        </Row>


        {/* <p>{moment(received_at, 'YYYY-MM-DD HH:mm:ss').fromNow()}</p>
            <p>{received_at}</p> */}
        
    </Container>
  );
}




