import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

/*
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
  
import { Line } from 'react-chartjs-2';
*/  


import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
  
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);





/*
const options = {
  //maintainAspectRatio: false,	// Don't maintain w/h ratio
  //responsive: true,

  scales:{
    y:{

      grid:{
        //color: ['grey'],
        display: 'true',
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Vent kts',
    },
  },
};*/

const option_rad = {
    plugins:{
        tooltip:{
            enabled: false,
        },
        title: {
          display: true,
          text: 'Direction [deg°]',
        },
    },
    //maintainAspectRatio: false,	// Don't maintain w/h ratio
    scales: {
        r: {
            min: -5,
            ticks: {
              //color: 'red',
              display: false,
            },
          /*pointLabels: {
            color: 'red'
          }*/
        }
    }
}






function Polaire (props) {


    
    let received_at = props.received_at;
    let Wind_ave = props.Wind_ave;
    let Wind_3sgust = props.Wind_3sgust;
    let Wind_3smin = props.Wind_3smin;
    let Wind_stdev = props.Wind_stdev;
    let Dir_ave = props.Dir_ave;
    let Dir_3sgust = props.Dir_3sgust;
    let Dir_stdev = props.Dir_stdev;
    let gust_3stime = props.gust_3stime;
    let Hum_SHT = props.Hum_SHT;
    let TempC_DS = props.TempC_DS;
    let TempC_SHT = props.TempC_SHT;
    let WTMP = props.WTMP;
    let Temp_Red = props.Temp_Red;
    let Temp_White = props.Temp_White;
    let end_device_ids = props.end_device_ids;


    
    //const dirData = Dir_3sgust;
    //const windData = Wind_3sgust;
    //const dir_ave = Dir_ave;//[193, 204, 166, 212, 187, 204, 204, 207, 209, 21, 215, 203];//Dir_3sgust;//[10, 20, 60, 110, 130, 190, 250, 330];
    //const wind_ave = Wind_ave;//[15.30, 17.70, 15.00, 16.60, 17.10, 16.00, 16.20, 17.20, 13.00, 14.70, 16.60, 18.70];//Wind_3sgust;//[5, 8, 12, 15, 18, 10, 6, 4];
    const dir_names = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    
    const wind_ave = [0, 0, 0, 0, 0, 0, 0, 0];// [0, 4, 17, 12, 3, 0, 0, 0];//

    for(let i = 0; i < Dir_ave.length; i++){
        const direction = Dir_ave[i];
        const wind = Wind_ave[i];
        let idx; // variable pour stocker l'indice
      if (direction !== null && direction !==undefined) {
        if (direction <= 22.5 || direction > 337.5) {
          idx = 0;
        } else if (direction <= 67.5) {
          idx = 1;
        } else if (direction <= 112.5) {
          idx = 2;
        } else if (direction <= 157.5) {
          idx = 3;
        } else if (direction <= 202.5) {
          idx = 4;
        } else if (direction <= 247.5) {
          idx = 5;
        } else if (direction <= 292.5) {
          idx = 6;
        } else {
          idx = 7;
        }
        wind_ave[idx]+=Number(wind);
        //wind_data[idx]=wind_data[idx]+wind;
      }
    }

    const wind_gust = [0, 0, 0, 0, 0, 0, 0, 0];// [0, 4, 17, 12, 3, 0, 0, 0];//
    //const wind_gust = [5, 5, 5, 5, 5, 5, 5, 5];// [0, 4, 17, 12, 3, 0, 0, 0];//

    for(let i = 0; i < Dir_3sgust.length; i++){
        const direction = Dir_3sgust[i];
        // console.log('direction')
        // console.log(direction)
        // console.log(Dir_3sgust[i])
        const wind = Wind_3sgust[i];
        let idx; // variable pour stocker l'indice

      if (direction !== null && direction !==undefined) {
        if (direction <= 22.5 || direction > 337.5) {
          idx = 0;
        } else if (direction <= 67.5) {
          idx = 1;
        } else if (direction <= 112.5) {
          idx = 2;
        } else if (direction <= 157.5) {
          idx = 3;
        } else if (direction <= 202.5) {
          idx = 4;
        } else if (direction <= 247.5) {
          idx = 5;
        } else if (direction <= 292.5) {
          idx = 6;
        } else {
          idx = 7;
        }
        wind_gust[idx]+=Number(wind);
        //wind_data[idx]=wind_data[idx]+wind;
      }
    }

    const donne ={
        labels: dir_names,//dir_names,
        datasets: [
          {
            label: 'Vent',
            data: wind_ave,
            //fill: true,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.2
          },
          {
            label: 'Rafale',
            data: wind_gust,
            //fill: true,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            tension: 0.2
          },
        ],
      };
    /*
    const [wspd, setWspd] = useState({
        labels: [],
        datasets: [
          {
            label: 'Vent',
            data: [],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.3
          },
          {
            label: 'Rafale',
            data: [],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            tension: 0.3
          },
          {
            label: 'Vent minimum',
            data: [],
            borderColor: 'rgb(255, 216, 123)',
            backgroundColor: 'rgba(255, 230, 173)',
            tension: 0.3
          },
        ],
      });*/




  return (
    <Container fluid>
        <Radar data={donne} options={option_rad}/>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
    </Container>
  );
}


export default Polaire;


//isNaN(Wind_ave)

//<Line data={donne} options={options}/>