import React, { useState, useEffect } from 'react';
import "./i18n"; //import this
import { useTranslation } from "react-i18next";

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Table from 'react-bootstrap/Table';


function AlerteMessage() {
  const { t } = useTranslation();

  const [showPrompt, setShowPrompt] = useState(true);

  const deadline = new Date('2024-09-09T12:00:00');

  useEffect(() => {
    const now = new Date();

    // Si la date actuelle est avant la date limite, afficher l'alerte
    if (now > deadline) {
      setShowPrompt(false);
    }
  }, [deadline]);

  const handleClose = () => {
    setShowPrompt(false);
  };

  if (showPrompt) {
    return (
        
        <Alert variant="warning" onClose={handleClose} dismissible>
            <Alert.Heading> Nouvelles couleurs! </Alert.Heading>

            <div className="install-prompt">
                <p>
                    Afin d'améliorer l'évaluation visuelle du stress thermique, nous avons ajouté 2 nouvelles limites empiriques¹, succeptibles d'évoluer selon les besoins.<br></br>
                    Les limites pour les personnes acclimatées² et non-acclimatées², issues de la littérature, restent inchangées mais se voient allouées de nouvelles couleurs.
                </p>

                <Table striped bordered hover size="sm" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                    <thead>
                        <tr>
                        <th>Anciennes couleurs</th>
                        <th>Nouvelles couleurs</th>
                        <th>Limites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td style={{ textAlign: 'center' }}> - </td>
                        <td style={{ backgroundColor: 'rgba(150, 0, 0, 0.75)' }}> </td>
                        <td>{t("info.legend.darklimit")+" ¹"}</td>
                        {/* <td><i>WBGT<sub>ref</sub> &ge; 53.5 - 8.9 log<sub>10</sub>(M)</i></td>&plusmn; */}
                        </tr>

                        <tr>
                        <td style={{ textAlign: 'center' }}> - </td>
                        <td style={{ backgroundColor: 'rgba(255, 0, 0, 0.75)' }}> </td>
                        <td>{t("info.legend.redlimit")+" ¹"}</td>
                        {/* <td><i>WBGT<sub>ref</sub> &ge; 55.1 - 10.2 log<sub>10</sub>(M)</i></td>&plusmn; */}
                        </tr>

                        <tr>
                        <td style={{ backgroundColor: 'rgba(255, 0, 0, 0.75)' }}> </td>
                        <td style={{ backgroundColor: 'rgba(255, 125, 0, 0.75)' }}> </td>
                        <td>{t("info.legend.orangelimit")+" ²"}</td>
                        {/* <td><i>WBGT<sub>ref</sub> &ge; 56.7 - 11.5 log<sub>10</sub>(M)</i></td>&plusmn; */}
                        </tr>

                        <tr>
                        <td style={{ backgroundColor: 'rgba(255, 125, 0, 0.75)' }}> </td>
                        <td style={{ backgroundColor: 'rgba(255, 225, 0, 0.755)' }}> </td>
                        <td>{t("info.legend.yellowlimit")+" ²"}</td>
                        {/* <td><i>WBGT<sub>ref</sub> &ge; 59.9 - 14.1 log<sub>10</sub>(M)</i></td> */}
                        </tr>

                        <tr>
                        <td style={{ backgroundColor: 'rgba(0, 255, 0, 0.5)' }}> </td>
                        <td style={{ backgroundColor: 'rgba(0, 255, 0, 0.5)' }}> </td>
                        <td style={{verticalAlign: 'middle' }}>{t("info.legend.safezone")}</td>
                        {/* <td><i>WBGT<sub>ref</sub> &lt; 59.9 - 14.1 log<sub>10</sub>(M)</i></td> */}
                        </tr>
                    </tbody>
                    </Table>
                    <br></br>
                    <i>
                        ¹ Les limites empiriques sont basées sur le retour d'expérience sur site et sont succeptibles d'évoluer en fonction des retours terrains et des standards souhaités.<br></br>
                        ²  Les limites issues de la littérature définissent des seuils au-delà desquels le stress thermique peut avoir des conséquences sur l'organisme.          
                    </i> 
                    <br></br>
                    <br></br>

                        <div>
                            <Button variant="primary" onClick={handleClose} size="lg">
                            Fermer
                            </Button>
                        </div>
         
            </div>
        </Alert>

        
    );
  } else {
    return null;
  }
}

export default AlerteMessage;

