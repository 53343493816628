{
    "language": "ภาษาอังกฤษ",
    "locale": "th-TH",

    "install": {
        "title": "ติดตั้งแอป Heatector!",
        "text": "Heatector ติดตั้งง่ายบนคอมพิวเตอร์หรือโทรศัพท์ แอปนี้ไม่ใช้พื้นที่ เนื่องจากเป็นเว็บแอป!",
        "button": "คลิกเพื่อติดตั้งแอป!"
    },

    "contact": {
        "phone": "โทรศัพท์",
        "mail": "อีเมล",
        "button": "คลิกเพื่อติดตั้งแอป!"
    },

    "nav": {
        "Direct": "สด",
        "Map": "แผนที่",
        "Montre": "นาฬิกา"
    },

    "info": {
        "legend": {
            "header": "สัญลักษณ์",
            "varbl": "ตัวแปร",
            "descr": "คำอธิบาย",

            "hist_forecast": "ประวัติการพยากรณ์",

            "wbt": "อุณหภูมิของลูกบอลเปียก",
            "tg": "อุณหภูมิของลูกบอลดำ",
            "ta": "อุณหภูมิอากาศ",
            "rh": "ความชื้นสัมพัทธ์",

            "wbgt-M": "WBGT - เมตาบอลิซึม",

            "redlimit": "ขีดจำกัดเชิงประจักษ์",
            "orangelimit": "ขีดจำกัดสำหรับผู้ที่เคยชินกับสภาพ",
            "yellowlimit": "ขีดจำกัดสำหรับผู้ที่ไม่เคยชินกับสภาพ",
            "safezone": "เขตปลอดภัย",
            "coldzone": "เขตเย็น",
            "coldnote": "ระวังการใช้งานของวัสดุบางอย่าง",
            "M": "การประมาณการเมตาบอลิซึมพลังงาน",
            "CAV": "ค่าปรับสำหรับเสื้อผ้า"
        },

        "M": {
            "#0": "พักผ่อน",
            "#1": "ต่ำ",
            "#2": "ปานกลาง",
            "#3": "สูง",
            "#4": "สูงมาก"
        },

        "ME": {
            "header": "การจำแนกระดับเมตาบอลิซึมพลังงานตามมาตรฐาน ISO 8996",
            "title": {
                "#0": "พักผ่อน",
                "#1": "เมตาบอลิซึมพลังงานต่ำ",
                "#2": "เมตาบอลิซึมพลังงานปานกลาง",
                "#3": "เมตาบอลิซึมพลังงานสูง",
                "#4": "เมตาบอลิซึมพลังงานสูงมาก"
            },
            "text": {
                "#0": "พักผ่อน, นั่งในตำแหน่งที่สบาย",
                "#1": "งานเบาด้วยมือ (เขียน, พิมพ์, วาด, เย็บผ้า, การบัญชี); งานที่ใช้มือและแขน (เครื่องมือขนาดเล็ก, การตรวจสอบ, การประกอบหรือการคัดแยกวัสดุเบา); งานที่ใช้แขนและขา (การขับยานพาหนะภายใต้สภาวะปกติ, การใช้งานสวิตช์หรือคันเหยียบด้วยเท้า).\n\nยืน: เจาะ (ชิ้นส่วนขนาดเล็ก); กัด (ชิ้นส่วนขนาดเล็ก); ม้วน; การพันแกนเล็ก ๆ; การตัดเฉือนด้วยเครื่องมือกำลังต่ำ; การเดินบนพื้นผิวเรียบเป็นครั้งคราว (ความเร็วสูงสุด 2.5 กม./ชม.).",
                "#2": "งานที่ต้องใช้มือและแขนอย่างต่อเนื่อง (การตอกตะปู, การขัด); งานที่ใช้แขนและขา (การควบคุมในไซต์ของรถบรรทุก, รถแทรกเตอร์หรืออุปกรณ์); งานที่ใช้แขนและลำตัว (งานค้อนลม, การเชื่อมต่อยานพาหนะ, การฉาบปูน, การจัดการวัสดุที่มีน้ำหนักปานกลางเป็นครั้งคราว, การถอนวัชพืช, การเก็บผลไม้หรือผัก; การผลักหรือดึงรถเข็นเบาหรือรถสาลี่; การเดินที่ความเร็ว 2.5 ถึง 5.5 กม./ชม. บนพื้นผิวเรียบ; การตีเหล็ก).",
                "#3": "งานที่ต้องใช้แขนและลำตัวอย่างหนัก; การขนส่งวัสดุที่มีน้ำหนักมาก; การตักดิน; งานตอก; การเลื่อย, การไสหรือการสกัดไม้เนื้อแข็ง; การตัดหญ้าด้วยมือ; การขุด; การเดินที่ความเร็ว 5.5 ถึง 7 กม./ชม. บนพื้นผิวเรียบ.\n\nการผลักหรือดึงรถเข็นมือที่มีน้ำหนักมากหรือรถสาลี่; การกำจัดเศษชิ้นส่วนจากการหล่อ; การวางบล็อกคอนกรีต.",
                "#4": "กิจกรรมที่เข้มข้นมากด้วยความเร็วใกล้เคียงกับความสามารถสูงสุด; งานขวาน; การตักหรือขุดอย่างเข้มข้น; การปีนบันได, ทางลาดหรือบันได; การเดินเร็วด้วยก้าวสั้น; การวิ่งบนพื้นผิวเรียบ; การเดินที่ความเร็วมากกว่า 7 กม./ชม. บนพื้นผิวเรียบ."
            },
            "richtext": {
                "#0": "พักผ่อน, นั่งในตำแหน่งที่สบาย",
                "#1": "งานเบาด้วยมือ (เขียน, พิมพ์, วาด, เย็บผ้า, การบัญชี); งานที่ใช้มือและแขน (เครื่องมือขนาดเล็ก, การตรวจสอบ, การประกอบหรือการคัดแยกวัสดุเบา); งานที่ใช้แขนและขา (การขับยานพาหนะภายใต้สภาวะปกติ, การใช้งานสวิตช์หรือคันเหยียบด้วยเท้า).<br/><br/> ยืน: เจาะ (ชิ้นส่วนขนาดเล็ก); กัด (ชิ้นส่วนขนาดเล็ก); ม้วน;<br/> การพันแกนเล็ก ๆ; การตัดเฉือนด้วยเครื่องมือกำลังต่ำ; การเดินบนพื้นผิวเรียบเป็นครั้งคราว (ความเร็วสูงสุด 2.5 กม./ชม.).",
                "#2": "งานที่ต้องใช้มือและแขนอย่างต่อเนื่อง (การตอกตะปู, การขัด); งานที่ใช้แขนและขา (การควบคุมในไซต์ของรถบรรทุก, รถแทรกเตอร์หรืออุปกรณ์); งานที่ใช้แขนและลำตัว (งานค้อนลม, การเชื่อมต่อยานพาหนะ, การฉาบปูน, การจัดการวัสดุที่มีน้ำหนักปานกลางเป็นครั้งคราว, การถอนวัชพืช, การเก็บผลไม้หรือผัก; การผลักหรือดึงรถเข็นเบาหรือรถสาลี่; การเดินที่ความเร็ว 2.5 ถึง 5.5 กม./ชม. บนพื้นผิวเรียบ; การตีเหล็ก).",
                "#3": "งานที่ต้องใช้แขนและลำตัวอย่างหนัก; การขนส่งวัสดุที่มีน้ำหนักมาก; การตักดิน; งานตอก; การเลื่อย, การไสหรือการสกัดไม้เนื้อแข็ง; การตัดหญ้าด้วยมือ; การขุด; การเดินที่ความเร็ว 5.5 ถึง 7 กม./ชม. บนพื้นผิวเรียบ.<br/><br/>การผลักหรือดึงรถเข็นมือที่มีน้ำหนักมากหรือรถสาลี่; การกำจัดเศษชิ้นส่วนจากการหล่อ; การวางบล็อกคอนกรีต.",
                "#4": "กิจกรรมที่เข้มข้นมากด้วยความเร็วใกล้เคียงกับความสามารถสูงสุด; งานขวาน; การตักหรือขุดอย่างเข้มข้น; การปีนบันได, ทางลาดหรือบันได; การเดินเร็วด้วยก้าวสั้น; การวิ่งบนพื้นผิวเรียบ; การเดินที่ความเร็วมากกว่า 7 กม./ชม. บนพื้นผิวเรียบ."
            },

            "#0": "พักผ่อน",
            "#1": "เมตาบอลิซึมพลังงานต่ำ",
            "#2": "เมตาบอลิซึมพลังงานปานกลาง",
            "#3": "เมตาบอลิซึมพลังงานสูง",
            "#4": "เมตาบอลิซึมพลังงานสูงมาก"
        },


        "CAV": {
            "header": "ค่าปรับของดัชนี WBGT สำหรับเสื้อผ้า (CAV) สำหรับชุดแต่งกายต่าง ๆ ใน °C-WBGT",
            "title": {


                "sc": "เสื้อผ้าที่ไม่มีฮู้ด",
                "sc_a": "เสื้อผ้าทำงาน",
                "sc_b": "ชุดป้องกัน",
                "sc_c": "ชุด SMS แบบไม่ทอชั้นเดียว",
                "sc_d": "ชุดผ้าโพลีโอเลฟินแบบไม่ทอชั้นเดียว",
                "sc_e": "ผ้ากันเปื้อนป้องกันสารเคมีแขนยาวและชุดป้องกันขนาดยาว",
                "sc_f": "ชุดผ้าทอแบบสองชั้น",
                "sc_g": "ชุดป้องกันแบบไม่ทอชั้นเดียวที่ไม่กันน้ำ",
                "sc_h": "ชุดป้องกันแบบไม่ทอชั้นเดียวที่ไม่กันน้ำ, ไม่มีฮู้ด",
    
                "ac": "เสื้อผ้าพร้อมฮู้ด",
                "ac_a": "เสื้อผ้าทำงาน + ฮู้ด",
                "ac_b": "ชุดป้องกัน + ฮู้ด",
                "ac_c": "ชุด SMS แบบไม่ทอชั้นเดียว + ฮู้ด",
                "ac_d": "ชุดผ้าโพลีโอเลฟินแบบไม่ทอชั้นเดียว + ฮู้ด",
                "ac_e": "ผ้ากันเปื้อนป้องกันสารเคมีแขนยาวและชุดป้องกันขนาดยาว + ฮู้ด",
                "ac_f": "ชุดผ้าทอแบบสองชั้น + ฮู้ด",
                "ac_g": "ชุดป้องกันแบบไม่ทอชั้นเดียวพร้อมฮู้ด",

                "c": "ฮู้ด"
            },
            "text": {
                "sc_a": "เสื้อผ้าทำงานที่ทำจากผ้าทอเป็นชุดอ้างอิง.",
                "sc_b": "ผ้าทอที่ประกอบด้วยฝ้ายที่ผ่านการบำบัด.",
                "sc_c": "กระบวนการไม่ผูกขาดในการผลิตผ้าไม่ทอจากโพรพิลีน.",
                "sc_d": "ผ้าพิเศษที่ทำจากโพลีเอทิลีน.",
                "sc_e": "ผ้ากันเปื้อนแบบครอบคลุมได้รับการออกแบบเพื่อปกป้องส่วนหน้าและด้านข้างของร่างกายจากการหกรดสารเคมีโดยบังเอิญ.",
                "sc_f": "มักถือว่าเป็นชุดป้องกันที่ใส่ทับเสื้อผ้าทำงาน.",
                "sc_g": "ผลกระทบที่แท้จริงขึ้นอยู่กับระดับความชื้น และในหลายกรณีผลกระทบเล็กน้อย.",
                "sc_h": "-",

                "ac_g": "ผลกระทบที่แท้จริงขึ้นอยู่กับระดับความชื้น และในหลายกรณีผลกระทบเล็กน้อย.",

                "c": "การสวมใส่ฮู้ดพร้อมกับชุดเสื้อผ้าใด ๆ ค่านี้จะถูกเพิ่มไปยังค่า CAV ของชุดที่ไม่มีฮู้ดหรืออุปกรณ์หายใจ.",
                
                "note": "หมายเหตุ: สำหรับเสื้อผ้าที่มีความต้านทานต่อการระเหยสูง มีการพึ่งพาความชื้นสัมพัทธ์ ค่าของ CAV เป็นค่าที่มีแนวโน้มสูง."
            },

            "richtext": {
                "c": "การสวมใส่ฮู้ดพร้อมกับชุดเสื้อผ้าใด ๆ<br/><br/><i>ค่านี้จะถูกเพิ่มไปยังค่า CAV ของชุดที่ไม่มีฮู้ดหรืออุปกรณ์หายใจ.</i>",
                
                "note": "<i>หมายเหตุ: สำหรับเสื้อผ้าที่มีความต้านทานต่อการระเหยสูง มีการพึ่งพาความชื้นสัมพัทธ์ ค่าของ CAV เป็นค่าที่มีแนวโน้มสูง.</i>"
            }
        }
  },

  

  "Chart": {
    "spotselect": "เลือกจุด",
    "spotnotfound": "ไม่พบจุด",

    "Metabolisme": "เมตาบอลิซึม",

    "start": "เริ่ม",
    "end": "สิ้นสุด",


    "Perso": "ปรับแต่งเอง",
    "2h3h6h12h24h": "2ชม - 6ชม - 12ชม - 24ชม",
    
    "2h": "2 ชม.",
    "6h": "6 ชม.",
    "12h": "12 ชม.",
    "24h": "24 ชม.",
    "2d_prev": "2 วันก่อนหน้า",


    "forecast": "การพยากรณ์"
      
  },


    "Map": {
        "h":{
            "Options": "ตัวเลือก",
            "Map": "แผนที่",
            "swisslayer": "ชั้นเพิ่มเติม"
        },
        "button":{
            "Wind": "ลม",
            "WBGT": "WBGT",
            "Temp": "อุณหภูมิ",
            "Temperature": "อุณหภูมิ",
            "Hum": "ความชื้น",
            "Press": "ความดัน",
            "Lux": "ลักซ์",
            "Water": "น้ำ",
            "Wave": "คลื่น",
            "Period": "ช่วงเวลา",
            "Tide": "น้ำขึ้นน้ำลง",

            "Gust": "ลมกระโชก",
            "min_Wind": "ความเร็วลมขั้นต่ำ",
            "Sunshine": "แสงแดด",
            "precip_intensity": "ความเข้มของการตกของฝน",
            "Precip": "ปริมาณน้ำฝน",
            "avg_wave_period": "ช่วงคลื่นเฉลี่ย",
            "dom_wave_period": "ช่วงคลื่นที่โดดเด่น",
            "wave_dir": "ทิศทางเฉลี่ยของคลื่น",
            "wave_height": "ความสูงคลื่นที่สำคัญ"
        }
    },

    "ExportButton": {
        "export": "ส่งออก",
        "csv": "ส่งออก CSV",
        "excel": "ส่งออก Excel",
        "pdf": "ส่งออก PDF",
        "pdf_hot": "ส่งออก PDF - ความร้อน",
        "pdf_cold": "ส่งออก PDF - ความเย็น"
    },


    "Certif": {

        "cert_clim": "ใบรับรองสภาพภูมิอากาศ",
        "cert": "ใบรับรอง",
        "hot": "อุณหภูมิสูง",
        "cold": "อุณหภูมิต่ำมาก",
        "lab": "การวัดที่ใช้เซนเซอร์อุณหภูมิ WBGT¹ สร้างขึ้นตามมาตรฐาน ISO 7243 ผ่านการทดสอบ² โดยห้องปฏิบัติการที่ได้รับการรับรองตามมาตรฐาน ISO 1725.",

        "comp": "บริษัท",
        "ide": "IDE",
        "lieu": "ชื่อสถานที่",
        "long": "ลองจิจูด",
        "lat": "ละติจูด",
        "date": "วันที่ออกใบรับรอง",
        "start": "เริ่ม",
        "end": "สิ้นสุด",
        "metabo": "เมตาบอลิซึมพลังงาน",
        "cav": "CAV⁴ (เสื้อผ้า)",

        "exmp": "ตัวอย่าง",
        "comm": "ความคิดเห็น",
        "shirt": "เสื้อผ้า",

        "bp1": "¹ ดัชนีอุณหภูมิ WBGT คำนวณการรับรู้ความเครียดความร้อนของร่างกาย ที่เกี่ยวข้องกับอุณหภูมิ ความชื้น และรังสีอาทิตย์โดยตรงและโดยอ้อม.",
        "bp2": "² เราสามารถให้คุณทราบเกี่ยวกับโปรโตคอลการวัดและกระบวนการควบคุมคุณภาพของเราได้ตามคำขอ.",

        "voir": "ดู",
        "Anx_a": "ภาคผนวก A",
        "Anx_b": "ภาคผนวก B",

        "redPeriode": "ช่วงเวลาที่เกินขีดจำกัดสำหรับผู้ที่เคยชินกับสภาพ",
        "orangePeriode": "ช่วงเวลาที่เกินขีดจำกัดสำหรับผู้ที่ไม่เคยชินกับสภาพ",
        "coldPeriode": "ช่วงเวลาที่อุณหภูมิต่ำกว่า 5°C",



        "csvxls":{
            "date": "วันที่",
            "limAcc": "ขีดจำกัดผู้ที่เคยชิน °C",
            "limNAcc": "ขีดจำกัดผู้ที่ไม่เคยชิน °C",
            "temp": "อุณหภูมิ"
        }

    }
}