import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import tudor from '../img/tudor.jpg';
import { maxHeight, textAlign } from '@mui/system';


import Timer from "./timer";
import Contact from './contact'; // Assurez-vous que le chemin est correct

// import Mongps from "./Mongps";


const arrayRange = (start, stop, step) =>
Array.from(
  { length: (stop - start) / step + 1 },
  (value, index) => start + index * step
  );
const arrSeconde = arrayRange(6, 354, 6);
const arrCinq = arrayRange(0, 330, 30);
const arrQuart = arrayRange(90, 330, 90);
const arrMond = arrayRange(0, 330, 30);

for (let i = 4; i < 60; i=i+5) {
  delete arrSeconde[i];
}
for (let i = 0; i < 60; i=i+3) {
  delete arrCinq[i];
}



const IntervalExample = (props) => {
  let montre = props.montre;

  const [temps, setTemps] = useState(0);
  const [date, setDate] = useState(0);

  const [time, setTime] = useState(0);

  const [heures, setHeures] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [millis, setMillis] = useState(0);
  const [stop2go, setStop2go] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTemps(
        new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        );
      setDate(
        new Date().toLocaleDateString([], {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        );
      setTime(
        new Date().toLocaleTimeString([], {
          weekday: 'long',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        );
      setHeures(
        new Date().getHours()
        //String(Date.now()).slice(-9)
        );

      
      setMinutes(
        /*new Date().toLocaleTimeString([], {
            minute: '2-digit',
          })*/
        new Date().getMinutes()
        );
      setSeconds(
        /*new Date().toLocaleTimeString([], {
            second: '2-digit',
          })*/
        new Date().getSeconds()
        );
      setMillis(
        //String(new Date().toLocaleTimeString([], {second: '2-digit',}))+String(Date.now()).slice(-3)
        new Date().getSeconds()+new Date().getMilliseconds()
        );
      setStop2go(
        6*(60/58)*((String(new Date().toLocaleTimeString([], {second: '2-digit',}))+String(Date.now()).slice(-3))/1000)-6
        );
      if(6*(60/58)*((String(new Date().toLocaleTimeString([], {second: '2-digit',}))+String(Date.now()).slice(-3))/1000)-6>360){setStop2go(0);}
      if(6*(60/58)*((String(new Date().toLocaleTimeString([], {second: '2-digit',}))+String(Date.now()).slice(-3))/1000)-6<0){setStop2go(0);}

    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container fluid>

{(() => {
      if (montre==0) {
        return (


          <Row >
          <Col xs={0} lg={3}></Col>
          
          <Col xs={12} lg={6}>


          <br></br>
          <br></br>
          <br></br>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" >

          <g>
          <circle cx="125" cy="125" r="100" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />
          <circle cx="125" cy="125" r="5" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />


          {arrSeconde.map((value, index) => {
            return <line key={index} x1="125" y1="35" x2="125" y2="30" strokeWidth="2" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}

          {arrMond.map((value, index) => {
            return <line key={index} x1="125" y1="50" x2="125" y2="30" strokeWidth="4" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}
          

          </g>

          <g  opacity="1" transform={`rotate(${30*heures+minutes/2+seconds/120} 125 125)`} fill="#C0C0C0">
          <line x1="125" y1="75" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 65 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 10) scale(0.75 0.75)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 99, 132)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 30) scale(1 1)" />
          </g>
          </g>

          <g  opacity="1" transform={`rotate(${360*minutes/60} 125 125)`} fill="orange">
          <line x1="125" y1="45" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 40 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="#none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 -5) scale(0.75 0.75)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 216, 123)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 10) scale(1 1)" />
          </g>
          </g>

          <g  opacity="1" transform={`rotate(${stop2go} 125 125)`} fill="#61DAFB">
          <line x1="125" y1="35" x2="125" y2="118" strokeWidth="1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 12) scale(0.4 0.4)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(53, 162, 235)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 0) scale(1 1)" />
          </g>
          </g>

          </svg>

          <br></br>
          <br></br>
          <br></br>



          
          </Col>
          <Col xs={0} lg={3}></Col>



          </Row>
          )
      }
    })()}












    {(() => {
      if (montre==1) {
        return (
          <Container fluid>
            <Row>
              <Col>
                <Timer/>
                {/*<Mongps/>*/}
              </Col>
            </Row>


          {/*<Row>
          <Col>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
          <text textAnchor="middle" x="125" y="20" fill="#eb3434"  fontSize="8" fontWeight="bold">{date}</text>
          <text textAnchor="middle" x="125" y="80" fill="#eb3434" fontFamily='Helvetica' fontSize="50" fontWeight="bold">{temps}</text>
          </svg>
          </Col>
          </Row>*/}






          <Row >
          <Col xs={0} lg={3}></Col>
          
          <Col xs={12} lg={6}>

          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" >

          <g>
          <circle cx="125" cy="125" r="100" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />
          <circle cx="125" cy="125" r="5" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />


          {arrSeconde.map((value, index) => {
            return <line key={index} x1="125" y1="35" x2="125" y2="30" strokeWidth="2" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}

          {arrMond.map((value, index) => {
            return <line key={index} x1="125" y1="50" x2="125" y2="30" strokeWidth="4" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}
          

          </g>

          <g  opacity="1" transform={`rotate(${30*heures+minutes/2+seconds/120} 125 125)`} fill="#C0C0C0">
          <line x1="125" y1="75" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 65 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 10) scale(0.75 0.75)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 99, 132)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 30) scale(1 1)" />
          </g>
          </g>

          <g  opacity="1" transform={`rotate(${360*minutes/60} 125 125)`} fill="orange">
          <line x1="125" y1="45" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 40 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="#none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 -5) scale(0.75 0.75)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 216, 123)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 10) scale(1 1)" />
          </g>
          </g>

          <g  opacity="1" transform={`rotate(${stop2go} 125 125)`} fill="#61DAFB">
          <line x1="125" y1="35" x2="125" y2="118" strokeWidth="1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 12) scale(0.4 0.4)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(53, 162, 235)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 0) scale(1 1)" />
          </g>
          </g>

          </svg>

          <br></br>
          <br></br>
          <br></br>
          <br></br>


          
          </Col>
          <Col xs={0} lg={3}></Col>



          </Row>


          <Contact />
            <br></br>
            <br></br>
            <br></br>

          </Container>

          )
}
})()}










{/*}

{(() => {
      if (montre==1) {
        return (
          <Container fluid>


          <Row>
          <Col>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
          <text textAnchor="middle" x="125" y="20" fill="#eb3434"  fontSize="8" fontWeight="bold">{date}</text>
          <text textAnchor="middle" x="125" y="80" fill="#eb3434" fontFamily='Helvetica' fontSize="50" fontWeight="bold">{temps}</text>
          </svg>
          </Col>
          </Row>






          <Row >
          
          <Col>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" style={{maxHeight:'105vh'}}>

          <g>
          <circle cx="125" cy="125" r="100" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />
          <circle cx="125" cy="125" r="5" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />


          {arrSeconde.map((value, index) => {
            return <line key={index} x1="125" y1="35" x2="125" y2="30" strokeWidth="2" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}

          {arrMond.map((value, index) => {
            return <line key={index} x1="125" y1="50" x2="125" y2="30" strokeWidth="4" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}
          

          </g>

          <g  opacity="1" transform={`rotate(${30*heures+minutes/2+seconds/120} 125 125)`} fill="#C0C0C0">
          <line x1="125" y1="100" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 65 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 99, 132)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 40) scale(1 1)" />

          </g>
          <g  opacity="1" transform={`rotate(${360*minutes/60} 125 125)`} fill="orange">
          <line x1="125" y1="80" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 40 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="#none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 216, 123)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 20) scale(1 1)" />
          
          </g>

          <g  opacity="1" transform={`rotate(${stop2go} 125 125)`} fill="#61DAFB">
          <line x1="125" y1="55" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <circle cx="125" cy="60" r="1" fill="red" strokeWidth="0.5" stroke="none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(53, 162, 235)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          </g>

          </svg>
          </div>

          
          </Col>
          


          </Row>








          <Row >
          <Col xs={0} lg={3}></Col>
          
          <Col xs={12} lg={6}>


          <br></br>
          <br></br>
          <br></br>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" >

          <g>
          <circle cx="125" cy="125" r="100" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />
          <circle cx="125" cy="125" r="5" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />


          {arrSeconde.map((value, index) => {
            return <line key={index} x1="125" y1="35" x2="125" y2="30" strokeWidth="2" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}

          {arrMond.map((value, index) => {
            return <line key={index} x1="125" y1="50" x2="125" y2="30" strokeWidth="4" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}
          

          </g>

          <g  opacity="1" transform={`rotate(${30*heures+minutes/2+seconds/120} 125 125)`} fill="#C0C0C0">
          <line x1="125" y1="75" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 65 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 10) scale(0.75 0.75)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 99, 132)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 30) scale(1 1)" />
          </g>
          </g>

          <g  opacity="1" transform={`rotate(${360*minutes/60} 125 125)`} fill="orange">
          <line x1="125" y1="45" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 40 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="#none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 -5) scale(0.75 0.75)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 216, 123)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 10) scale(1 1)" />
          </g>
          </g>

          <g  opacity="1" transform={`rotate(${stop2go} 125 125)`} fill="#61DAFB">
          <line x1="125" y1="35" x2="125" y2="118" strokeWidth="1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <g transform="rotate(0 0 0) translate(125 12) scale(0.4 0.4)">
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(53, 162, 235)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(-125 0) scale(1 1)" />
          </g>
          </g>

          </svg>

          <br></br>
          <br></br>
          <br></br>



          
          </Col>
          <Col xs={0} lg={3}></Col>



          </Row>

          </Container>

          )
}
})()}
*/}



{/*}    
{(() => {
      if (montre==3) {
        return (
          <Row>
          <Col xs={3}></Col>
          
          <Col xs={6}>
          <br></br>
          
          
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" >
          
          <g>
          <circle cx="125" cy="125" r="100" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />
          <circle cx="125" cy="125" r="5" stroke="#C0C0C0" strokeWidth="5" fill="white" opacity={1} />
          
          
          {arrSeconde.map((value, index) => {
            return <line key={index} x1="125" y1="35" x2="125" y2="30" strokeWidth="2" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}
          
          {arrMond.map((value, index) => {
            return <line key={index} x1="125" y1="50" x2="125" y2="30" strokeWidth="4" stroke="black" transform={`rotate(${value} 125 125)`} />
          })}
          
          
          </g>
          
          <g  opacity="1" transform={`rotate(${30*heures+minutes/2+seconds/120} 125 125)`} fill="#C0C0C0">
          <line x1="125" y1="100" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 65 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 99, 132)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 40) scale(1 1)" />
          
          </g>
          <g  opacity="1" transform={`rotate(${360*minutes/60} 125 125)`} fill="orange">
          <line x1="125" y1="80" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 40 L130 140 L125 135 L120 140 Z" fill="none" strokeWidth="0.1" stroke="#none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(255, 216, 123)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 20) scale(1 1)" />
          
          </g>
          
          <g  opacity="1" transform={`rotate(${stop2go} 125 125)`} fill="#61DAFB">
          <line x1="125" y1="55" x2="125" y2="118" strokeWidth="2" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <circle cx="125" cy="60" r="1" fill="red" strokeWidth="0.5" stroke="none" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          <path d="M125 50 L135 75 L125 70 L115 75 Z" fill='rgb(53, 162, 235)' strokeWidth="0.1" stroke="#C0C0C0" transform="rotate(0 0 0) translate(0 0) scale(1 1)" />
          </g>
          
          </svg>
          
          
          </Col>
          
          
          <Col xs={3}></Col>
          
          </Row>
          )
      }
    })()}
    */}


</Container>

);
};

export default IntervalExample;






/*
                <svg width="100px" viewBox="0 -20 201 133"  class="mx-auto d-block">
                    <g>
                        <path fill="#BE0200" d="M116.3,21.1V10.7c-4.1,1.2-8.5,1.1-12.4-0.4v10.8H116.3z M97.2,21.1V10.3c-4,1.5-8.4,1.6-12.4,0.4v10.3H97.2z M121.8,27.3c-0.4,14.1-8.6,26.8-21.3,32.8c-12.7-6.1-20.9-18.7-21.3-32.8V0.5c4.9,6.3,15,7.6,21.3,1c4.8,5.3,14.9,6.8,21.3-1V27.3z M116.1,27.3H84.9c0.3,10.9,6.2,20.9,15.6,26.4C109.9,48.2,115.8,38.2,116.1,27.3"></path>
                    </g>
                    <g>
                        <path d="M58.1,107.4c0.9,0,1.8,0,2.6-0.2c2.9-0.6,5.1-3,5.4-6V82.6c0-0.1,0-0.3-0.1-0.4c-0.3-0.5-0.7-0.8-1.3-0.9c-1-0.2-1.9-0.4-2.9-0.5c-0.2,0-0.3,0-0.5,0h-0.9v-3.5h16v3.5H76c-0.2,0-0.3,0-0.5,0c-1,0.1-2,0.3-2.9,0.5c-0.5,0.1-1,0.4-1.3,0.9c-0.1,0.1-0.1,0.3-0.1,0.4v18.6c0,1.1-0.2,2.1-0.6,3.1c-1.2,3.1-3.6,5.5-6.6,6.7c-2.1,0.8-4.3,1.1-6.6,1c-2.2,0.1-4.5-0.3-6.6-1c-2.7-0.8-5-2.6-6.3-5.2c-0.6-1.2-0.9-2.5-0.9-3.8V82.6c0-0.1,0-0.3-0.1-0.4c-0.3-0.5-0.7-0.8-1.3-0.9c-1-0.3-2.1-0.4-3.2-0.6v-3.5h16.3v3.5c-1.1,0.1-2.1,0.3-3.2,0.6c-0.5,0.1-1,0.4-1.3,0.9c-0.1,0.1-0.1,0.3-0.1,0.4v18.7c0,2.7,1.9,5.1,4.5,5.8C56.2,107.3,57.1,107.4,58.1,107.4"></path>
                        <path d="M7.4,111.2v-3.9h0.1c0.9,0,1.9-0.1,2.8-0.1c0.6,0,1.2-0.1,1.8-0.3c0.4-0.2,0.8-0.6,0.8-1.1V81.1h-1.8c-0.6,0-1.3,0-1.9,0.1c-1,0.2-1.9,0.7-2.6,1.5c-1.3,1.5-2.2,3.3-2.7,5.2H0.5V77.2h32v10.7h-3.4c-0.5-1.9-1.4-3.7-2.7-5.2c-0.7-0.8-1.6-1.3-2.6-1.5c-0.6-0.1-1.2-0.1-1.9-0.1h-1.8v24.8c0,0.5,0.4,0.9,0.8,1.1c0.6,0.2,1.2,0.3,1.8,0.3c0.9,0,1.9,0.1,2.8,0.1h0.1v3.9H7.4z"></path>
                        <path d="M99.1,77.2c3.4,0.1,6.7,0.9,9.8,2.2c8,3.9,11.4,13.5,7.8,21.6c-3.2,5.9-9.1,9.8-15.8,10.3H82.9v-3.4c0.9,0,1.8-0.1,2.8-0.2c0.4,0,0.7-0.1,1.1-0.2c0.2,0,0.5-0.1,0.7-0.2c0.2-0.3,0.3-0.7,0.3-1.2V82.6c0-0.1,0-0.3,0-0.4c-0.1-0.6-0.4-1.1-0.9-1.3c-0.3-0.2-0.7-0.3-1.1-0.4c-0.9-0.2-1.8-0.3-2.7-0.4v-3H99.1z M94.8,81c-0.2,0.3-0.3,0.7-0.3,1.1l-0.1,24c0,0.4,0.1,0.8,0.3,1.2c0.2,0.1,0.4,0.2,0.7,0.2c0.4,0.1,0.7,0.1,1.1,0.2c0.9,0.1,1.8,0.1,2.8,0.1c3.7,0.4,7.2-1.4,9.2-4.5c2.8-5.4,2.7-11.9-0.2-17.3c-2-3.1-5.3-5.1-8.9-5.4c-0.9,0-1.8,0-2.8,0c-0.4,0-0.7,0.1-1.1,0.1C95.2,80.8,95,80.8,94.8,81"></path>
                        <path d="M141.6,75.9c10.1,0,18.3,8.2,18.3,18.3c0,10.1-8.2,18.3-18.3,18.3c-10.1,0-18.3-8.2-18.3-18.3C123.3,84.1,131.5,75.9,141.6,75.9 M149.3,104.3c1.7-3.1,2.5-6.6,2.3-10.1c0.2-3.5-0.6-7-2.3-10.1c-1.7-2.7-4.6-4.3-7.7-4.2c-3.1-0.1-6.1,1.5-7.7,4.2c-1.7,3.1-2.5,6.6-2.3,10.1c-0.2,3.5,0.6,7,2.3,10.1c1.7,2.7,4.6,4.3,7.7,4.2C144.7,108.6,147.6,107,149.3,104.3"></path>
                        <path d="M187.1,92c2.1-0.5,3.7-2.3,4-4.4c0.5-3-1.6-5.9-4.6-6.3c-0.1,0-0.2,0-0.3,0c-1.6-0.2-3.1-0.4-4.7-0.4h-6.7v11.7h8.7C184.7,92.5,185.9,92.3,187.1,92 M180.5,96.4h-5.7v9.5c0,0.4,0.2,0.7,0.4,0.9c0.1,0.1,0.2,0.1,0.2,0.1c0.4,0.1,0.8,0.2,1.2,0.3c0.9,0.1,1.8,0.2,2.7,0.3v3.7h-15.6v-3.8c0.9-0.1,1.8-0.2,2.7-0.3c0.4,0,0.8-0.1,1.2-0.3c0.1,0,0.2-0.1,0.2-0.1c0.3-0.2,0.4-0.6,0.4-0.9l0-23.4c0-0.4-0.1-0.7-0.4-0.9c-0.1-0.1-0.1-0.1-0.2-0.1c-0.4-0.2-0.7-0.3-1.1-0.4c-1-0.2-1.9-0.4-2.9-0.6v-3.3h24.2c1.7,0,3.3,0.5,4.7,1.4c2.5,1.7,4.1,4.5,4.3,7.5c0.4,5.4-3.7,10.1-9.1,10.5l6.7,9.1c0.5,0.7,1.3,1.2,2.2,1.3c1.2,0.2,2.5,0.3,3.7,0.4v3.8h-9.7L180.5,96.4z"></path>
                    </g>
                </svg>
*/