import * as React from 'react';
import "../i18n"; //import this
import { useTranslation } from "react-i18next";

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputDateTimeRangeField';
// import { MultiInputDateTimeRangeField } from '@mui/x-date-pickers-pro/MultiInputDateTimeRangeField';


// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


// const DateTimeRangeFieldValue = ({ DateTimeRange, setDateTimeRange}) => {
const DateTimeRangeFieldValue = ({ DateTimeRange, setDateTimeRange, hours, setHours }) => {
  const { t } = useTranslation();


  // const [value, setValue] = React.useState<DateRange<Dayjs>>(() => [
  //   dayjs('2022-04-17T15:30'),
  //   dayjs('2022-04-21T18:30'),
  // ]);


  return (

    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer components={['DateTimePicker']}>
      <Row>
        <Col>
          <DateTimePicker
          label={t("Chart.start")}
          value={DateTimeRange[0]}
          onChange={(newStartDate) => {
            const updatedDateTimeRange = [...DateTimeRange];
            updatedDateTimeRange[0] = newStartDate;
            setDateTimeRange(updatedDateTimeRange);
            // setHours(0);
          }}
          onOpen={() => { if(hours>0){setHours(0)} } }
          ampm={false}
          size='small'
          disableFuture={false}
          // disableFuture={true}
          format="HH:mm DD-MM-YYYY"
          // formatDensity='dense'
          />
        </Col>

        <Col>
          <DateTimePicker
          label={t("Chart.end")}
          value={DateTimeRange[1]}
          onChange={(newStartDate) => {
            const updatedDateTimeRange = [...DateTimeRange];
            updatedDateTimeRange[1] = newStartDate;
            setDateTimeRange(updatedDateTimeRange);
          }}
          onOpen={() => {setHours(-1)}}
          ampm={false}
          size='small'
          disableFuture={false}
          // disableFuture={true}
          format="HH:mm DD-MM-YYYY"
          // formatDensity='spacious'
          />
        </Col>
      </Row>
    </DemoContainer>
  </LocalizationProvider>
    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <DemoContainer
    //     components={[
    //       'SingleInputDateTimeRangeField',
    //       'SingleInputDateTimeRangeField',
    //     ]}
    //   >
    //     <SingleInputDateTimeRangeField
    //       label="Controlled field"
    //       value={DateTimeRange}
    //       onChange={(newValue) => setDateTimeRange(newValue)}
    //       // style={{ maxWidth: '500px' }}
    //       ampm={false}
    //       // color={'primary'}
    //       // fullWidth={true}
    //       // margin='dense'
    //       size='small' //medium
    //       // variant='outlined' //'standard''filled'

    //     />

    //     {/* <MultiInputDateTimeRangeField
    //       slotProps={{
    //         textField: ({ position }) => ({
    //           label: position === 'start' ? 'Check-in' : 'Check-out',
    //         }),
    //       }}

    //       label="Controlled field"
    //       value={DateTimeRange}
    //       onChange={(newValue) => setDateTimeRange(newValue)}
    //       // style={{ maxWidth: '500px' }}
    //       ampm={false}
    //       // color={'primary'}
    //       // fullWidth={true}
    //       // margin='dense'
    //       size='small' //medium
    //       // variant='outlined' //'standard''filled'

    //     /> */}

    //   </DemoContainer>
    // </LocalizationProvider>


  );
}

export default DateTimeRangeFieldValue;
