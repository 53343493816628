// import React, { useState, useEffect } from 'react';
import React from 'react';

//import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';


import Container from 'react-bootstrap/Container';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  

  const generateVector = (start, end, step) => {
    const vector = [];
    for (let i = start; i <= end; i += step) {
      vector.push(i);
    }
    return vector;
  }


  const option_wave = {
    pointRadius: 0,
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          display: false, // Cacher l'axe y
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        ticks: {
          display: false, // Cacher l'axe y
        }
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Vagues',
      },
    },
  };
  const Wave_line = (props) => {

    // let WVHT = props.WVHT;
    let WVHT = 1;
    let DPD = props.DPD;
    let APD = props.APD;             
    // let MWD = props.MWD;
    let n = 10;
    // let m = 2;
    let m = DPD/(DPD-APD);
    // let s = 0.9;
    
    let l = 5;


    const x = generateVector(0, 2*DPD*2*Math.PI, 0.1);
    // const y = x;
    // let y;
    let y = x.map(val => WVHT*Math.sin(val/DPD));
    let yi;

    // let y1 = x.map(val => (WVHT/(1+i))*( Math.sin(val/APD/i) + Math.sin(val/APD*i)) );
    // y = y.map((val, index) => val + y1[index]);

    // i=2;
    // let y2 = x.map(val => (WVHT/(1+i))*( Math.sin(val/APD/i) + Math.sin(val/APD*i)) );
    // y = y.map((val, index) => val + y2[index]);

    
    for (let i = 2; i <= n; i++) { // Ajout de la déclaration let et de l'opérateur <=
      // yi = x.map(val => (WVHT/(1+i))*( Math.sin(val/APD/i) + Math.sin(val/APD*i)) );
      // yi = x.map(val => (WVHT/(m**i))*( Math.sin(val/APD/m**i) + Math.sin(val/APD*m**i)) );
      // yi = x.map(val => (WVHT/(m**i))*( Math.sin(val/APD/m**i) + Math.sin(val/APD*m**i)) );
      // yi = x.map(val => (WVHT/(m**(i/s)))*( Math.sin(val/APD/m**(i/s)) + Math.sin(val/APD*m**(i/s))) );
      // yi = x.map(val => (WVHT/(2**i))*( Math.sin(val/APD/2**i) + Math.sin(val/APD*2**i)) );
      
      // yi = x.map(val => (WVHT/(m**i))*( Math.sin(val/APD/m**i) + Math.sin(val/APD*m**i)) );
      // yi = x.map(val => (WVHT/(l*i))*( Math.sin(val/APD/(m/i)) + Math.sin(val/APD*(m/i))) );
      // yi = x.map(val => (WVHT/(l*i))* Math.sin(val/(APD*(1-m))) + (WVHT/(l*i))* Math.sin(val/(APD*(1/m))) );
      // yi = x.map(val => (WVHT)* Math.sin(val/(APD+i)) + (WVHT)* Math.sin(val/(APD-i)) );

      yi = x.map(val => (WVHT/(m*i))* Math.sin(val*(-1/i+1/APD)) + (WVHT/(m*i))* Math.sin(val*(1/i+1/APD)) );
      // yi = x.map(val => (WVHT/(2**i))* Math.sin(val/(APD/(2**i))) + (WVHT/(2**i))* Math.sin(val/((2**i)*APD)) );

      y = y.map((val, index) => val + yi[index]);
    }


    const wave = {
        // labels: [],
        labels: x,
        datasets: [
        {
          label: 'Allure des vagues',
        //   data: [],
          data: y,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        // {
        //   label: 'Rafale',
        //   data: [],
        //   borderColor: 'rgb(255, 99, 132)',
        //   backgroundColor: 'rgba(255, 99, 132, 0.5)',
        //   tension: 0.3,
        //   spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        // },
        // {
        //   label: 'Vent minimum',
        //   data: [],
        //   borderColor: 'rgb(255, 216, 123)',
        //   backgroundColor: 'rgba(255, 230, 173)',
        //   tension: 0.3,
        //   spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        // },
      ],
    };

    return (
        <Container fluid>
          <div style={{height:"40vh"}}>
            <Line data={wave} options={option_wave}/>
            </div>
            <br></br>
        </Container>



    );
};

export default Wave_line;