import React, { useState } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';

import logo from '../img/icon-384x384.png';
import Contact from './contact'; // Assurez-vous que le chemin est correct


import contactimg from '../image/contact.png'



// const Login = ({ onLogin }) => {
const Login = ({ setLoggedInUser, setSource_list, setSpot }) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const response = await fetch('./login/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      });

      if (response.ok) {
        const userData = await response.json();
        // Les informations d'identification sont correctes, mettez à jour l'état loggedInUser
        // onLogin(userData.username);
        // onLogin(userData.username, userData.source_list, userData.def_spot);
        setLoggedInUser(userData.username);
        // setSource_list(userData.source_list);

        const sc_list = userData.source_list.split(',').map(item => item.trim()); // Convertir en tableau et supprimer les espaces
        setSource_list(sc_list);

        setSpot(userData.def_spot);
        console.log('userData.def_spot')
        console.log(userData.def_spot)

      } else {
        // Les informations d'identification sont incorrectes, gérez l'erreur
        setError('Nom d\'utilisateur ou mot de passe incorrect');
      }
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      setError('Erreur lors de la connexion. Veuillez réessayer plus tard.');
    }
  };

  return (
    <>
    <Row className="justify-content-center">
      <Col xs={8} sm={6} lg={4} className="mx-auto">
        <br />
        <br />
        <img
          src={logo}
          alt="Your App Logo"
          className="img-fluid mx-auto d-block mb-4"
          />

        <Form>
          <Form.Group controlId="formUsername">
            <Form.Label>Identifiant:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrer votre identifiant"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Mot de passe:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Entrer votre mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <br />

          <Button variant="primary" onClick={handleLogin}>
            Login
          </Button>

          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </Form>

        {/* <br></br>
        <br></br>
        <br></br>
        <p style={{ fontSize: "1em", color: "gray", textAlign: 'center', fontWeight:"150" }}>Téléphone : </p>
        <p>Adresse e-mail : </p> */}
        {/* <Contact /> */}
      </Col>
      
    </Row>

    <Row>

      <Col sm={2} lg={4}></Col>
      <Col sm={8} lg={4}>
        
        <h1 style={{
          color: "gray",
          textAlign:'center',
          fontSize:'18px',
          fontStyle:'italic'
        }}
        >
              <br></br>

          HEATECTOR
        </h1>

        <p>
          La solution pour objectiver le stress thermique WBGT °C, dans l’environnement de travail.
        </p>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={contactimg} alt="Contact" style={{ width: '50%' }} />
        </div>
        <br></br>

      </Col>
      <Col sm={2} lg={4}></Col>


    </Row>
    </>
  );
};

export default Login;
