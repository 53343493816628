// index.jsx
import { createRoot } from "react-dom/client";

import { App } from "./App";


import * as serviceWorker from './sw'


/* The following line can be included in your src/index.js or App.js file */
import 'bootstrap/dist/css/bootstrap.min.css';

const container = document.getElementById("app");
const root = createRoot(container)
root.render(<App />);




// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     const swUrl = new URL('./sw.js', import.meta.url);
//     navigator.serviceWorker.register(swUrl)
//     .then(registration => {
//       console.log('[SW] service Worker is registered', registration.scope)
//     })
//     .catch(err => {
//       console.error('[SW] service Worker registration failed:', err)
//     })
//   })
// }


// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     const swUrl = new URL('./sw.js', import.meta.url);
//     navigator.serviceWorker.register(swUrl)
//     .then(registration => {
//       console.log('[SW] service Worker is registered', registration.scope)
//     })
//     .catch(err => {
//       console.error('[SW] service Worker registration failed:', err)
//     })
//   })
// }



if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    const swUrl = new URL('./sw.js', import.meta.url);
    navigator.serviceWorker.register(swUrl)
    .then(registration => {
      // En cas de succès de l'enregistrement du service worker
      registration.addEventListener('updatefound', () => {
        const installingWorker = registration.installing;
        if (installingWorker) {
          // Écoutez l'événement "updatefound" pour détecter les mises à jour du service worker

          // En cas de nouvelle version disponible
          installingWorker.addEventListener('statechange', () => {
            if (installingWorker.state === 'installed') {
              // En cas de nouvelle version installée
              if (navigator.serviceWorker.controller) {
                // Vider le cache et forcer la récupération de la nouvelle version
                caches.open('my-cache-name').then(cache => {
                  cache.keys().then(keys => {
                    keys.forEach(request => cache.delete(request));
                  });
                });
                window.location.reload();
              }
            }
          });
        }
      });
    });
  })
}

  



// Intercepter les erreurs globales
window.onerror = function (message, source, lineno, colno, error) {
  // Vérifier si c'est une erreur "net::ERR_ABORTED 404"
  // if (message === "net::ERR_ABORTED") {
  if (message.includes("net::ERR_ABORTED")||message.includes("404")||message.includes("403")||message.includes("400")||message.includes("410")||message.includes("500")) {
      // Supprimer les caches existants
    if ('caches' in window) {
      caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      }).then(function() {
        // Recharger la fenêtre après la suppression des caches
        window.location.reload(true);
      });
    }
    // Effectuer d'autres actions en réponse à cette erreur, si nécessaire
  }
};
