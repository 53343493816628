{
    "language": "日本語",
    "locale": "ja-JP",

    "install": {
        "title": "Heatectorアプリをインストール！",
        "text": "Heatectorはコンピュータやスマートフォンに簡単にインストールできます。このアプリはWebアプリなので、スペースを取りません！",
        "button": "アプリをインストールするにはクリック！"
    },
    "contact": {
        "phone": "電話",
        "mail": "メール",
        "button": "アプリをインストールするにはクリック！"
    },
    "nav": {
        "Direct": "ライブ",
        "Map": "地図",
        "Montre": "時計"
    },


    "info": {
        "legend": {
            "header": "凡例",
            "varbl": "変数",
            "descr": "説明",

            "hist_forecast": "予想履歴",

            "wbt": "湿球温度",
            "tg": "ブラックグローブ温度",
            "ta": "空気温度",
            "rh": "相対湿度",
            "wbgt-M": "WBGT - 代謝",
            "redlimit": "経験的限界",
            "orangelimit": "適応した個人の限界",
            "yellowlimit": "非適応個人の限界",
            "safezone": "安全ゾーン",
            "coldzone": "寒冷地帯",
            "coldnote": "特定の材料の実装に注意",
            "M": "エネルギー代謝の推定",
            "CAV": "衣類の調整値"
        },

        "M": {
            "#0": "休憩",
            "#1": "低い",
            "#2": "適度な",
            "#3": "高い",
            "#4": "非常に高い"
        },

        "ME": {
            "header": "ISO 8996に基づくエネルギー代謝レベルの分類",
            "title": {
                "#0": "休憩",
                "#1": "低いエネルギー代謝",
                "#2": "適度なエネルギー代謝",
                "#3": "高いエネルギー代謝",
                "#4": "非常に高いエネルギー代謝"
            },
            "text": {
                "#0": "休憩、快適な座り姿勢",
                "#1": "軽作業（書き込み、タイピング、絵画、裁縫、会計）；手や腕の作業（小さな作業台ツール、検査、軽量材料の組み立てや分類）；手や足の作業（通常の条件下での車両の運転、フットスイッチやペダルの操作）。\n\n立ち作業：ドリル（小さい部品）；フライス（小さい部品）；巻き取り；小さなアーマチュアの巻き取り；低出力ツールによる加工；平らな表面での間欠的な歩行（最大速度2.5 km/h）。",
                "#2": "手や腕の持続的な作業（釘打ち、やすりがけ）；手や足の作業（トラック、トラクター、または設備現場の操作）；腕と体幹の作業（空気ハンマー作業、車両の連結、左官、中程度の重い材料の間欠的な取り扱い、雑草取り、耕作、果物や野菜の収穫；軽量カートや手押し車の押し引き；平らな表面での速度2.5〜5.5 km/hでの歩行；鍛造）。",
                "#3": "腕と体幹の激しい作業；重い材料の輸送；シャベル作業；ハンマー作業；堅木を切ったり削ったり彫ったりする作業；手動での芝刈り；掘削作業；平らな表面での速度5.5〜7 km/hでの歩行。<br/><br/>重く積まれた手押しカートや手押し車の押し引き；成形部品からのチップの除去；コンクリートブロックの敷設。",
                "#4": "最大に近い速いペースでの非常に激しい活動；斧作業；激しいペースでのシャベルや掘削；階段、ランプ、またははしごの登り降り；短いステップでの速い歩行；平らな表面での走行；平らな表面での速度が7 km/hを超える歩行。"
            },
            "richtext": {
                "#0": "休憩、快適な座り姿勢",
                "#1": "軽作業（書き込み、タイピング、絵画、裁縫、会計）；手や腕の作業（小さな作業台ツール、検査、軽量材料の組み立てや分類）；手や足の作業（通常の条件下での車両の運転、フットスイッチやペダルの操作）。\n\n立ち作業：ドリル（小さい部品）；フライス（小さい部品）；巻き取り；小さなアーマチュアの巻き取り；低出力ツールによる加工；平らな表面での間欠的な歩行（最大速度2.5 km/h）。",
                "#2": "手や腕の持続的な作業（釘打ち、やすり掛け）；腕と脚の作業（トラック、トラクター、または設備現場での操作）；腕と胴の作業（空気ハンマー作業、車両の結合、左官、中程度の重い材料の断続的な取り扱い、除草、くわおよび果物や野菜の収穫；軽いカートや手押し車の押し引き；平坦な表面での時速2.5〜5.5 km/hの歩行；鍛造）。",
                "#3": "腕と胴の激しい作業；重い材料の運搬；シャベル作業；ハンマー作業；堅木の切断、平滑化、または彫刻；手作業の刈り取り；掘削；平坦な表面での時速5.5〜7 km/hの歩行。<br/><br/>手押し車や手押し車の重荷の押し引き；成形部品からのチップの除去；コンクリートブロックの敷設。",
                "#4": "最大速度に近い速いペースでの非常に激しい活動；斧作業；激しいペースでのシャベルや掘削；階段、斜面、またははしごの登り；短いステップでの速い歩行；平坦な表面での走行；平坦な表面での時速7 km/h以上の歩行。"
            },

            "#0": "休憩",
            "#1": "低エネルギー代謝",
            "#2": "中程度のエネルギー代謝",
            "#3": "高いエネルギー代謝",
            "#4": "非常に高いエネルギー代謝"
        },


        "CAV": {
            "header": "異なる衣類のウェットブルブ球温度指数の調整値（CAV）（°C-WBGT）",
            "title": {
                "sc": "フードなしの衣類",
                "sc_a": "作業服",
                "sc_b": "オーバーオール",
                "sc_c": "単層の不織布SMSオーバーオール",
                "sc_d": "単層の不織ポリオレフィンオーバーオール",
                "sc_e": "長袖エプロンと長ズボンの防蒸気エプロン",
                "sc_f": "2層の織物衣類",
                "sc_g": "フードなしの単層の防蒸気オーバーオール",
                "sc_h": "フードなしのオーバーオールを防蒸気にする",
    
                "ac": "フード付きの衣類",
                "ac_a": "作業服 + フード",
                "ac_b": "オーバーオール + フード",
                "ac_c": "フード付きの単層の不織SMSオーバーオール",
                "ac_d": "フード付きの単層の不織ポリオレフィンオーバーオール",
                "ac_e": "長袖エプロンと長ズボンの防蒸気エプロン + フード",
                "ac_f": "2層の織物衣類 + フード",
                "ac_g": "フード付きの単層の防蒸気オーバーオール",
    
                "c": "フード"
            },
            "text": {
                "sc_a": "織物で作られた作業服が基準の衣装です。",
                "sc_b": "処理された綿を含む織物。",
                "sc_c": "ポリプロピレンから不織布を製造するための非特許プロセス。",
                "sc_d": "ポリエチレンから作られた特許布。",
                "sc_e": "巻き込みエプロン構成は、化学物質の偶発的なこぼれから体の前面と側面を保護するように設計されています。",
                "sc_f": "一般的には作業服の上にオーバーオールと考えられています。",
                "sc_g": "実際の効果は湿度のレベルに依存し、多くの場合、効果は軽微です。",
                "sc_h": "-",
    
                "ac_g": "実際の効果は湿度のレベルに依存し、多くの場合、効果は軽微です。",
    
                "c": "どの衣類でもフード付きの布を着用します。この値は、フードなしの衣装または呼吸器具のCAV値に追加されます。",
                
                "note": "注：蒸発に対する高い抵抗を持つ衣類の場合、相対湿度に依存する関係があります。 CAV値は、おそらく高い値を表します。"
            },
            "richtext": {
                "c": "どの衣類でもフード付きの布を着用します。<br/><br/><i>この値は、フードなしの衣装または呼吸器具のCAV値に追加されます。</i>",
                
                "note": "<i>注：蒸発に対する高い抵抗を持つ衣類の場合、相対湿度に依存する関係があります。 CAV値は、おそらく高い値を表します。</i>"
            }
        }
  },

  

  "Chart": {
    "spotselect": "スポットを選択",
    "spotnotfound": "スポットが見つかりません",

    "Metabolisme": "代謝",

    "start": "開始",
    "end": "終了",


    "Perso": "パーソナル",
    "2h3h6h12h24h": "2時間 - 6時間 - 12時間 - 24時間",
    
    "2h": "2時間",
    "6h": "6時間",
    "12h": "12時間",
    "24h": "24時間",
    "2d_prev": "過去2日",


    "forecast": "予想"
},

"Map": {
    "h": {
        "Options": "オプション",
        "Map": "地図",
        "swisslayer": "追加レイヤー"
    },
    "button": {
        "Wind": "風",
        "WBGT": "WBGT",
        "Temp": "気温",
        "Temperature": "温度",
        "Hum": "湿度",
        "Press": "気圧",
        "Lux": "照度",
        "Water": "水",
        "Wave": "波",
        "Period": "期間",
        "Tide": "潮汐",

        "Gust": "突風",
        "min_Wind": "最低風速",
        "Sunshine": "日照",
        "precip_intensity": "降水強度",
        "Precip": "降水量",
        "avg_wave_period": "平均波周期",
        "dom_wave_period": "卓越波周期",
        "wave_dir": "平均波向",
        "wave_height": "有義波高"
    }
},

"ExportButton": {
    "export": "エクスポート",
    "csv": "CSVエクスポート",
    "excel": "Excelエクスポート",
    "pdf": "PDFエクスポート",
    "pdf_hot": "PDFエクスポート - 熱",
    "pdf_cold": "PDFエクスポート - 冷"
},

"Certif": {
    "cert_clim": "気候証明書",
    "cert": "証明書",
    "hot": "高温",
    "cold": "極寒",
    "lab": "ISO 7243規格に従って構築されたWBGT¹温度センサーで行われた測定、ISO 1725認定ラボによるテスト²を受けました。",

    "comp": "会社",
    "ide": "IDE",
    "lieu": "場所名",
    "long": "経度",
    "lat": "緯度",
    "date": "証明書発行日",
    "start": "開始",
    "end": "終了",
    "metabo": "エネルギー代謝",
    "cav": "CAV⁴（衣類）",

    "exmp": "例",
    "comm": "コメント",
    "shirt": "衣類",
    

    "bp1": "¹ WBGT温度指数は、温度、湿度、直接および間接的な太陽放射に関連する体の熱ストレスを量化します。",
    "bp2": "² 要求に応じて、測定プロトコルと品質管理手順を提供できます。",

    "voir": "表示",
    "Anx_a": "付属書A",
    "Anx_b": "付属書B",

    "redPeriode": "適応した個人の限界を超える期間",
    "orangePeriode": "非適応個人の限界を超える期間",
    "coldPeriode": "5°C以下の温度の期間",



    "csvxls": {
        "date": "日付",
        "limAcc": "適応した限界 °C",
        "limNAcc": "非適応限界 °C",
        "temp": "温度"
    }
}
    

}
