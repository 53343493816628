{
    "language": "Italiano",
    "locale": "it-IT",

    "install": {
        "title": "Installa l'app Heatector!",
        "text": "Heatector si installa facilmente su computer o telefono. L'app non occupa spazio, poiché è una Webapp!",
        "button": "Clicca per installare l'app!"
    },

    "contact": {
        "phone": "Telefono",
        "mail": "Mail",
        "button": "Clicca per installare l'app!"
    },

    "nav": {
        "Direct": "Diretto",
        "Map": "Mappa",
        "Montre": "Orologio"
    },

    "info": {
        "legend": {
            "header": "Leggende",
            "varbl": "Variabile",
            "descr": "Descrizione",

            "hist_forecast": "Storico delle previsioni",

            "wbt": "Temperatura del bulbo umido",
            "tg": "Temperatura della sfera nera",
            "ta": "Temperatura dell'aria",
            "rh": "Umidità relativa",

            "wbgt-M": "WBGT - Metabolismo",

            "redlimit": "Limite empirico",
            "orangelimit": "Limite per persone acclimatate",
            "yellowlimit": "Limite per persone non acclimatate",
            "safezone": "Zona sicura",
            "coldzone": "Zona fredda",
            "coldnote": "attenzione all'implementazione di determinati materiali",
            "M": "Stima del metabolismo energetico",
            "CAV": "Valore di aggiustamento per l'abbigliamento"
        },

        "M": {
            "#0": "Riposo",
            "#1": "Basso",
            "#2": "Moderato",
            "#3": "Alto",
            "#4": "Molto alto"
        },

        "ME": {
            "header": "Classificazione dei livelli di metabolismo energetico secondo ISO 8996",
            "title": {
                "#0": "Riposo",
                "#1": "Basso metabolismo energetico",
                "#2": "Metabolismo energetico moderato",
                "#3": "Alto metabolismo energetico",
                "#4": "Molto alto metabolismo energetico"
            },
            "text": {
                "#0": "Riposo, posizione seduta confortevole",
                "#1": "Lavoro manuale leggero (scrittura, digitazione, disegno, cucito, contabilità); lavoro di mani e braccia (piccoli utensili da banco, ispezione, montaggio o ordinamento di materiali leggeri); lavoro di braccia e gambe (guida di un veicolo in condizioni normali, azionamento di un interruttore a pedale o a pedale).\n\nIn piedi: foratura (pezzi piccoli); fresatura (pezzi piccoli); avvolgimento; avvolgimento di piccoli avvolgimenti; lavorazione con utensili a bassa potenza; camminata occasionale su una superficie piana (velocità fino a 2,5 km/h).",
                "#2": "Lavoro sostenuto di mani e braccia (chiodatura, limatura); lavoro di braccia e gambe (manovra su camion, trattore o siti di attrezzature); lavoro di braccia e tronco (lavoro a martello pneumatico, accoppiamento veicoli, stuccatura, gestione intermittente di materiali moderatamente pesanti, diserbo, zappatura, raccolta di frutta o verdura; spinta o trazione di carrelli leggeri o carriole; camminata a una velocità di 2,5 a 5,5 km/h su una superficie piana; forgiatura).",
                "#3": "Lavoro intenso di braccia e tronco; trasporto di materiali pesanti; scavo; lavoro di martellatura; segatura, piallatura o scalpello in legno duro; falciatura manuale; scavo; camminata a una velocità di 5,5 a 7 km/h su una superficie piana.\n\nSpinta o trazione di carrelli a mano o carriole pesantemente caricati; rimozione di trucioli da parti stampate; posa di blocchi di calcestruzzo.",
                "#4": "Attività molto intensa a un ritmo veloce vicino al massimo; lavoro con l'accetta; scavo o scavo a un ritmo intensivo; salita di scale, di una rampa o di una scala; camminata veloce a passi corti; corsa su una superficie piana; camminata a una velocità superiore a 7 km/h su una superficie piana."
            },
            "richtext": {
                "#0": "Riposo, posizione seduta confortevole",
                "#1": "Lavoro manuale leggero (scrittura, digitazione, disegno, cucito, contabilità); lavoro di mani e braccia (piccoli utensili da banco, ispezione, montaggio o ordinamento di materiali leggeri); lavoro di braccia e gambe (guida di un veicolo in condizioni normali, azionamento di un interruttore a pedale o a pedale).<br/><br/> In piedi: foratura (pezzi piccoli); fresatura (pezzi piccoli); avvolgimento; avvolgimento di piccole armature; lavorazione con utensili di bassa potenza; camminata occasionale su una superficie piana (velocità fino a 2,5 km/h).",
                "#2": "Lavoro prolungato delle mani e delle braccia (chiodatura, limatura); lavoro delle braccia e delle gambe (manovra su siti di camion, trattori o attrezzature); lavoro delle braccia e del tronco (lavoro con martello pneumatico, accoppiamento di veicoli, stuccatura, movimentazione intermittente di materiali moderatamente pesanti, sarchiatura, zappatura, raccolta di frutta o verdura; spinta o trascinamento di carrelli leggeri o carriole; camminata a una velocità da 2,5 a 5,5 km/h su una superficie piana; forgia).",
                "#3": "Lavoro intenso delle braccia e del tronco; trasporto di materiali pesanti; scavo con la pala; lavoro di martellatura; segatura, piallatura o scalpello su legno duro; taglio manuale dell'erba; scavo; camminata a una velocità da 5,5 a 7 km/h su una superficie piana.<br/><br/> Spinta o trascinamento di carrelli a mano o carriole pesantemente carichi; rimozione di schegge da parti stampate; posa di blocchi di calcestruzzo.",
                "#4": "Attività molto intensa a un ritmo veloce vicino al massimo; lavoro con l'ascia; scavo o escavazione a un ritmo intensivo; scalata di scale, una rampa o una scala a pioli; camminata veloce a passo corto; corsa su una superficie piana; camminata a una velocità superiore a 7 km/h su una superficie piana."
            },

            "#0": "Riposo",
            "#1": "Metabolismo energetico basso",
            "#2": "Metabolismo energetico moderato",
            "#3": "Metabolismo energetico alto",
            "#4": "Metabolismo energetico molto alto"
        },
        "CAV": {
            "header": "Valori di regolazione dell'indice WBGT per l'abbigliamento (CAV) per differenti abiti in °C-WBGT",
            "title": {
                "sc": "Abiti senza cappuccio",
                "sc_a": "Vestiti da lavoro",
                "sc_b": "Tute",
                "sc_c": "Tute non tessute in SMS a singolo strato",
                "sc_d": "Tute in poliolefina non tessute a singolo strato",
                "sc_e": "Grembiule antivapore con maniche lunghe e tute lunghe",
                "sc_f": "Doppio strato di abbigliamento in tessuto",
                "sc_g": "Tute antivapore a singolo strato, senza cappuccio",
                "sc_h": "Tute antivapore sopra le tute, senza cappuccio",
                "ac": "Abiti con cappuccio",
                "ac_a": "Vestiti da lavoro + cappuccio",
                "ac_b": "Tute + cappuccio",
                "ac_c": "Tute non tessute in SMS a singolo strato + cappuccio",
                "ac_d": "Tute in poliolefina non tessute a singolo strato + cappuccio",
                "ac_e": "Grembiule antivapore con maniche lunghe e tute lunghe + cappuccio",
                "ac_f": "Doppio strato di abbigliamento in tessuto + cappuccio",
                "ac_g": "Tute antivapore con cappuccio a singolo strato",
                "c": "Cappuccio"
            },
            "text": {
                "sc_a": "I vestiti da lavoro in tessuto sono l'abbigliamento di riferimento.",
                "sc_b": "Tessuto in cotone trattato.",
                "sc_c": "Processo non proprietario per la produzione di tessuti non tessuti in polipropilene.",
                "sc_d": "Tessuto proprietario realizzato in polietilene.",
                "sc_e": "La configurazione del grembiule avvolgente è progettata per proteggere le parti frontali e laterali del corpo da eventuali fuoriuscite accidentali di sostanze chimiche.",
                "sc_f": "Generalmente considerato come tuta sopra i vestiti da lavoro.",
                "sc_g": "L'effetto effettivo dipende dal livello di umidità e, in molti casi, l'effetto è minore.",
                "sc_h": "-",
                "ac_g": "L'effetto effettivo dipende dal livello di umidità e, in molti casi, l'effetto è minore.",
                "c": "Indossare un tessuto con cappuccio con qualsiasi tipo di abbigliamento. Questo valore viene aggiunto al valore CAV dell'abbigliamento senza cappuccio o attrezzatura respiratoria.",
                "note": "NOTA: Per l'abbigliamento con alta resistenza all'evaporazione, vi è una dipendenza dall'umidità relativa. I valori CAV rappresentano il valore probabile più alto."
            },
            "richtext": {
                "c": "Indossare un tessuto con cappuccio con qualsiasi tipo di abbigliamento.<br/><br/><i>Questo valore viene aggiunto al valore CAV dell'abbigliamento senza cappuccio o attrezzatura respiratoria.</i>",
                "note": "<i>NOTA: Per l'abbigliamento con alta resistenza all'evaporazione, vi è una dipendenza dall'umidità relativa. I valori CAV rappresentano il valore probabile più alto.</i>"
            }
        }
    },
    "Chart": {
        "spotselect": "Seleziona un punto",
        "spotnotfound": "Punto non trovato",
        "Metabolisme": "Metabolismo",
        "start": "Inizio",
        "end": "Fine",


        "Perso": "Personalizzato",
        "2h3h6h12h24h": "2ore - 6ore - 12ore - 24ore",
        
        "2h": "2 ore",
        "6h": "6 ore",
        "12h": "12 ore",
        "24h": "24 ore",
        "2d_prev": "Preced. 2 gg",


        "forecast": "Prévision"
    },
    "Map": {
        "h": {
            "Options": "Opzioni",
            "Map": "Mappa",
            "swisslayer": "Strati aggiuntivi"
        },
        "button": {
            "Wind": "Vento",
            "WBGT": "WBGT",
            "Temp": "Temp.",
            "Temperature": "Temperatura",
            "Hum": "Umidità",
            "Press": "Pressione",
            "Lux": "Lux",
            "Water": "Acqua",
            "Wave": "Onda",
            "Period": "Periodo",
            "Tide": "Marea",

            "Gust": "Raffica",
            "min_Wind": "Vento minimo",
            "Sunshine": "Insolazione",
            "precip_intensity": "Intensità delle precipitazioni",
            "Precip": "Precipitazioni",
            "avg_wave_period": "Periodo medio delle onde",
            "dom_wave_period": "Periodo dell'onda dominante",
            "wave_dir": "Direzione media delle onde",
            "wave_height": "Altezza significativa delle onde"
        }
    },
    "ExportButton": {
        "export": "Esporta",
        "csv": "Esporta CSV",
        "excel": "Esporta Excel",
        "pdf": "Esporta PDF",
        "pdf_hot": "Esporta PDF - Caldo",
        "pdf_cold": "Esporta PDF - Freddo"
    },
    "Certif": {
        "cert_clim": "Certificato climatico",
        "cert": "Certificato",
        "hot": "Temperature elevate",
        "cold": "Freddo estremo",
        "lab": "Misure effettuate con sensori di temperatura WBGT¹ costruiti secondo lo standard ISO 7243, sottoposti a test² da parte di un laboratorio accreditato ISO 1725.",
        "comp": "Azienda",
        "ide": "IDE",
        "lieu": "Nome del luogo",
        "long": "Longitudine",
        "lat": "Latitudine",
        "date": "Data di emissione del certificato",
        "start": "Inizio",
        "end": "Fine",
        "metabo": "Metabolismo energetico",
        "cav": "CAV⁴ (abbigliamento)",
        "exmp": "Esempio",
        "comm": "Commento",
        "shirt": "Abiti",
        "bp1": "¹ L'indice di temperatura WBGT quantifica lo stress termico sul corpo, legato alla temperatura, all'umidità e alla radiazione solare diretta e indiretta.",
        "bp2": "² Su richiesta, possiamo fornirti il nostro protocollo di misurazione e le procedure di controllo della qualità.",
        "voir": "Visualizza",
        "Anx_a": "Allegato A",
        "Anx_b": "Allegato B",
        "redPeriode": "Periodo(i) oltre il limite per individui acclimatati",
        "orangePeriode": "Periodo(i) oltre il limite per individui non acclimatati",
        "coldPeriode": "Periodo(i) di temperatura inferiore a 5°C",



        "csvxls":{
            "date": "Data",
            "limAcc": "Limite acclimatato °C",
            "limNAcc": "Limite non acclimatato °C",
            "temp": "Temperatura"
        }
    }
}
