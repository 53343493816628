{
    "language": "Español",
    "locale": "es-ES",

    "install": {
        "title": "¡Instala la App Heatector!",
        "text": "Heatector se instala fácilmente en la computadora o el teléfono. ¡La App no ocupa espacio, ya que es una Webapp!",
        "button": "¡Haz clic para instalar la App!"
    },

    "contact": {
        "phone": "Teléfono",
        "mail": "Correo",
        "button": "¡Haz clic para instalar la App!"
    },

    "nav": {
        "Direct": "En Vivo",
        "Map": "Mapa",
        "Montre": "Reloj"
    },

    "info": {
        "legend": {
            "header": "Leyendas",
            "varbl": "Variable",
            "descr": "Descripción",

            "hist_forecast": "Historial de pronósticos",

            "wbt": "Temperatura de bulbo húmedo",
            "tg": "Temperatura de globo negro",
            "ta": "Temperatura del aire",
            "rh": "Humedad relativa",

            "wbgt-M": "WBGT - Metabolismo",

            "redlimit": "Límite empírico",
            "orangelimit": "Límite para individuos aclimatados",
            "yellowlimit": "Límite para individuos no aclimatados",
            "safezone": "Zona de seguridad",
            "coldzone": "Zona fría",
            "coldnote": "atención a la implementación de ciertos materiales",
            "M": "Estimación del metabolismo energético",
            "CAV": "Valor de ajuste para la ropa"
        },

        "M": {
            "#0": "Reposo",
            "#1": "Bajo",
            "#2": "Moderado",
            "#3": "Alto",
            "#4": "Muy alto"
        },

        "ME": {
            "header": "Clasificación de los niveles de metabolismo energético según ISO 8996",
            "title": {
                "#0": "Reposo",
                "#1": "Metabolismo energético bajo",
                "#2": "Metabolismo energético moderado",
                "#3": "Metabolismo energético alto",
                "#4": "Metabolismo energético muy alto"
            },
            "text": {
                "#0": "Reposo, posición sentada cómoda",
                "#1": "Trabajo manual ligero (escribir, teclear, dibujar, coser, contabilidad); trabajo de manos y brazos (herramientas pequeñas, inspección, ensamblaje o clasificación de materiales ligeros); trabajo de brazos y piernas (conducir un vehículo en condiciones normales, operar un interruptor de pie o pedal).\n\nDe pie: taladrado (piezas pequeñas); fresado (piezas pequeñas); bobinado; bobinado de armaduras pequeñas; mecanizado con herramientas de baja potencia; caminata ocasional en superficie plana (velocidad hasta 2.5 km/h).",
                "#2": "Trabajo sostenido de manos y brazos (clavado, limado); trabajo de brazos y piernas (maniobras en camión, tractor o sitios de equipo); trabajo de brazos y tronco (trabajo con martillo neumático, acoplamiento de vehículos, enlucido, manejo intermitente de materiales moderadamente pesados, deshierbe, azadonado, recolección de frutas o verduras; empujar o tirar de carretillas ligeras; caminar a una velocidad de 2.5 a 5.5 km/h en superficie plana; forja).",
                "#3": "Trabajo intenso de brazos y tronco; transporte de materiales pesados; palear; trabajo con martillo; aserrar, cepillar o cincelar madera dura; segado manual; excavación; caminar a una velocidad de 5.5 a 7 km/h en superficie plana.\n\nEmpujar o tirar de carretillas pesadamente cargadas; retirada de virutas de piezas moldeadas; colocación de bloques de hormigón.",
                "#4": "Actividad muy intensa a un ritmo rápido cerca del máximo; trabajo con hacha; palear o excavar a un ritmo intensivo; subir escaleras, una rampa o una escalera; caminar rápido con pasos cortos; correr en superficie plana; caminar a una velocidad superior a 7 km/h en superficie plana."
            },
            "richtext": {
                "#0": "Reposo, posición sentada cómoda",
                "#1": "Trabajo manual ligero (escribir, teclear, dibujar, coser, contabilidad); trabajo de manos y brazos (herramientas pequeñas, inspección, ensamblaje o clasificación de materiales ligeros); trabajo de brazos y piernas (conducir un vehículo en condiciones normales, operar un interruptor de pie o pedal).<br/><br/> De pie: taladrado (piezas pequeñas); fresado (piezas pequeñas); bobinado;<br/> bobinado de armaduras pequeñas; mecanizado con herramientas de baja potencia; caminata ocasional en superficie plana (velocidad hasta 2.5 km/h).",
                "#2": "Trabajo sostenido de manos y brazos (clavado, limado); trabajo de brazos y piernas (maniobras en camión, tractor o sitios de equipo); trabajo de brazos y tronco (trabajo con martillo neumático, acoplamiento de vehículos, enlucido, manejo intermitente de materiales moderadamente pesados, deshierbe, azadonado, recolección de frutas o verduras; empujar o tirar de carretillas ligeras; caminar a una velocidad de 2.5 a 5.5 km/h en superficie plana; forja).",
                "#3": "Trabajo intenso de brazos y tronco; transporte de materiales pesados; palear; trabajo con martillo; aserrar, cepillar o cincelar madera dura; segado manual; excavación; caminar a una velocidad de 5.5 a 7 km/h en superficie plana.<br/><br/>Empujar o tirar de carretillas pesadamente cargadas; retirada de virutas de piezas moldeadas; colocación de bloques de hormigón.",
                "#4": "Actividad muy intensa a un ritmo rápido cerca del máximo; trabajo con hacha; palear o excavar a un ritmo intensivo; subir escaleras, una rampa o una escalera; caminar rápido con pasos cortos; correr en superficie plana; caminar a una velocidad superior a 7 km/h en superficie plana."
            },

            "#0": "Reposo",
            "#1": "Metabolismo energético bajo",
            "#2": "Metabolismo energético moderado",
            "#3": "Metabolismo energético alto",
            "#4": "Metabolismo energético muy alto"
        },


        "CAV": {
            "header": "Valores de ajuste del índice WBGT para la ropa (CAV) para diferentes conjuntos de ropa en °C-WBGT",
            "title": {
                "sc": "Ropa sin capucha",
                "sc_a": "Ropa de trabajo",
                "sc_b": "Monos",
                "sc_c": "Monos SMS no tejidos de una sola capa",
                "sc_d": "Monos de poliolefina no tejida de una sola capa",
                "sc_e": "Delantal impermeable con mangas largas y monos largos",
                "sc_f": "Doble capa de ropa tejida",
                "sc_g": "Monos impermeables de una sola capa, sin capucha",
                "sc_h": "Sobre-monos impermeables, sin capucha",
    
                "ac": "Ropa con capucha",
                "ac_a": "Ropa de trabajo + capucha",
                "ac_b": "Monos + capucha",
                "ac_c": "Monos SMS no tejidos de una sola capa + capucha",
                "ac_d": "Monos de poliolefina no tejida de una sola capa + capucha",
                "ac_e": "Delantal impermeable con mangas largas y monos largos + capucha",
                "ac_f": "Doble capa de ropa tejida + capucha",
                "ac_g": "Monos impermeables con capucha de una sola capa",

                "c": "Capucha"
            },
            "text": {
                "sc_a": "La ropa de trabajo de tela tejida es el conjunto de referencia.",
                "sc_b": "Tela tejida que contiene algodón tratado.",
                "sc_c": "Proceso no patentado para fabricar telas no tejidas a partir de polipropileno.",
                "sc_d": "Tela patentada hecha de polietileno.",
                "sc_e": "La configuración de delantal envolvente está diseñada para proteger las partes frontales y laterales del cuerpo de derrames accidentales de productos químicos.",
                "sc_f": "Generalmente se considera como un mono sobre la ropa de trabajo.",
                "sc_g": "El efecto real depende del nivel de humedad y, en muchos casos, el efecto es menor.",
                "sc_h": "-",

                "ac_g": "El efecto real depende del nivel de humedad y, en muchos casos, el efecto es menor.",

                "c": "Llevar una tela con capucha con cualquier conjunto de ropa. Este valor se añade al valor CAV del conjunto sin capucha ni equipo respiratorio.",
                
                "note": "NOTA: Para ropa con alta resistencia a la evaporación, existe una dependencia de la humedad relativa. Los valores de CAV representan el valor máximo probable."
            },

            "richtext": {
                "c": "Llevar una tela con capucha con cualquier conjunto de ropa.<br/><br/><i>Este valor se añade al valor CAV del conjunto sin capucha ni equipo respiratorio.</i>",
                
                "note": "<i>NOTA: Para ropa con alta resistencia a la evaporación, existe una dependencia de la humedad relativa. Los valores de CAV representan el valor máximo probable.</i>"
            }
        }
    },

    "Chart": {
        "spotselect": "Seleccionar un punto",
        "spotnotfound": "Punto no encontrado",

        "Metabolisme": "Metabolismo",

        "start": "Inicio",
        "end": "Fin",

        "Perso": "Personalizado",
        "2h3h6h12h24h": "2h - 6h - 12h - 24h",
        
        "2h": "2 h",
        "6h": "6 h",
        "12h": "12 h",
        "24h": "24 h",
        "2d_prev": "2 días prev.",


        "forecast": "Previsión"
    },

    "Map": {
        "h":{
            "Options": "Opciones",
            "Map": "Mapa",
            "swisslayer": "Capas adicionales"
        },
        "button":{
            "Wind": "Viento",
            "WBGT": "WBGT",
            "Temp": "Temp.",
            "Temperature": "Temperatura",
            "Hum": "Humedad",
            "Press": "Presión",
            "Lux": "Lux",
            "Water": "Agua",
            "Wave": "Ola",
            "Period": "Periodo",
            "Tide": "Marea",
            
            "Gust": "Ráfaga",
            "min_Wind": "Viento mínimo",
            "Sunshine": "Luz solar",
            "precip_intensity": "Intensidad de precipitación",
            "Precip": "Precipitación",
            "avg_wave_period": "Período medio de ola",
            "dom_wave_period": "Período de ola dominante",
            "wave_dir": "Dirección media de las olas",
            "wave_height": "Altura significativa de las olas"
        }
    },

    "ExportButton": {
        "export": "Exportar",
        "csv": "Exportar CSV",
        "excel": "Exportar Excel",
        "pdf": "Exportar PDF",
        "pdf_hot": "Exportar PDF - Calor",
        "pdf_cold": "Exportar PDF - Frío"
    },

    "Certif": {
        "cert_clim": "Certificado Climático",
        "cert": "Certificado",
        "hot": "Altas Temperaturas",
        "cold": "Frío Extremo",
        "lab": "Mediciones tomadas con sensores de temperatura WBGT¹ construidos según la norma ISO 7243, sometidos a pruebas² por un laboratorio acreditado por ISO 1725.",

        "comp": "Empresa",
        "ide": "IDE",
        "lieu": "Nombre del lugar",
        "long": "Longitud",
        "lat": "Latitud",
        "date": "Fecha de emisión del certificado",
        "start": "Inicio",
        "end": "Fin",
        "metabo": "Metabolismo Energético",
        "cav": "CAV⁴ (ropa)",

        "exmp": "Ejemplo",
        "comm": "Comentario",
        "shirt": "Ropa",

        "bp1": "¹ El índice de temperatura WBGT cuantifica el estrés térmico en el cuerpo, relacionado con la temperatura, la humedad y la radiación solar directa e indirecta.",
        "bp2": "² A solicitud, podemos proporcionarle nuestro protocolo de medición y procedimientos de control de calidad.",

        "voir": "Ver",
        "Anx_a": "Anexo A",
        "Anx_b": "Anexo B",

        "redPeriode": "Periodo(s) más allá del límite para individuos aclimatados",
        "orangePeriode": "Periodo(s) más allá del límite para individuos no aclimatados",
        "coldPeriode": "Periodo(s) de temperatura por debajo de 5°C",

        "csvxls": {
            "date": "Fecha",
            "limAcc": "Límite Aclimatado °C",
            "limNAcc": "Límite No Aclimatado °C",
            "temp": "Temperatura"
        }
    }
}
