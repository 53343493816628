import React, { useState, useEffect } from 'react';
import "./i18n"; //import this
import { useTranslation } from "react-i18next";

//import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BubbleController,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

import annotationPlugin from 'chartjs-plugin-annotation';

// Chart.register(annotationPlugin);
ChartJS.register(annotationPlugin);


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BubbleController,
  Title,
  Tooltip,
  Legend,
  // annotationPlugin
);





import Button from 'react-bootstrap/Button';

import MapIcon from '@mui/icons-material/Map';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';
import WindPowerRoundedIcon from '@mui/icons-material/WindPowerRounded';
import { WiThermometer, WiThermometerExterior, WiThermometerInternal, WiSandstorm, WiStrongWind, WiHumidity, WiBarometer, WiRaindrop, WiRaindrops, WiDaySunny } from "react-icons/wi";
import { TbWind, TbWindmillFilled, TbWindmill } from "react-icons/tb";
import { FiWind } from "react-icons/fi";
import { GiWindsock,GiHighTide, GiLowTide} from "react-icons/gi";
import { MdOutlineWindPower } from "react-icons/md";
import { RiWaterPercentFill, RiWaterPercentLine } from "react-icons/ri";

import { IoWater, IoWaterOutline, IoThermometerOutline } from "react-icons/io5";
import { MdOutlineWaterDrop, MdWaterDrop } from "react-icons/md";
import { CiPercent } from "react-icons/ci";


import { BsWind, BsWater, BsFillDropletFill, BsDropletHalf, BsDroplet, BsPercent, BsThermometerHalf, BsFillRocketTakeoffFill, BsFillSunriseFill, BsFillSunsetFill, BsTsunami } from "react-icons/bs";
import { PiNavigationArrowFill, PiWaveSineBold } from "react-icons/pi";











import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl'
import Select from 'react-select';




import { BsSearch,
  BsViewList,BsInfoCircle,
  BsFillCursorFill, BsFillGeoAltFill, BsGeoAltFill, BsGeoAlt, BsFillGeoAlt, BsFillGeoFill, BsGeoFill, BsGeo
} from "react-icons/bs";


import {Dirsvg} from "./dir";

import Clock from "./montre";

import WindStat from "./Stat";
import Wave_line from "./wave";

import Station from "./Station";


import InstallBanner from "./installable";
import AlerteMessage from "./alerte";

import Contact from './contact'; // Assurez-vous que le chemin est correct

import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';

import DateTimeRangeFieldValue from './Component/DateTimeRangeField';
import ExportButton from './Component/ExportButton';

import Info from './Component/info';


// import { sum } from '@tensorflow/tfjs';

import moment from 'moment';
moment.locale(); // Active la localisation automatique en utilisant la région par défaut du navigateur



// import jsonData from './jsonData.json';



const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;





// const Linechart = (props) => {
//   let { CAV, setCAV, CAV_key, setCAV_key, M, setM, M_key, setM_key } = props;
const Linechart = ({ loggedInUser, pointSource, selectstation, spot, setSpot, Lat, setLat, Long, setLong,  CAV, setCAV, CAV_key, setCAV_key, M, setM, M_key, setM_key, fcast_hist, setfcast_hist, source_list }) => {
  const { t } = useTranslation();

  const M_types =["#0: "+t("info.M.#0")+" - 115 W",
                  "#1: "+t("info.M.#1")+" - 180 W",
                  "#2: "+t("info.M.#2")+" - 300 W",
                  "#3: "+t("info.M.#3")+" - 415 W",
                  "#4: "+t("info.M.#4")+" - 520 W"
                    // t("info.M.#0"),
                    // t("info.M.#1"),
                    // t("info.M.#2"),
                    // t("info.M.#3"),
                    // t("info.M.#4")
                  ];
  const M_label = [115, 180, 300, 415, 520];

  const CAV_types =[
    "0 - "+t("info.CAV.title.sc_a"),
    "0 - "+t("info.CAV.title.sc_b"),
    "0 - "+t("info.CAV.title.sc_c"),
    "2 - "+t("info.CAV.title.sc_d"),
    "4 - "+t("info.CAV.title.sc_e"),
    "3 - "+t("info.CAV.title.sc_f"),
    "10 - "+t("info.CAV.title.sc_g"),
    "12 - "+t("info.CAV.title.sc_h"),

    "1 - "+t("info.CAV.title.ac_a"),
    "1 - "+t("info.CAV.title.ac_b"),
    "1 - "+t("info.CAV.title.ac_c"),
    "3 - "+t("info.CAV.title.ac_d"),
    "5 - "+t("info.CAV.title.ac_e"),
    "4 - "+t("info.CAV.title.ac_f"),
    "11 - "+t("info.CAV.title.ac_g"),
  ]


  

  // const [CAV, setCAV] = useState(0);
  // const [CAV_key, setCAV_key] = useState(0);
  // const [M, setM] = useState(300);
  // const [M_key, setM_key] = useState(2);


  const [data_rssi, setdata_rssi] = useState({
    labels: [],
    datasets: [
      {
        label: 'signal',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        fill: true, // Remplir l'espace sous la courbe
      },
      {
        type: 'line',
        label: 'low signal',
        data: [],
        borderColor: 'rgba(125, 255, 255, 0.5)',
        backgroundColor: 'rgba(125, 255, 255, 0.5)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // borderWidth: 0, // Masquer la ligne
        // fill: 'origin',
        fill: 'start',        // fill: '-1',
        // fill: '1',
      },     
    ],
  });
  const [data_BatV, setdata_BatV] = useState({
    labels: [],
    datasets: [
      {
        label: 'BatV',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        fill: true, // Remplir l'espace sous la courbe
      },
      {
        type: 'line',
        label: 'low battery',
        data: [],
        borderColor: 'rgba(125, 255, 255, 0.5)',
        backgroundColor: 'rgba(125, 255, 255, 0.5)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // borderWidth: 0, // Masquer la ligne
        // fill: 'origin',
        fill: 'start',        // fill: '-1',
        // fill: '1',
      },     
    ],
  });





  const [data_WBGT, setdata_WBGT] = useState({
    labels: [],
    datasets: [
      {
        label: 'WBGT',
        data: [],
        borderColor: 'rgb(0, 0, 255)',
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'WBGT_f',
        data: [],
        borderColor: 'rgb(0, 135, 255)',
        borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
        backgroundColor: 'rgba(0, 135, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'WBT',
        data: [],
        borderColor: 'rgb(154, 102, 255)',
        backgroundColor: 'rgba(154, 102, 255, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'WBT_f',
        data: [],
        borderColor: 'rgb(174, 122, 255)',
        borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
        backgroundColor: 'rgba(174, 122, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'TG',
        data: [],
        borderColor: 'rgb(5, 5, 5)',
        backgroundColor: 'rgba(5, 5, 5, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'TG_f',
        data: [],
        borderColor: 'rgb(5, 5, 5)',
        borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
        backgroundColor: 'rgba(15, 15, 15, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'TA',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'TA_f',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
        backgroundColor: 'rgba(255, 120, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_WBGT_small, setdata_WBGT_small] = useState({
    labels: [],
    datasets: [
      {
        label: 'WBGT',
        data: [],
        borderColor: 'rgb(0, 0, 255)',
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        type: 'line',
        label: t("info.legend.safezone"),
        data: [],
        borderColor: 'rgba(0, 255, 0)',
        backgroundColor: 'rgba(0, 255, 0, 0.5)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        borderWidth: 0, // Masquer la ligne
        // fill: 'origin',
        fill: 'start',        // fill: '-1',
        // fill: '1',
      },
      {
        type: 'line',
        label: t("info.legend.yellowlimit"),
        data: [],
        borderColor: 'rgba(255, 225, 0)',
        backgroundColor: 'rgba(255, 225, 0, 0.55)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // fill: 'origin',
        fill: '+1',
        // fill: '1',
      },
      {
        type: 'line',
        label: t("info.legend.orangelimit"),
        data: [],
        borderColor: 'rgba(255, 125, 0)',
        backgroundColor: 'rgba(255, 125, 0, 0.55)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // fill: 'origin',
        fill: '+1',
        // fill: '1',
      },
      {
        type: 'line',
        label: t("info.legend.redlimit"),
        data: [],
        borderColor: 'rgba(255, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.55)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // fill: 'origin',
        fill: '+1',
        // fill: '1',
      },
      {
        type: 'line',
        label: t("info.legend.darklimit"),
        data: [],
        // data: [28, 28, 28, 28, 28, 28, 28, 28, 28, -CAV+ 56.7 - 11.5 * Math.log10(M), 28, 28, 28, 28, 28],
        borderColor: 'rgba(150, 0, 0)',
        backgroundColor: 'rgba(150, 0, 0, 0.5)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // fill: 0,
        fill: 'end',  //Boundary: 'start', 'end', 'origin'
      },
      {
        label: t("Chart.forecast"),
        data: [],
        borderColor: 'rgb(200, 200, 200)',
        backgroundColor: 'rgba(200, 200, 200, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      }
    ],
  });


  

  const [data_Wspd, setdata_Wspd] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.Wind"),
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: t("Map.button.Gust"),
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: t("Map.button.min_Wind"),
        data: [],
        borderColor: 'rgb(255, 216, 123)',
        backgroundColor: 'rgba(255, 230, 173)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_Wdir, setdata_Wdir] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.Wind"),
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: t("Map.button.Gust"),
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_WVHT, setdata_WVHT] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.wave_height"),
        data: [],
        borderColor: 'rgb(255, 158, 64)',
        backgroundColor: 'rgba(255, 158, 64, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_MWD, setdata_MWD] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.wave_dir"),
        data: [],
        borderColor: 'rgb(255, 158, 64)',
        backgroundColor: 'rgba(255, 158, 64, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_PD, setdata_PD] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.dom_wave_period"),
        data: [],
        borderColor: 'rgb(154, 102, 255)',
        backgroundColor: 'rgba(154, 102, 255, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: t("Map.button.avg_wave_period"),
        data: [],
        borderColor: 'rgb(76, 192, 192)',
        backgroundColor: 'rgba(76, 192, 192, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  
  const [data_Eau, setdata_Eau] = useState({
    labels: [],
    datasets: [
      {
        label: '5 cm',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: '65 cm',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: '125 cm',
        data: [],
        borderColor: 'rgb(255, 216, 123)',
        backgroundColor: 'rgba(255, 230, 173)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_Temp, setdata_Temp] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.Temperature"),
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        type: 'line',
        label: t("info.legend.coldzone"),
        data: [],
        borderColor: 'rgba(125, 255, 255, 0.5)',
        backgroundColor: 'rgba(125, 255, 255, 0.5)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // borderWidth: 0, // Masquer la ligne
        // fill: 'origin',
        fill: 'start',        // fill: '-1',
        // fill: '1',
      },
    ],
  });
  const [data_Hum, setdata_Hum] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.Hum"),
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        fill: true, // Remplir l'espace sous la courbe
      },
      {
        label: t("Chart.forecast"),
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        fill: true,
      },
    ],
  });

  const [data_Press, setdata_Press] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.Press"),
        data: [],
        borderColor: 'rgb(151, 151, 151)',
        backgroundColor: 'rgba(151, 151, 151, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_Pluie, setdata_Pluie] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.Precip"),
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_Averse, setdata_Averse] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.precip_intensity"),
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_Soleil, setdata_Soleil] = useState({
    labels: [],
    datasets: [
      {
        label: t("Map.button.Sunshine"),
        data: [],
        borderColor: 'rgb(255, 239, 2)',
        backgroundColor: 'rgba(255, 239, 2, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        fill: true,
      },
    ],
  });



  
  const [lim_risk, setLim_risk] = useState(M_label.map(value => -CAV+ 53.5 - 8.9 * Math.log10(value)));
  const [lim_extrem, setLim_extrem] = useState(M_label.map(value => -CAV+ 55.1 - 10.2 * Math.log10(value)));
  const [lim_high, setLim_high] = useState(M_label.map(value => -CAV+ 56.7 - 11.5 * Math.log10(value)));
  const [lim_low, setLim_low] = useState(M_label.map(value => -CAV+ 59.9 - 14.1 * Math.log10(value)));



  // let laColdLim = Array.from({ length: 1000 }).fill(null);
  // // Mettre à jour le premier et le dernier élément
  // laColdLim[0] = 5;
  // laColdLim[laColdLim.length - 1] = 5;
  // const [ColdLim, setColdLim] = useState( laColdLim ); //Array.from({ length: WBGT_received_at.length }, () => -CAV + 56.7 - 11.5 * Math.log10(M));
  
  

  let laRiskLim = Array.from({ length: 1000 }).fill(null);
  // Mettre à jour le premier et le dernier élément
  laRiskLim[0] = -CAV + 53.5 - 8.9 * Math.log10(parseInt(M));
  laRiskLim[laRiskLim.length - 1] = -CAV + 53.5 - 8.9 * Math.log10(parseInt(M));
  const [RiskLim, setRiskLim] = useState(laRiskLim);

  let laExtremLim = Array.from({ length: 1000 }).fill(null);
  // Mettre à jour le premier et le dernier élément
  laExtremLim[0] = -CAV + 55.1 - 10.2 * Math.log10(parseInt(M));
  laExtremLim[laExtremLim.length - 1] = -CAV + 55.1 - 10.2 * Math.log10(parseInt(M));
  const [ExtremLim, setExtremLim] = useState(laExtremLim);


  let laHighLim = Array.from({ length: 1000 }).fill(null);
  // Mettre à jour le premier et le dernier élément
  laHighLim[0] = -CAV + 56.7 - 11.5 * Math.log10(parseInt(M));
  laHighLim[laHighLim.length - 1] = -CAV + 56.7 - 11.5 * Math.log10(parseInt(M));
  const [HighLim, setHighLim] = useState(laHighLim);

  let laLowLim = Array.from({ length: 1000 }).fill(null);
  // Mettre à jour le premier et le dernier élément
  laLowLim[0] =  -CAV + 59.9 - 14.1 * Math.log10(parseInt(M));
  laLowLim[laLowLim.length - 1] =  -CAV + 59.9 - 14.1 * Math.log10(parseInt(M));
  const [LowLim, setLowLim] = useState( laLowLim );

  // let ColdArray = Array.from({ length: 1000 }).fill(null);
  // // Mettre à jour le premier et le dernier élément
  // ColdArray[0] = initialValue;
  // ColdArray[ColdArray.length - 1] = initialValue;
  // const [ColdLim, setColdLim] = useState(ColdArray);

  


  const [data_safe, setdata_safe] = useState({
    labels: M_label,
    datasets: [
      {
        type: 'bubble', // Type de dataset bubble
        label: t("info.legend.wbgt-M"),
        data: [
          { x: M, y: 27.96, r: 7 }, // Exemple de donnée bubble
          // { x: 300, y: 40, r: 30 },
          // { x: 400, y: 50, r: 40 },
          // Ajoutez les données bubble comme des objets avec les propriétés x, y et r (radius)
        ],
        backgroundColor: 'rgba(0, 0, 255, 1)', // Couleur de remplissage des bulles
        borderColor: 'rgba(0, 0, 255, 1)', // Couleur de la bordure des bulles
      },
      {
        type: 'line',
        label: t("info.legend.safezone"),
        data: lim_low,
        borderColor: 'rgba(0, 255, 0)',
        backgroundColor: 'rgba(0, 255, 0, 0.5)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        borderWidth: 0, // Masquer la ligne
        // fill: 'origin',
        fill: 'start',        // fill: '-1',
        // fill: '1',
      },
      {
        type: 'line',
        label: t("info.legend.yellowlimit"),
        data: lim_low,
        borderColor: 'rgba(255, 225, 0)',
        backgroundColor: 'rgba(255, 225, 0, 0.55)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // fill: 'origin',
        fill: '+1',
        // fill: '1',
      },
      {
        type: 'line',
        label: t("info.legend.orangelimit"),
        data: lim_high,
        // borderColor: '#FF9D40',
        // backgroundColor: 'rgba(255, 206, 163, 0.5)', // Couleur de remplissage avec opacité
        borderColor: 'rgba(255, 125, 0)',
        backgroundColor: 'rgba(255, 125, 0, 0.55)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // fill: 'origin',
        fill: '+1',
        // fill: '1',
      },
      {
        type: 'line',
        label: t("info.legend.redlimit"),
        data: lim_extrem,
        // borderColor: '#FF9D40',
        // backgroundColor: 'rgba(255, 206, 163, 0.5)', // Couleur de remplissage avec opacité
        borderColor: 'rgba(255, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.55)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // fill: 'origin',
        fill: '+1',
        // fill: '1',
      },
      {
        type: 'line',
        label: t("info.legend.darklimit"),
        data: lim_risk,
        // borderColor: '#FF6384',
        // backgroundColor: 'rgba(255, 179, 194, 0.5)', // Couleur de remplissage avec opacité
        borderColor: 'rgba(150, 0, 0)',
        backgroundColor: 'rgba(150, 0, 0, 0.5)', // Couleur de remplissage avec opacité
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        pointRadius: 0, // Masquer les points
        // fill: 0,
        fill: 'end',  //Boundary: 'start', 'end', 'origin'
      }
      
      
    ],
  });





  
  const options_rssi = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        // min: 0,
        max: 32,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'rssi: 0:	-113dBm or less | 1:	-111dBm | 2...30:	-109dBm...-53dBm | 31:	-51dBm or greater | 99:	Not known or not detectable',
      },


    },
  };

  const options_BatV = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 2,
        max: 4,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Batterie [V]',
      },

    },
  };






  const options_WBGT = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'WBGT = 0.7WBT + 0.2TG + 0.1TA',
      },

  
  
    //   beforeDraw: (chart) => {
    //     const ctx = chart.ctx;
    //     const chartArea = chart.chartArea;
    //     const dataset = chart.data.datasets[0];
    //     const limitHigh = 60; // Limite haute
    //     const limitLow = 40; // Limite basse
  
    //     // Dessiner une zone supérieure à la limite haute
    //     ctx.fillStyle = 'rgba(150, 0, 0, 0.1)';
    //     ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.top + (chartArea.bottom - chartArea.top) * (1 - (limitHigh - Math.min(...dataset.data)) / (Math.max(...dataset.data) - Math.min(...dataset.data))));
  
    //     // Dessiner une zone inférieure à la limite basse
    //     ctx.fillStyle = 'rgba(255, 255, 0, 0.1)';
    //     ctx.fillRect(chartArea.left, chartArea.top + (chartArea.bottom - chartArea.top) * (1 - (limitHigh - Math.min(...dataset.data)) / (Math.max(...dataset.data) - Math.min(...dataset.data))), chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
  
    //     // Dessiner une zone entre les limites haute et basse
    //     ctx.fillStyle = 'rgba(0, 255, 0, 0.1)';
    //     ctx.fillRect(chartArea.left, chartArea.top + (chartArea.bottom - chartArea.top) * (1 - (limitHigh - Math.min(...dataset.data)) / (Math.max(...dataset.data) - Math.min(...dataset.data))), chartArea.right - chartArea.left, (chartArea.bottom - chartArea.top) * ((limitHigh - limitLow) / (Math.max(...dataset.data) - Math.min(...dataset.data))));
    // }
  
  
    },
  };
  
  
  const options_WBGT_small = {
  
  
    // transitions: 'hide', //'active', 'hide', 'reset', 'resize', 'show'
    
    // animations: {
    //   tension: {
    //     duration: 1000,
    //     easing: 'linear',
    //     from: 1,
    //     to: 0,
    //     loop: true
    //   }
    // },
  
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        // min: 10,
        // max: 34,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'WBGT [°C]',
      },


      annotation: {
        annotations: {
          qatarLimCallout: {
            type: 'label',
            content: 32.1,
            // content: `Risk Limit: ${RiskLim[0].toFixed(1)}°C`,
            position: 'start', // 'start', 'center', 'end'
            xValue: 0, // Positionner sur la première étiquette
            yValue: 32.1,
            yAdjust: -30, // Décale l'annotation de x pixels vers le haut
            backgroundColor: 'rgba(50, 0, 0, 0.7)',
            font: {
              size: 16,
              weight: 'bold',
            },
            color: 'white',
          },
          riskLimCallout: {
            type: 'label',
            content: `${RiskLim[0].toFixed(1)}`,
            // content: `Risk Limit: ${RiskLim[0].toFixed(1)}°C`,
            position: 'start', // 'start', 'center', 'end'
            xValue: 0, // Positionner sur la première étiquette
            yValue: RiskLim[0],
            // yAdjust: -30, // Décale l'annotation de x pixels vers le haut
            backgroundColor: 'rgba(150, 0, 0, 0.7)',
            font: {
              size: 16,
              weight: 'bold',
            },
            color: 'white',
          },
          extremLimCallout: {
            type: 'label',
            content: `${ExtremLim[0].toFixed(1)}`,
            // content: `Extrem Limit: ${ExtremLim[0].toFixed(1)}°C`,
            position: 'start', // 'start', 'center', 'end'
            xValue: 0, // Positionner sur la première étiquette
            yValue: ExtremLim[0],
            // yAdjust: -30, // Décale l'annotation de x pixels vers le haut
            backgroundColor: 'rgba(255, 0, 0, 0.7)',
            font: {
              size: 16,
              weight: 'bold',
            },
            color: 'white',
          },
          highLimCallout: {
            type: 'label',
            content: `${HighLim[0].toFixed(1)}`,
            // content: `High Limit: ${HighLim[0].toFixed(1)}°C`,
            position: 'start', // 'start', 'center', 'end'
            xValue: 0, // Positionner sur la première étiquette
            yValue: HighLim[0],
            // yAdjust: -30, // Décale l'annotation de x pixels vers le haut
            backgroundColor: 'rgba(255, 125, 0, 0.7)',
            font: {
              size: 16,
              weight: 'bold',
            },
            color: 'white',
          },
          lowLimCallout: {
            type: 'label',
            content: `${LowLim[0].toFixed(1)}`,
            // content: `Low Limit: ${LowLim[0].toFixed(1)}°C`,
            position: 'start', // 'start', 'center', 'end'
            xValue: 0, // Positionner sur la première étiquette
            yValue: LowLim[0],
            // yAdjust: -30, // Décale l'annotation de x pixels vers le haut
            backgroundColor: 'rgba(255, 225, 0, 0.85)',
            font: {
              size: 16,
              weight: 'bold',
            },
            color: 'white',
          },

          // WBGTCallout: {
          //   type: 'label',
          //   content: `${WBGT[-1].toFixed(1)}`,
          //   position: 'end', // 'start', 'center', 'end'
          //   xValue: 0, // Positionner sur la première étiquette
          //   yValue: WBGT[-1],
          //   yAdjust: -30, // Décale l'annotation de x pixels vers le haut
          //   backgroundColor: 'rgba(0, 0, 255, 0.7)',
          //   font: {
          //     size: 16,
          //     weight: 'bold',
          //   },
          //   color: 'white',
          // },


        },
      },


  
    },
  };
  
  const options_WBGT_small_pdf = {
  
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 7 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'WBGT [°C]',
      },
  
    },
  };
  
  
  
  const options_SPD = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Vent [kts]',
      },
    },
  };
  
  const options_Dir = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0,
        max: 360,
        ticks: {
          // forces step size to be 50 units
          stepSize: 45
        },
        grid:{
          //color: ['grey'],
          display: 'true',
        }
      },
      y1: {
        type: 'category',
        labels: ['N','W','S', 'E', 'N'],
        position: 'right',
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Direction [deg°]',
      },
    },
  };
  
  const options_WVHT = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Hauteur [m]',
      },
    },
  };
  
  const options_PD = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Période [s]',
      },
    },
  };
  
  const options_MWD = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0,
        max: 360,
        ticks: {
          // forces step size to be 50 units
          stepSize: 45
        },
        grid:{
          //color: ['grey'],
          display: 'true',
        }
      },
      y1: {
        type: 'category',
        labels: ['N','W','S', 'E', 'N'],
        position: 'right',
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Direction [deg°]',
      },
    },
  };
  
  
  const options_EAU = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Température de l\'eau [°C] à une profondeur de:',
      },
    },
  };
  
  const options_Temp = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: t("info.legend.ta")+' [°C]',
      },
    },
  };
  
  const options_Temp_pdf = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 7 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: t("info.legend.ta")+' [°C]',
      },
    },
  };
  
  
  
  const options_Hum = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0,
        max: 100,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: t("info.legend.rh")+' [%]',
      },
    },
  };
  
  const options_Pluie = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          skipNull: true,
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0, // Commencer l'axe Y à zéro
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Précipitations [mm]',
      },
    },
  };
  
  const options_Averse = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          skipNull: true,
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0, // Commencer l'axe Y à zéro
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Intensité de précipitations [mm/h]',
      },
    },
  };
  
  const options_Sol = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ensoleillement [W/m²]',
      },
    },
  };
  const options_Press = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        ticks: {
          callback: function (value, index, values) {
            return value.toFixed(1); // Arrondir au dixième
          }
        }
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pression Atmosphérique [hPa]',
      },
    },
  };
  
  const options_safe = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
  
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        },
        // title: {
        //   display: true,
        //   text: t("Chart.Metabolisme")
        // }
      },
      x1: {
        type: 'category',
        labels: [t("info.M.#0"), t("info.M.#1"), t("info.M.#2"), t("info.M.#3"), t("info.M.#4")],
        position: 'bottom',
        title: {
          display: true,
          text: t("Chart.Metabolisme")+" [W]"
        },
        
      },
      y:{
        title: {
          display: true,
          text: 'WBGT [°C]'
        }
      },
      
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      // title: {
      //   display: true,
      //   text: 'Période [s]',
      // },
    },
  };

















  const [showSearch, setShowSearch] = useState(false); // état initial de 6 heures
  const [hours, setHours] = useState(6); // état initial de 6 heures


  // const [DateTimeRange, setDateTimeRange] = React.useState<DateRange<Dayjs>>(() => [
  //   dayjs('2022-04-17T15:30'),
  //   dayjs('2022-04-21T18:30'),
  // ]);
  // const [DateTimeRange, setDateTimeRange] = useState([dayjs('2022-04-17T15:30'), dayjs('2022-04-21T18:30')]);
  let initialStartDate = dayjs().subtract(6, 'hours');
  // let mtn = dayjs();
  // let initialEndDate = mtn;
  let initialEndDate = dayjs();
  const [DateTimeRange, setDateTimeRange] = useState([initialStartDate, initialEndDate]);
  
  // const [spot, setSpot] = useState(props.slectspot); //'1-meteowind-iot-pro'); // état initial de 6 heures
  // let pointSource= props.pointSource;
  
  // const [Lat, setLat]= useState(props.Lat);
  // const [Long, setLong]= useState(props.Long);
  // let LaSource = props.LaSource;
  // console.log('slectspot')
  // console.log(props.slectspot)



  const [WBGTreceivedtime, setWBGTreceivedtime] = useState([]);
  const [receivedtimeTempHum, setreceivedtimeTempHum] = useState([]);
  
  const [received_at, setreceived_at] = useState([]);
  const [received_time, setreceived_time] = useState([]);
  const [received_date, setreceived_date] = useState([]);

  const [rssi, setrssi] = useState([]);
  const [BatV, setBatV] = useState([]);

  const [WBGT, setWBGT] = useState([]);
  const [WBT, setWBT] = useState([]);
  const [TG, setTG] = useState([]);

// // // // // Forecast // // // // // 
  const [WBGT_f, setWBGT_f] = useState([]);
  const [WBT_f, setWBT_f] = useState([]);
  const [TG_f, setTG_f] = useState([]);
  const [TA_f, setTA_f] = useState([]);
  const [RH_f, setRH_f] = useState([]);
// // // // // // // // // // // // // 

  const [Wind_ave, setWind_ave] = useState([]);
  const [Wind_3sgust, setWind_3sgust] = useState([]);
  const [Wind_3smin, setWind_3smin] = useState([]);
  const [Wind_stdev, setWind_stdev] = useState([]);
  const [Dir_ave, setDir_ave] = useState([]);
  const [Dir_3sgust, setDir_3sgust] = useState([]);
  const [Dir_stdev, setDir_stdev] = useState([]);
  const [gust_3stime, setGust_3stime] = useState([]);
  const [Hum_SHT, setHum_SHT] = useState([]);
  const [TempC_DS, setTempC_DS] = useState([]);
  const [TempC_SHT, setTempC_SHT] = useState([]);
  const [WTMP, setWTMP] = useState([]);
  const [Temp_Red, setTemp_Red] = useState([]);
  const [Temp_White, setTemp_White] = useState([]);

  const [Type, setType] = useState([]);
  const [T_min, setT_min] = useState([]);
  const [T_max, setT_max] = useState([]);
  const [Pressure, setPressure] = useState([]);
  const [Irradiation, setIrradiation] = useState([]);
  const [Irr_max, setIrr_max] = useState([]);

  const [Rain, setRain] = useState([]);
  const [Rain_time, setRain_time] = useState([]);
  const [Frost_alert, setFrost_alert] = useState([]);
  const [Heater_on, setHeater_on] = useState([]);
  const [RainIntensityCorrection, setRainIntensityCorrection] = useState([]);
  
  const [Rain_rate, setRain_rate] = useState([]); // Ajoutez cet état pour stocker les valeurs de Rain_rate
  const [Rain_mm, setRain_mm] = useState([]); // Ajoutez cet état pour stocker les valeurs de Rain_rate
  
  const [totalRain, setTotalRain] = useState(0);


  const [DewP, setDewP] = useState([]);
  const [Visib, setVisib] = useState([]);
  const [vertVis, setvertVis] = useState([]);
  const [Snow, setSnow] = useState([]);


  const [WVHT, setWVHT] = useState([]); // - Hauteur significative des vagues (en mètres)
  const [DPD, setDPD] = useState([]); // - Période de vague dominante (en secondes)
  const [APD, setAPD] = useState([]); // - Période moyenne de vague (en secondes)
  const [MWD, setMWD] = useState([]); // - Direction moyenne des vagues (en degrés)

  const [PTDY, setPTDY] = useState([]); // - Changement de pression en 3 heures (en hPa)


  const [TIDE, setTIDE] = useState([]); // - Niveau de la marée
  const [hw, sethw ] = useState([]); // - marée haute
  const [hw_time, sethw_time] = useState([]); // - heure marée haute
  const [lw, setlw] = useState([]); // - marée basse
  const [lw_time, setlw_time] = useState([]); // - heure marée basse

  
  

  const fetchData = async () => {

    let StartDate = DateTimeRange[0];
    let EndDate = DateTimeRange[1];      
    let formattedStartDate = encodeURIComponent(StartDate.toISOString());
    let formattedEndDate = encodeURIComponent(EndDate.toISOString());

    let url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    // let url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;  // Déclarez la variable en dehors des blocs if




    let foundFeature = pointSource.data.features.find(feature => feature.properties.spot_id === spot);
    // console.log('foundFeature')
    // console.log(foundFeature)

    if(foundFeature){

      if(foundFeature.properties.source=='METAR'){
        url1 =`./jsondata/spots/weather.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;
      }

      if(foundFeature.properties.source=='NOAA'){
        url1 =`./jsondata/spots/NOAA.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;
      }

      if(foundFeature.properties.source=='SHIP'){
        url1 =`./jsondata/spots/SHIP.php?lat=${encodeURIComponent(Lat)}&long=${encodeURIComponent(Long)}&hours=${encodeURIComponent(hours)}`;
      }

    }
    // console.log('url')
    // console.log(url1)


    let received_at = [];
    let received_time = [];
    let received_date = [];

    let rssi = [];
    let BatV = [];


    let WBGT = [];
    let WBT = [];
    let TG = [];
    let Hum_SHT = []; // ajout de Hum_SHT
    let TempC_SHT = []; // ajout de TempC_SHT
    let TempC_DS = []; // ajout de TempC_DS$
    // // // // // Forecast // // // // // 
    let WBGT_f = [];
    let WBT_f = [];
    let TG_f = [];
    let TA_f = [];
    let RH_f = [];
    // // // // // // // // // // // // // 

    let Wind_ave = [];
    let Wind_3sgust = [];
    let Wind_3smin = [];
    let Dir_ave = [];
    let Dir_3sgust = [];
    let Dir_stdev = []; // ajout de Dir_stdev
    let gust_3stime = []; // ajout de gust_3stime
    
    let WTMP = []; // ajout de WTMP
    let Temp_Red = []; // ajout de Temp_Red
    let Temp_White = []; // ajout de Temp_Whitey
    
    let Type = [];
    let T_min = [];
    let T_max = [];
    let Pressure = []; // ajout de gust_3stime
    let Irradiation = [];
    let Irr_max = [];

    let Rain = [];
    let Rain_time = [];
    let Frost_alert = [];
    let Heater_on = [];
    let RainIntensityCorrection = [];

    let Rain_rate = [];    
    let Rain_mm = [];



    let DewP = [];
    let Visib = [];
    let vertVis = [];
    let Snow = [];


    let WVHT = []; // - Hauteur significative des vagues (en mètres)
    let DPD = []; // - Période de vague dominante (en secondes)
    let APD = []; // - Période moyenne de vague (en secondes)
    let MWD = []; // - Direction moyenne des vagues (en degrés)

    let PTDY = []; // - Changement de pression en 3 heures (en hPa)



    let TIDE = []; // - Niveau de la marée
    let hw  = []; // - marée haute
    let hw_time = []; // - heure marée haute
    let lw = []; // - marée basse
    let lw_time = []; // - heure marée basse




    // /*/
    await Promise.race([
      fetch(url1),
      // fetch(url2)
    ])
      .then((data) => {
        let res = data.json();
        return res;
      })
      .then((res) => {
        res.reverse();

        for (let val of res) {

            if(hours>24 || hours<=0){
              received_at.push(
                new Date(val.received_at+ 'Z').toLocaleTimeString([], {  //.toLocaleDateString
                  timeZone: userTimezone,
                  //year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  hour: 'numeric',
                  hour12: false,
                  minute: '2-digit',
                }));
            }
            else{
              received_at.push(
                new Date(val.received_at+ 'Z').toLocaleTimeString([], {
                  timeZone: userTimezone,
                  hour: '2-digit',
                  minute: '2-digit',
                  // second: '2-digit',
                }));
            }
            received_time.push(
              // new Date(val.received_at)
              new Date(val.received_at+ 'Z').toLocaleTimeString([], {  //.toLocaleDateString
                timeZone: userTimezone,
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                hour12: false,
                minute: '2-digit',
                second:'2-digit'
              })
            );
            received_date.push(
              new Date(val.received_at+ 'Z').toLocaleTimeString([], {  //.toLocaleDateString
                timeZone: userTimezone,
                //year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                hour12: false,
                minute: '2-digit',
            }));


            rssi.push(val.rssi);
            BatV.push(val.BatV);

            WBGT.push(val.WBGT);
            
            WBT.push(val.WBT);
            TG.push(val.TG);

            Hum_SHT.push(val.RH);
            TempC_SHT.push(val.TA);


            // // // // // Forecast // // // // // 
            WBGT_f.push(val.WBGT_f);
        
            WBT_f.push(val.WBT_f);
            TG_f.push(val.TG_f);

            RH_f.push(val.RH_f);
            TA_f.push(val.TA_f);
            // // // // // // // // // // // // // 

            Wind_ave.push(val.Wind_ave);
            Wind_3sgust.push(val.Wind_3sgust);
            Wind_3smin.push(val.Wind_3smin);
            Wind_stdev.push(val.Wind_stdev);
            Dir_ave.push(val.Dir_ave);
            Dir_3sgust.push(val.Dir_3sgust);
            Dir_stdev.push(val.Dir_stdev);
            gust_3stime.push(val.gust_3stime);

            
            // console.log('val.TempC_SHT')
            // console.log(val.TempC_SHT)

            WTMP.push(val.WTMP);
            Temp_Red.push(val.Temp_Red);
            Temp_White.push(val.Temp_White); 

            Type.push(val.Type);
            T_min.push(val.T_min);
            T_max.push(val.T_max);
            Irradiation.push(val.Irradiation);
            Irr_max.push(val.Irr_max);

            Pressure.push(val.QNH);
            PTDY.push(val.PTDY); // - Changement de pression en 3 heures (en hPa)

        
            Frost_alert.push(val.Frost_alert);
            Heater_on.push(val.Heater_on);

            Rain.push(val.Rain); // compteur de clics
            RainIntensityCorrection.push(val.RainIntensityCorrection); // correction en forte pluie

            Rain_time.push(val.Rain_time); // temps entre 2 clics
            Rain_rate.push(val.Rain_rate); // valeur calcullée a partir de Rain_time
            Rain_mm.push(val.Rain_mm); // valeur calcullée a partir de Rain_time


            DewP.push(val.DewP);
            Visib.push(val.Visib);
            vertVis.push(val.vertVis);
            Snow.push(val.Snow);

            // console.log('val.WVHT')
            // console.log(val.WVHT)
            WVHT.push(val.WVHT); // - Hauteur significative des vagues (en mètres)
            DPD.push(val.DPD); // - Période de vague dominante (en secondes)
            APD.push(val.APD); // - Période moyenne de vague (en secondes)
            MWD.push(val.MWD); // - Direction moyenne des vagues (en degrés)
        
        
        
        
            TIDE.push(val.TIDE); // - Niveau de la marée
            hw.push(val.hw); // - marée haute
            hw_time.push(val.hw_time); // - heure marée haute
            lw.push(val.lw); // - marée basse
            lw_time.push(val.lw_time); // - heure marée basse
            

          // }
        }

        setreceived_at(received_at)
        setreceived_time(received_time)
        setreceived_date(received_date)

        setrssi(rssi)
        setBatV(BatV)

        setWBGT(WBGT)
        setWBT(WBT)
        setTG(TG)

        
        // // // // // Forecast // // // // // 
        setWBGT_f(WBGT_f)
        setWBT_f(WBT_f)
        setTG_f(TG_f)
        setTA_f(TA_f)
        setRH_f(RH_f)
        // // // // // // // // // // // // // 


        setWind_ave(Wind_ave)
        setWind_3sgust(Wind_3sgust)
        setWind_3smin(Wind_3smin)
        setWind_stdev(Wind_stdev)
        setDir_ave(Dir_ave)
        setDir_3sgust(Dir_3sgust)
        setDir_stdev(Dir_stdev)
        setGust_3stime(gust_3stime)
        setTempC_DS(TempC_DS)
        setTempC_SHT(TempC_SHT)
        setWTMP(WTMP)
        setTemp_Red(Temp_Red)
        setTemp_White(Temp_White)


        setType(Type)
        setT_min(T_min)
        setT_max(T_max)
        setPressure(Pressure)
        setHum_SHT(Hum_SHT)
        setIrradiation(Irradiation)
        setIrr_max(Irr_max)
      
        setRain(Rain)
        setRain_time(Rain_time)
        setFrost_alert(Frost_alert)
        setHeater_on(Heater_on)
        setRainIntensityCorrection(RainIntensityCorrection)

        setRain_rate(Rain_rate)
        setRain_mm(Rain_mm)

        setDewP(DewP)
        setVisib(Visib)
        setvertVis(vertVis)
        setSnow(Snow)

        setWVHT(WVHT) // - Hauteur significative des vagues (en mètres)
        setDPD(DPD) // - Période de vague dominante (en secondes)
        setAPD(APD) // - Période moyenne de vague (en secondes)
        setMWD(MWD) // - Direction moyenne des vagues (en degrés)
    
        setPTDY(PTDY) // - Changement de pression en 3 heures (en hPa)
    
    
    
        setTIDE(TIDE) // - Niveau de la marée
        sethw(hw) // - marée haute
        sethw_time(hw_time) // - heure marée haute
        setlw(lw) // - marée basse
        setlw_time(lw_time) // - heure marée basse

        // Convertir les chaînes de caractères en valeurs numériques et filtrer les valeurs null
        let numericValues = Rain_mm.map(value => parseFloat(value)).filter(value => !isNaN(value) && value !== null && !isNaN(value));
        // Calculer la somme des valeurs numériques
        let sumRain = numericValues.reduce((acc, value) => acc + value, 0);
        let roundedSumRain = sumRain.toFixed(2);
        setTotalRain(roundedSumRain); // Vous pouvez ajouter cette fonction pour mettre à jour la quantité totale dans le state


         


        // let received_at_ff = [];
        let WBGT_ff = [];
        let WBT_ff = [];
        let TG_ff = [];
        let TA_ff = [];
        let RH_ff = [];


        if (!fcast_hist){
        // if (fcast_hist){
            let futur = 1;
          // for (let j = 0; j < WBGT.length; j++) {
          for (let j = WBGT.length-1 ; j >=0 ; j--) {
            if (typeof WBGT[j] !== "undefined" && WBGT[j] !== null) {
              futur = 0;
              // console.log('\n\nprésent', WBGT[j])
            }

            if (futur) {
            WBGT_ff.push(WBGT_f[j]);
            WBT_ff.push(WBT_f[j]);
            TG_ff.push(TG_f[j]);
            TA_ff.push(TA_f[j]);
            RH_ff.push(RH_f[j]);

            // console.log('\nfutur', WBGT_f[j])

            }
            else{
              WBGT_ff.push(null);
              WBT_ff.push(null);
              TG_ff.push(null);
              TA_ff.push(null);
              RH_ff.push(null);
              // console.log('\npas ftur')

            }

          }
          setWBGT_f(WBGT_ff.reverse());
          setWBT_f(WBT_ff.reverse());
          setTG_f(TG_ff.reverse());
          setTA_f(TA_ff.reverse());
          setRH_f(RH_ff.reverse());
        }

        // for (let j = 0; j < 100; j++) {
        //   setWBGT_f[-j](null);
        // }
        // if (!fcast_hist) {
        //     const WBGT_ff = WBGT_f.map((value, index) => {
        //         if (WBGT[index] !== null) {
        //             return null;
        //         } else {
        //             return value;
        //         }
        //     });
        //     setWBGT_f(WBGT_ff);
        // }



        setdata_rssi({
          labels: received_at, //WBGT_received_at,
          datasets: [
            {
              label: 'rssi',
              data: rssi,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              // fill: true, // Remplir l'espace sous la courbe
            },
            {
              type: 'line',
              label: 'excellent signal',
              data: Array(received_at.length).fill(20), // Créer un dataset de la taille de received_at avec la valeur 10              borderColor: 'rgba(125, 255, 255, 0.5)',
              borderColor: 'rgb(0, 255, 0, 0.5)',
              backgroundColor: 'rgba(0, 255, 0, 0.5)', // Couleur de remplissage avec opacité
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              pointRadius: 0, // Masquer les points
              // borderWidth: 0, // Masquer la ligne
              // fill: 'origin',
              fill: 'end',
              // fill: '-1',
              // fill: '1',
            },
            {
              type: 'line',
              label: 'good signal',
              data: Array(received_at.length).fill(20), // Créer un dataset de la taille de received_at avec la valeur 10              borderColor: 'rgba(125, 255, 255, 0.5)',
              borderColor: 'rgb(255, 225, 0, 0.5)',
              backgroundColor: 'rgba(255, 225, 0, 0.5)', // Couleur de remplissage avec opacité
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              pointRadius: 0, // Masquer les points
              // borderWidth: 0, // Masquer la ligne
              // fill: 'origin',
              // fill: {value: -50},
              fill: {value: 12},
            },
            {
              type: 'line',
              label: 'low signal',
              data: Array(received_at.length).fill(12), // Créer un dataset de la taille de received_at avec la valeur 10              borderColor: 'rgba(125, 255, 255, 0.5)',
              borderColor: 'rgb(255, 150, 0, 0.5)',
              backgroundColor: 'rgba(255, 150, 0, 0.5)', // Couleur de remplissage avec opacité
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              pointRadius: 0, // Masquer les points
              // borderWidth: 0, // Masquer la ligne
              // fill: 'origin',
              // fill: {value: -50},
              // fill: {value: 15},
              // fill: '1',
              fill: 'start',
            },

          ],
        });

        setdata_BatV({
          labels: received_at, //WBGT_received_at,
          datasets: [
            {
              label: 'BatV',
              data: BatV,
              borderColor: 'rgb(0, 0, 255)',
              backgroundColor: 'rgba(0, 0, 255, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              type: 'line',
              label: 'good battery',
              data: Array(received_at.length).fill(2.7), // Créer un dataset de la taille de received_at avec la valeur 10              borderColor: 'rgba(125, 255, 255, 0.5)',
              borderColor: 'rgb(0, 255, 0, 0.5)',
              backgroundColor: 'rgba(0, 255, 0, 0.5)', // Couleur de remplissage avec opacité
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              pointRadius: 0, // Masquer les points
              // borderWidth: 0, // Masquer la ligne
              // fill: 'origin',
              // fill: '-1',
              fill: 'end',
            },
            {
              type: 'line',
              label: 'low battery',
              data: Array(received_at.length).fill(2.7), // Créer un dataset de la taille de received_at avec la valeur 10              borderColor: 'rgba(125, 255, 255, 0.5)',
              borderColor: 'rgb(255, 0, 0, 0.5)',
              backgroundColor: 'rgba(255, 0, 0, 0.5)', // Couleur de remplissage avec opacité
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              pointRadius: 0, // Masquer les points
              // borderWidth: 0, // Masquer la ligne
              // fill: 'origin',
              // fill: {value: -50},
              // fill: {value: 15},
              // fill: '1',
              fill: 'start',
            },
          ],
        });



        let WBGT_received_at = [];
        let WBGT_received_time = [];
        let plot_WBGT = [];
        let plot_WBT = [];
        let plot_TG = [];
        let plot_TA = [];
        for (let i = 0; i < WBGT.length; i++) {
          if (WBGT[i] !== null) {
            WBGT_received_at.push(received_at[i]);
            WBGT_received_time.push(received_time[i]);
            plot_WBGT.push(WBGT[i]);
            plot_WBT.push(WBT[i]);
            plot_TG.push(TG[i]);
            plot_TA.push(TempC_SHT[i]);
          }
        }
        setWBGTreceivedtime(WBGT_received_time);


        setdata_WBGT({
          labels: received_at, //WBGT_received_at,
          datasets: [
            {
              label: 'WBGT',
              data: WBGT,
              borderColor: 'rgb(0, 0, 255)',
              backgroundColor: 'rgba(0, 0, 255, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'WBGT_f',
              data: WBGT_f,
              borderColor: 'rgb(0, 135, 255)',
              borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
              backgroundColor: 'rgba(0, 135, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'WBT',
              data: WBT,
              borderColor: 'rgb(154, 102, 255)',
              backgroundColor: 'rgba(154, 102, 255, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'WBT_f',
              data: WBT_f,
              borderColor: 'rgb(174, 122, 255)',
              borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
              backgroundColor: 'rgba(174, 122, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'TG',
              data: TG,
              borderColor: 'rgb(5, 5, 5)',
              backgroundColor: 'rgba(5, 5, 5, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'TG_f',
              data: TG_f,
              borderColor: 'rgb(5, 5, 5)',
              borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
              backgroundColor: 'rgba(15, 15, 15, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'TA',
              data: TempC_SHT,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'TA_f',
              data: TA_f,
              borderColor: 'rgb(255, 99, 132)',
              borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
              backgroundColor: 'rgba(255, 120, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });


        



        let laRiskLim = Array.from({ length: WBGT.length }).fill(null);
        // Mettre à jour le premier et le dernier élément
        laRiskLim[0] = -CAV + 53.5 - 8.9 * Math.log10(parseInt(M));
        laRiskLim[laRiskLim.length - 1] = -CAV + 53.5 - 8.9 * Math.log10(parseInt(M));
        setRiskLim(laRiskLim);

        let laExtremLim = Array.from({ length: WBGT.length }).fill(null);
        // Mettre à jour le premier et le dernier élément
        laExtremLim[0] = -CAV + 55.1 - 10.2 * Math.log10(parseInt(M));
        laExtremLim[laExtremLim.length - 1] = -CAV + 55.1 - 10.2 * Math.log10(parseInt(M));
        setExtremLim(laExtremLim);

        let laHighLim = Array.from({ length: WBGT.length }).fill(null);
        // Mettre à jour le premier et le dernier élément
        laHighLim[0] = -CAV + 56.7 - 11.5 * Math.log10(parseInt(M));
        laHighLim[laHighLim.length - 1] = -CAV + 56.7 - 11.5 * Math.log10(parseInt(M));
        setHighLim(laHighLim);

        let laLowLim = Array.from({ length: WBGT.length }).fill(null);
        // Mettre à jour le premier et le dernier élément
        laLowLim[0] = -CAV + 59.9 - 14.1 * Math.log10(parseInt(M));
        laLowLim[laLowLim.length - 1] =  -CAV + 59.9 - 14.1 * Math.log10(parseInt(M));
        setLowLim(laLowLim);
        




        
        





        // Filtrer les valeurs null de Wind_ave et leurs correspondances dans received_at
        let received_atWind = [];
        let plot_WindAve = [];
        let plot_Wind_3sgust = [];
        let plot_Wind_3smin = [];
        let plot_Dir_ave = [];
        let plot_Dir_3sgust = [];
        for (let i = 0; i < Wind_ave.length; i++) {
          if (Wind_ave[i] !== null) {
            received_atWind.push(received_at[i]);
            plot_WindAve.push(Wind_ave[i]);
            plot_Wind_3sgust.push(Wind_3sgust[i]);
            plot_Wind_3smin.push(Wind_3smin[i]);
            plot_Dir_ave.push(Dir_ave[i]);
            plot_Dir_3sgust.push(Dir_3sgust[i]);
          }
        }
        setdata_Wspd({
          labels: received_atWind,
          datasets: [
            {
              label: t("Map.button.Wind"),
              data: plot_WindAve,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: t("Map.button.Gust"),
              data: plot_Wind_3sgust,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: t("Map.button.min_Wind"),
              data: plot_Wind_3smin,
              borderColor: 'rgb(255, 216, 123)',
              backgroundColor: 'rgba(255, 230, 173)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });
        setdata_Wdir({
          labels: received_atWind,
          datasets: [
            {
              label: t("Map.button.Wind"),
              data: plot_Dir_ave,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: t("Map.button.Gust"),
              data: plot_Dir_3sgust,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });


        let WVHT_received_at = [];
        let plot_WVHT = [];
        for (let i = 0; i < WVHT.length; i++) {
          if (WVHT[i] !== null) {
            WVHT_received_at.push(received_at[i]);
            plot_WVHT.push(WVHT[i]);
          }
        }
        setdata_WVHT({
          labels: WVHT_received_at,
          datasets: [
            {
              label: t("Map.button.wave_height"),
              data: plot_WVHT,
              borderColor: 'rgb(255, 158, 64)',
              backgroundColor: 'rgba(255, 158, 64, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });



        let MWD_received_at = [];
        let plot_MWD = [];
        for (let i = 0; i < MWD.length; i++) {
          if (MWD[i] !== null) {
            MWD_received_at.push(received_at[i]);
            plot_MWD.push(MWD[i]);
          }
        }
        setdata_MWD({
          labels: MWD_received_at,
          datasets: [
            {
              label: t("Map.button.wave_dir"),
              data: plot_MWD,
              borderColor: 'rgb(255, 158, 64)',
              backgroundColor: 'rgba(255, 158, 64, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });
      
        let PD_received_at = [];
        let plot_PD = [];
        if (DPD.some(value => value > 0)){
          for (let i = 0; i < DPD.length; i++) {
            if (DPD[i] !== null) {
              PD_received_at.push(received_at[i]);
              plot_PD.push(DPD[i]);
            }
          }
        }
        else if(APD.some(value => value > 0)){
          for (let i = 0; i < APD.length; i++) {
            if (APD[i] !== null) {
              PD_received_at.push(received_at[i]);
              plot_PD.push(APD[i]);
            }
          }
        }
        
        
        setdata_PD({
          labels: PD_received_at,
          datasets: [
            {
              label: t("Map.button.dom_wave_period"),
              data: plot_PD,
              borderColor: 'rgb(154, 102, 255)',
              backgroundColor: 'rgba(154, 102, 255, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: t("Map.button.avg_wave_period"),
              data: plot_PD,
              borderColor: 'rgb(76, 192, 192)',
              backgroundColor: 'rgba(76, 192, 192, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });


        


        // Filtrer les valeurs null de Wind_ave et leurs correspondances dans received_at
        let received_atEau = [];
        let plot_Temp_White = [];
        let plot_WTMP = [];
        let plot_Temp_Red = [];
        for (let i = 0; i < Temp_White.length; i++) {
          if (Temp_White[i] !== null) {
            received_atEau.push(received_at[i]);
            plot_Temp_White.push(Temp_White[i]);
            plot_WTMP.push(WTMP[i]);
            plot_Temp_Red.push(Temp_Red[i]);
          }
        }
        setdata_Eau({
          labels: received_atEau,
          datasets: [
            {
              label: '5 cm',
              data: plot_Temp_White,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: '65 cm',
              data: plot_WTMP,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: '125 cm',
              data: plot_Temp_Red,
              borderColor: 'rgb(255, 216, 123)',
              backgroundColor: 'rgba(255, 230, 173)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });


        // Filtrer les valeurs null de TempC_SHT et leurs correspondances dans received_at
        let received_atTempHum = [];
        let received_timeTempHum = [];
        let plot_TempC_SHT = [];
        let plot_Hum_SHT = [];
        for (let i = 0; i < TempC_SHT.length; i++) {
          if (TempC_SHT[i] !== null) {
            received_atTempHum.push(received_at[i]);
            received_timeTempHum.push(received_time[i]);
            plot_TempC_SHT.push(TempC_SHT[i]);
            plot_Hum_SHT.push(Hum_SHT[i]);
          }
        }
        setreceivedtimeTempHum(received_timeTempHum);

        // // let laColdLim =  Array.from({ length: plot_TempC_SHT.length }).fill(5) ;
        // let laColdLim = Array.from({ length: plot_TempC_SHT.length }).fill(null);
        // // Mettre à jour le premier et le dernier élément
        // laColdLim[0] = 5;
        // laColdLim[laColdLim.length - 1] = 5;
        // setColdLim( laColdLim );
        
        setdata_Temp({
          labels: received_at, //received_atTempHum,
          datasets: [
            {
              label: t("Map.button.Temperature"),
              data: TempC_SHT, //plot_TempC_SHT,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: t("Chart.forecast"),
              data: TA_f,
              borderColor: 'rgb(255, 99, 132)',
              borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              type: 'line',
              label: t("info.legend.coldzone"),
              data: received_at.map((_, index) => index === 0 || index === received_at.length - 1 ? 5 : null), // Création directe du tableau
              borderColor: 'rgba(125, 255, 255, 0.5)',
              backgroundColor: 'rgba(125, 255, 255, 0.5)', // Couleur de remplissage avec opacité
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              pointRadius: 0, // Masquer les points
              // borderWidth: 0, // Masquer la ligne
              // fill: 'origin',
              fill: 'start',        // fill: '-1',
              // fill: '1',
            },
          ],
        });




        setdata_Hum({
          labels: received_at, //received_atTempHum,
          datasets: [
            {
              label: t("Map.button.Hum"),
              data: Hum_SHT, //plot_Hum_SHT,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              fill: true,
            },
            {
              label: t("Chart.forecast"),
              data: RH_f,
              borderColor: 'rgb(53, 162, 235)',
              borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              fill: true,
            },
          ],
        });


        // Filtrer les valeurs null de TempC_SHT et leurs correspondances dans received_at
        let received_atPressure = [];
        let plot_Pressure = [];
        for (let i = 0; i < Pressure.length; i++) {
          if (Pressure[i] !== null) {
            received_atPressure.push(received_at[i]);
            plot_Pressure.push(Pressure[i]);
          }
        }
        // let received_atPressure = received_at.filter((_, i) => Pressure[i] !== null);
        // let plot_Pressure = Pressure.filter(p => p !== null);

        setdata_Press({
          labels: received_atPressure,
          datasets: [
            {
              label: t("Map.button.Press"),
              data: plot_Pressure,
              borderColor: 'rgb(151, 151, 151)',
              backgroundColor: 'rgba(151, 151, 151, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });
        // setdata_Pluie({
        //   labels: received_at,
        //   datasets: [
        //     {
        //       label: 'Rain mm/h',
        //       data: Rain_rate,
        //       type: 'line', // Définir le type comme une ligne
        //       fill: false, // Ne pas remplir l'aire sous la ligne
        //       yAxisID: 'y2',
        //       borderColor: 'rgb(53, 162, 235)',
        //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //       // pointRadius: 5, // Augmenter la taille des points
        //       tension: 0.3,
        //       borderWidth: 1,
        //       spanGaps: true // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //     },
        //     {
        //       label: 'Rain mm',
        //       data: Rain_mm,
        //       type: 'bar', // Définir le type comme une ligne
        //       borderColor: 'rgb(53, 162, 235)',
        //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //       yAxisID: 'y',
        //       borderWidth: 1.5,
        //       borderRadius: 10,
        //       // barThickness: 5,
        //       barPercentage: 1.7,
        //       categoryPercentage: 1.7,
        //       // borderSkipped: true,

        //       tension: 0.3,
        //       spanGaps: true // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //     },
        //   ],
        // });

        // Filtrer les valeurs null de TempC_SHT et leurs correspondances dans received_at
        let received_atRain = [];
        let plot_Rain_mm = [];
        let plot_Rain_rate = [];
        for (let i = 0; i < Rain_mm.length; i++) {
          if (Rain[i] !== null) {
            received_atRain.push(received_at[i]);
            plot_Rain_mm.push(Rain_mm[i]);
            plot_Rain_rate.push(Rain_rate[i]);
          }
        }
        setdata_Pluie({
          labels: received_atRain,
          datasets: [
            {
              label: t("Map.button.Precip"),
              data: plot_Rain_mm,
              type: 'bar', // Définir le type comme une ligne
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              yAxisID: 'y',
              borderWidth: 1.5,
              borderRadius: 10,
              // barThickness: 5,
              barPercentage: 0.9, //bar est dans category
              categoryPercentage: 1,
              // borderSkipped: true,
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });
        setdata_Averse({
          labels: received_atRain,
          datasets: [
            {
              label: t("Map.button.precip_intensity"),
              data: plot_Rain_rate,
              type: 'line', // Définir le type comme une ligne
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });


        // Filtrer les valeurs null de TempC_SHT et leurs correspondances dans received_at
        let received_atIrr = [];
        let plot_Irr = [];
        for (let i = 0; i < Irradiation.length; i++) {
          if (Irradiation[i] !== null) {
            received_atIrr.push(received_at[i]);
            plot_Irr.push(Irradiation[i]);
          }
        }
        setdata_Soleil({
          labels: received_atIrr,
          datasets: [
            {
              label: t("Map.button.Sunshine"),
              data: plot_Irr,
              borderColor: 'rgb(255, 239, 2)',
              backgroundColor: 'rgba(255, 239, 2, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              fill: true,
            },
          ],
        });
        
        








        

      })
      .catch((e) => {

        // setHighLim(  Array.from({ length: 15 }).fill(-CAV + 56.7 - 11.5 * Math.log10(parseInt(M))) ); //Array.from({ length: WBGT_received_at.length }, () => -CAV + 56.7 - 11.5 * Math.log10(M));
        // setLowLim(  Array.from({ length: 15 }).fill(-CAV + 59.9 - 14.1 * Math.log10(parseInt(M))) ); //Array.from({ length: WBGT_received_at.length }, () => -CAV + 56.7 - 11.5 * Math.log10(M));
        // setColdLim(  Array.from({ length: WBGT.length }).fill(5) );
        // setWBGT([23, 23.2, 23.3, 24, 24.5, 24.6, 25, 25.1, 25.2, 26, 26.5, 26.6, 26.9, 26.7, 26.3])
        // setdata_WBGT_small({
        //   // labels: ['13:00', '13:10', '13:20', '13:30', '13:40', '13:50', '14:00', '14:10', '14:20', '14:30', '14:40', '14:50', '15:00', '15:10', '15:20'], // mode hors ligne
        //   labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        //   // labels: WBGT_received_at,
        //   datasets: [
        //     {
        //       label: 'WBGT',
        //       data: [23, 23.2, 23.3, 24, 24.5, 24.6, 25, 25.1, 25.2, 26, 26.5, 26.6, 26.9, 26.7, 26.3], // mode hors ligne
        //       // borderColor: ['green','blue','yellow'],
        //       // backgroundColor: ['red','blue','yellow'],
        //       // data: plot_WBGT,
        //       borderColor: 'rgb(0, 0, 255, 1)',
        //       backgroundColor: 'rgba(0, 0, 255, 1)', // 'rgba(255, 205, 86)',
        //       tension: 0.3,
        //       spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //     },
        //     {
        //       type: 'line',
        //       label: t("info.legend.darklimit"),
        //       data: HighLim,
        //       // data: [28, 28, 28, 28, 28, 28, 28, 28, 28, -CAV+ 56.7 - 11.5 * Math.log10(M), 28, 28, 28, 28, 28],
        //       borderColor: 'rgba(150, 0, 0)',
        //       backgroundColor: 'rgba(150, 0, 0, 0.5)', // Couleur de remplissage avec opacité
        //       tension: 0.3,
        //       spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //       pointRadius: 0, // Masquer les points
        //       // fill: 0,
        //       fill: 'end',  //Boundary: 'start', 'end', 'origin'
        //     },
        //     {
        //       type: 'line',
        //       label: t("info.legend.orangelimit"),
        //       data: LowLim,
        //       borderColor: 'rgba(255, 125, 0)',
        //       backgroundColor: 'rgba(255, 125, 0, 0.55)', // Couleur de remplissage avec opacité
        //       tension: 0.3,
        //       spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //       pointRadius: 0, // Masquer les points
        //       // fill: 'origin',
        //       fill: '-1',
        //       // fill: '1',
        //     },
        //     {
        //       type: 'line',
        //       label: t("info.legend.safezone"),
        //       data: LowLim,
        //       borderColor: 'rgba(0, 255, 0)',
        //       backgroundColor: 'rgba(0, 255, 0, 0.5)', // Couleur de remplissage avec opacité
        //       tension: 0.3,
        //       spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //       pointRadius: 0, // Masquer les points
        //       borderWidth: 0, // Masquer la ligne
        //       // fill: 'origin',
        //       fill: {value: -50},        // fill: '-1',
        //       // fill: '1',
        //     },
        //   ],
        // });
        console.log('error', e);
      });
  };
  
  useEffect(() => {
    const interval = setInterval(() => { //refreash toutes les x secondes

      if(hours > 0){ // update si StartDate et EndDate ont pas été touché
        let StartDate = dayjs().subtract(hours, 'hours');
        let EndDate = dayjs();
        setDateTimeRange([StartDate, EndDate]);
      }
      else if (hours == 0) { // update le endDate si le endDate a pas été touché
        let StartDate = DateTimeRange[0];
        let EndDate = dayjs();
        setDateTimeRange([StartDate, EndDate]);
      }
      // console.log(`CAV: ${CAV}`)
      fetchData(); //refreash toutes les x secondes
      // console.log(`CAV: ${CAV}`)
      
    }, 30000); //refreash toutes les x secondes
    fetchData(); // refetch les données lorsque hours est mis à jour

    return () => clearInterval(interval); // refetch les données lorsque hours est mis à jour
  }, [CAV, M, DateTimeRange, hours, spot, fcast_hist]); // refetch les données lorsque hours est mis à jour


  useEffect(() => {
    let newRadius=0;
    let newLineWidth=1.5;

    if(hours == 2){
      newRadius=3;
      newLineWidth=3;
    }
    else if(hours == 3){
      newRadius=2;
      newLineWidth=3;
    }
    else if(hours == 6){
      newRadius=2;
      newLineWidth=2.5;
    }
    else if(hours == 12){
      newRadius=1.5;
      newLineWidth=2;
    }
    else if(hours >= 24){
      newRadius=1;
      newLineWidth=1.5;
    }
    
    // Fonction réutilisable pour mettre à jour le rayon des points
    const updatePointRadiusInState = (data, newRadius, newLineWidth, setDataFunction) => {
      setDataFunction((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => ({
          ...dataset,
          pointRadius: newRadius,
          borderWidth: newLineWidth,
        })),
      }));
    };

    // Utilisation de la fonction pour mettre à jour le rayon des points dans différentes variables de state
    updatePointRadiusInState(data_WBGT_small, newRadius, newLineWidth, setdata_WBGT_small);
    updatePointRadiusInState(data_WBGT, newRadius, newLineWidth, setdata_WBGT);
    updatePointRadiusInState(data_Hum, newRadius, newLineWidth, setdata_Hum);
    updatePointRadiusInState(data_Temp, newRadius, newLineWidth, setdata_Temp);

    updatePointRadiusInState(data_Wdir, newRadius, newLineWidth, setdata_Wdir);
    updatePointRadiusInState(data_Wspd, newRadius, newLineWidth, setdata_Wspd);

    updatePointRadiusInState(data_MWD, newRadius, newLineWidth, setdata_MWD);
    updatePointRadiusInState(data_WVHT, newRadius, newLineWidth, setdata_WVHT);
    updatePointRadiusInState(data_PD, newRadius, newLineWidth, setdata_PD);

    updatePointRadiusInState(data_Eau, newRadius, newLineWidth, setdata_Eau);

    updatePointRadiusInState(data_Pluie, newRadius, newLineWidth, setdata_Pluie);
    updatePointRadiusInState(data_Averse, newRadius, newLineWidth, setdata_Averse);
    updatePointRadiusInState(data_Soleil, newRadius, newLineWidth, setdata_Soleil);
    updatePointRadiusInState(data_Press, newRadius, newLineWidth, setdata_Press);

  }, [DateTimeRange, hours, spot]); // refetch les données lorsque hours est mis à jour



  useEffect(() => {

    let WBGT_last=WBGT.slice().reverse().find(value => value !== null && !isNaN(value));

    setdata_safe({
      labels: M_label,
      datasets: [
        {
          type: 'bubble', // Type de dataset bubble
          label: t("info.legend.wbgt-M"),
          data: [
            { x: M, y: WBGT_last, r: 7 }, // Exemple de donnée bubble
            // { x: 300, y: 40, r: 30 },
            // { x: 400, y: 50, r: 40 },
            // Ajoutez les données bubble comme des objets avec les propriétés x, y et r (radius)
          ],
          backgroundColor: 'rgba(0, 0, 255, 1)', // Couleur de remplissage des bulles
          borderColor: 'rgba(0, 0, 255, 1)', // Couleur de la bordure des bulles
        },
        {
          type: 'line',
          label: t("info.legend.safezone"),
          data: lim_low,
          borderColor: 'rgba(0, 255, 0)',
          backgroundColor: 'rgba(0, 255, 0, 0.5)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          borderWidth: 0, // Masquer la ligne
          // fill: 'origin',
          fill: 'start',        // fill: '-1',
          // fill: '1',
        },
        {
          type: 'line',
          label: t("info.legend.yellowlimit"),
          data: lim_low,
          borderColor: 'rgba(255, 225, 0)',
          backgroundColor: 'rgba(255, 225, 0, 0.55)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 'origin',
          fill: '+1',
          // fill: '1',
        },
        {
          type: 'line',
          label: t("info.legend.orangelimit"),
          data: lim_high,
          // borderColor: '#FF9D40',
          // backgroundColor: 'rgba(255, 206, 163, 0.5)', // Couleur de remplissage avec opacité
          borderColor: 'rgba(255, 125, 0)',
          backgroundColor: 'rgba(255, 125, 0, 0.55)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 'origin',
          fill: '+1',
          // fill: '1',
        },
        {
          type: 'line',
          label: t("info.legend.redlimit"),
          data: lim_extrem,
          // borderColor: '#FF9D40',
          // backgroundColor: 'rgba(255, 206, 163, 0.5)', // Couleur de remplissage avec opacité
          borderColor: 'rgba(255, 0, 0)',
          backgroundColor: 'rgba(255, 0, 0, 0.55)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 'origin',
          fill: '+1',
          // fill: '1',
        },
        {
          type: 'line',
          label: t("info.legend.darklimit"),
          data: lim_risk,
          // borderColor: '#FF6384',
          // backgroundColor: 'rgba(255, 179, 194, 0.5)', // Couleur de remplissage avec opacité
          borderColor: 'rgba(150, 0, 0)',
          backgroundColor: 'rgba(150, 0, 0, 0.5)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 0,
          fill: 'end',  //Boundary: 'start', 'end', 'origin'
        }
      
      ],
    });
    
  }, [WBGT, M, lim_extrem, lim_high, lim_low]); // refetch les données lorsque hours est mis à jour
  // }, [WBGT, CAV, M]); // refetch les données lorsque hours est mis à jour




  useEffect(() => {
    

    setdata_WBGT_small({
      // labels: ['13:00', '13:10', '13:20', '13:30', '13:40', '13:50', '14:00', '14:10', '14:20', '14:30', '14:40', '14:50', '15:00', '15:10', '15:20'], // mode hors ligne
      // labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      labels: received_at, //Array.from({ length: WBGT.length }), //WBGT_received_at,
      datasets: [
        {
          label: 'WBGT',
          // data: [23, 23.2, 23.3, 24, 24.5, 24.6, 25, 25.1, 25.2, 26, 26.5, 26.6, 26.9, 26.7, 26.3], // mode hors ligne
          // borderColor: ['green','blue','yellow'],
          // backgroundColor: ['red','blue','yellow'],
          data: WBGT, //[], //plot_WBGT,
          borderColor: 'rgb(0, 0, 255)',
          backgroundColor: 'rgba(0, 0, 255, 0.5)', // 'rgba(255, 205, 86)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          type: 'line',
          label: t("info.legend.safezone"),
          data: LowLim,
          borderColor: 'rgba(0, 255, 0)',
          backgroundColor: 'rgba(0, 255, 0, 0.5)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          borderWidth: 0, // Masquer la ligne
          // fill: 'origin',
          fill: 'start',        // fill: '-1',
          // fill: '1',
        },
        {
          type: 'line',
          label: t("info.legend.yellowlimit"),
          data: LowLim,
          borderColor: 'rgba(255, 225, 0)',
          backgroundColor: 'rgba(255, 225, 0, 0.55)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 'origin',
          fill: '+1',
          // fill: '1',
        },
        {
          type: 'line',
          label: t("info.legend.orangelimit"),
          data: HighLim,
          borderColor: 'rgba(255, 125, 0)',
          backgroundColor: 'rgba(255, 125, 0, 0.55)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 'origin',
          fill: '+1',
          // fill: '1',
        },
        {
          type: 'line',
          label: t("info.legend.redlimit"),
          data: ExtremLim,
          borderColor: 'rgba(255, 0, 0)',
          backgroundColor: 'rgba(255, 0, 0, 0.55)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 'origin',
          fill: '+1',
          // fill: '1',
        },
        {
          type: 'line',
          label: t("info.legend.darklimit"),
          data: RiskLim,
          // data: [28, 28, 28, 28, 28, 28, 28, 28, 28, -CAV+ 56.7 - 11.5 * Math.log10(M), 28, 28, 28, 28, 28],
          borderColor: 'rgba(150, 0, 0)',
          backgroundColor: 'rgba(150, 0, 0, 0.5)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 0,
          fill: '+1',  //Boundary: 'start', 'end', 'origin'
          // fill: 'end',  //Boundary: 'start', 'end', 'origin'
        },
        {
          type: 'line',
          label: t("info.legend.qatarlimit"),
          data: received_at.map((_, index) => index === 0 || index === received_at.length - 1 ? 32.1 : null), // Création directe du tableau
          borderColor: 'rgba(50, 0, 0)',
          backgroundColor: 'rgba(50, 0, 0, 0.5)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // fill: 0,
          fill: 'end',  //Boundary: 'start', 'end', 'origin'
        },
        {
          label: t("Chart.forecast"),
          data: WBGT_f,
          borderColor: 'rgb(0, 0, 255)',
          borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
          backgroundColor: 'rgba(0, 0, 255, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        }
        
      ],
    });


  }, [WBGT, RiskLim, ExtremLim, HighLim, LowLim]); // refetch les données lorsque hours est mis à jour



  useEffect(() => {

    setdata_WBGT({
      labels: received_at, //WBGT_received_at,
      datasets: [
        {
          label: 'WBGT',
          data: WBGT,
          borderColor: 'rgb(0, 0, 255)',
          backgroundColor: 'rgba(0, 0, 255, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          label: 'WBGT_f',
          data: WBGT_f,
          borderColor: 'rgb(0, 135, 255)',
          borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
          backgroundColor: 'rgba(0, 135, 235, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          label: 'WBT',
          data: WBT,
          borderColor: 'rgb(154, 102, 255)',
          backgroundColor: 'rgba(154, 102, 255, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          label: 'WBT_f',
          data: WBT_f,
          borderColor: 'rgb(174, 122, 255)',
          borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
          backgroundColor: 'rgba(174, 122, 235, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          label: 'TG',
          data: TG,
          borderColor: 'rgb(5, 5, 5)',
          backgroundColor: 'rgba(5, 5, 5, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          label: 'TG_f',
          data: TG_f,
          borderColor: 'rgb(5, 5, 5)',
          borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
          backgroundColor: 'rgba(15, 15, 15, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          label: 'TA',
          data: TempC_SHT,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          label: 'TA_f',
          data: TA_f,
          borderColor: 'rgb(255, 99, 132)',
          borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
          backgroundColor: 'rgba(255, 120, 132, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
      ],
    });



    setdata_Temp({
      labels: received_at, //received_atTempHum,
      datasets: [
        {
          label: t("Map.button.Temperature"),
          data: TempC_SHT, //plot_TempC_SHT,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
        {
          type: 'line',
          label: t("info.legend.coldzone"),
          data: received_at.map((_, index) => index === 0 || index === received_at.length - 1 ? 5 : null), // Création directe du tableau
          borderColor: 'rgba(125, 255, 255, 0.5)',
          backgroundColor: 'rgba(125, 255, 255, 0.5)', // Couleur de remplissage avec opacité
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          pointRadius: 0, // Masquer les points
          // borderWidth: 0, // Masquer la ligne
          // fill: 'origin',
          fill: 'start',        // fill: '-1',
          // fill: '1',
        },
        {
          label: t("Chart.forecast"),
          data: TA_f,
          borderColor: 'rgb(255, 99, 132)',
          borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        },
      ],
    });


    setdata_Hum({
      labels: received_at, //received_atTempHum,
      datasets: [
        {
          label: t("Map.button.Hum"),
          data: Hum_SHT, //plot_Hum_SHT,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          fill: true,
        },
        {
          label: t("Chart.forecast"),
          data: RH_f,
          borderColor: 'rgb(53, 162, 235)',
          borderDash: [3, 2], // Définit le modèle des pointillés (5 pixels de ligne, 5 pixels d'espace)
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          tension: 0.3,
          spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
          fill: true,
        },
      ],
    });


}, [TA_f, RH_f, WBGT_f, WBT_f, WBGT_f, fcast_hist]); // refetch les données lorsque hours est mis à jour











  const change_CAV = (CAV_val, key) => {
    setLim_risk(M_label.map(value => -CAV_val+ 53.5 - 8.9 * Math.log10(value)));
    setLim_extrem(M_label.map(value => -CAV_val+ 55.1 - 10.2 * Math.log10(value)));
    setLim_high(M_label.map(value => -CAV_val+ 56.7 - 11.5 * Math.log10(value)));
    setLim_low(M_label.map(value => -CAV_val+ 59.9 - 14.1 * Math.log10(value)));

    // setRiskLim(Array.from({ length: WBGT.length }).fill(-CAV_val + 56.7 - 11.5 * Math.log10(parseInt(M))));
    let laRiskLim = Array.from({ length: WBGT.length }).fill(null);
    // Mettre à jour le premier et le dernier élément
    laRiskLim[0] = -CAV_val + 53.5 - 8.9 * Math.log10(parseInt(M));
    laRiskLim[laRiskLim.length - 1] = -CAV_val + 53.5 - 8.9 * Math.log10(parseInt(M));
    setRiskLim(laRiskLim);

    // setExtremLim(Array.from({ length: WBGT.length }).fill(-CAV_val + 56.7 - 11.5 * Math.log10(parseInt(M))));
    let laExtremLim = Array.from({ length: WBGT.length }).fill(null);
    // Mettre à jour le premier et le dernier élément
    laExtremLim[0] = -CAV_val + 55.1 - 10.2 * Math.log10(parseInt(M));
    laExtremLim[laExtremLim.length - 1] = -CAV_val + 55.1 - 10.2 * Math.log10(parseInt(M));
    setExtremLim(laExtremLim);

    // setHighLim(Array.from({ length: WBGT.length }).fill(-CAV_val + 56.7 - 11.5 * Math.log10(parseInt(M))));
    let laHighLim = Array.from({ length: WBGT.length }).fill(null);
    // Mettre à jour le premier et le dernier élément
    laHighLim[0] = -CAV_val + 56.7 - 11.5 * Math.log10(parseInt(M));
    laHighLim[laHighLim.length - 1] = -CAV_val + 56.7 - 11.5 * Math.log10(parseInt(M));
    setHighLim(laHighLim);

    // setLowLim(  Array.from({ length: WBGT.length }).fill(-CAV_val + 59.9 - 14.1 * Math.log10(parseInt(M))) );
    let laLowLim = Array.from({ length: WBGT.length }).fill(null);
    // Mettre à jour le premier et le dernier élément
    laLowLim[0] = -CAV_val + 59.9 - 14.1 * Math.log10(parseInt(M));
    laLowLim[laLowLim.length - 1] =  -CAV_val + 59.9 - 14.1 * Math.log10(parseInt(M));
    setLowLim(laLowLim);


    setCAV(CAV_val);
    setCAV_key(key);
    // console.log('CAV');
    // console.log(CAV_val);
    // change_M_CAV(received_at, WBGT, HighLim, LowLim);

  }


  const change_M = (M_val, key) => {

     // setRiskLim(Array.from({ length: WBGT.length }).fill(-CAV + 56.7 - 11.5 * Math.log10(parseInt(M_val))));
     let laRiskLim = Array.from({ length: WBGT.length }).fill(null);
     // Mettre à jour le premier et le dernier élément
     laRiskLim[0] = -CAV + 53.5 - 8.9 * Math.log10(parseInt(M_val));
     laRiskLim[laRiskLim.length - 1] = -CAV + 53.5 - 8.9 * Math.log10(parseInt(M_val));
     setRiskLim(laRiskLim);

    // setExtremLim(Array.from({ length: WBGT.length }).fill(-CAV + 56.7 - 11.5 * Math.log10(parseInt(M_val))));
    let laExtremLim = Array.from({ length: WBGT.length }).fill(null);
    // Mettre à jour le premier et le dernier élément
    laExtremLim[0] = -CAV + 55.1 - 10.2 * Math.log10(parseInt(M_val));
    laExtremLim[laExtremLim.length - 1] = -CAV + 55.1 - 10.2 * Math.log10(parseInt(M_val));
    setExtremLim(laExtremLim);

    // setHighLim(Array.from({ length: WBGT.length }).fill(-CAV + 56.7 - 11.5 * Math.log10(parseInt(M_val))));
    let laHighLim = Array.from({ length: WBGT.length }).fill(null);
    // Mettre à jour le premier et le dernier élément
    laHighLim[0] = -CAV + 56.7 - 11.5 * Math.log10(parseInt(M_val));
    laHighLim[laHighLim.length - 1] = -CAV + 56.7 - 11.5 * Math.log10(parseInt(M_val));
    setHighLim(laHighLim);

    // setLowLim(  Array.from({ length: WBGT.length }).fill(-CAV + 59.9 - 14.1 * Math.log10(parseInt(M_val))) );
    let laLowLim = Array.from({ length: WBGT.length }).fill(null);
    // Mettre à jour le premier et le dernier élément
    laLowLim[0] = -CAV + 59.9 - 14.1 * Math.log10(parseInt(M_val));
    laLowLim[laLowLim.length - 1] =  -CAV + 59.9 - 14.1 * Math.log10(parseInt(M_val));
    setLowLim(laLowLim);


    // setM(value);
    setM(parseInt(M_val));
    setM_key(key);
    // console.log('Métabolisme');
    // console.log(M_val);
    // console.log('key');
    // console.log(key);
  }





  let activeSpots = pointSource.data.features.filter(feature => feature.properties.active === "1");
  // // console.log('activeSpots')
  // // console.log(activeSpots)

  // const generateSpotOptions = (source) => {
  //   return (
  //     <optgroup label={source}>
  //       {activeSpots
  //       // .filter(feature => feature.properties.source === source && feature.properties.active === "1")
  //       .filter(feature => feature.properties.source === source)
  //         .map(feature => (
  //           <option
  //             key={feature.properties.spot_id}
  //             value={feature.properties.spot_id}
  //             selected={spot === feature.properties.spot_id}
  //           >
  //             {feature.properties.spotname}
  //           </option>
  //         ))}
  //     </optgroup>
  //   );
  // };
  const generateSpotOptions = (source_list) => {
    return source_list.map((source) => (
      <optgroup label={source} key={source}>
        {activeSpots
          .filter(feature => feature.properties.source === source)
          .map(feature => (
            <option
              key={feature.properties.spot_id}
              value={feature.properties.spot_id}
              selected={spot === feature.properties.spot_id}
            >
              {feature.properties.spotname}
            </option>
          ))}
      </optgroup>
    ));
  };
  

  // const generateSpotOptionsposition = (source) => {
  //   let userCoordinates = [Long, Lat]; // Utilisez les coordonnées de l'utilisateur
  
  //   return (
  //     <optgroup label={source}>
  //       {activeSpots
  //         .filter(feature => feature.properties.source === source)
  //         .map(feature => {
  //           let spotCoordinates = feature.geometry.coordinates;
  //           let distance = Math.sqrt(
  //             Math.pow(userCoordinates[0] - spotCoordinates[0], 2) +
  //             Math.pow(userCoordinates[1] - spotCoordinates[1], 2)
  //           );
  
  //           return {
  //             distance,
  //             feature,
  //           };
  //         })
  //         .sort((a, b) => a.distance - b.distance)
  //         .slice(0, 15) //limiter à 15 spots
  //         .map(({ feature }) => (
  //           <option
  //             key={feature.properties.spot_id}
  //             value={feature.properties.spot_id}
  //             selected={spot === feature.properties.spot_id}
  //           >
  //             {feature.properties.spotname}
  //           </option>
  //         ))}
  //     </optgroup>
  //   );
  // };
  const generateSpotOptionsposition = (source_list) => {
    let userCoordinates = [Long, Lat]; // Utilisez les coordonnées de l'utilisateur
  
    return source_list.map((source) => (
      <optgroup label={source} key={source}>
        {activeSpots
          .filter(feature => feature.properties.source === source)
          .map(feature => {
            let spotCoordinates = feature.geometry.coordinates;
            let distance = Math.sqrt(
              Math.pow(userCoordinates[0] - spotCoordinates[0], 2) +
              Math.pow(userCoordinates[1] - spotCoordinates[1], 2)
            );
  
            return {
              distance,
              feature,
            };
          })
          .sort((a, b) => a.distance - b.distance)
          .slice(0, 15) //limiter à 15 spots
          .map(({ feature }) => (
            <option
              key={feature.properties.spot_id}
              value={feature.properties.spot_id}
              selected={spot === feature.properties.spot_id}
            >
              {feature.properties.spotname}
            </option>
          ))}
      </optgroup>
    ));
  };




  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePositionClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLong(position.coords.longitude);
          setLat(position.coords.latitude);
          
          selectstation(position.coords.longitude, position.coords.latitude); // fonction de rappel en props
          
          // console.log('longitude');
          // console.log(position.coords.longitude);
          // console.log('latitude');
          // console.log(position.coords.latitude);
  
          let userCoordinates = [position.coords.longitude, position.coords.latitude];
  
          let closestSpot = pointSource.data.features.reduce((closest, current) => {
            let spotCoordinates = current.geometry.coordinates;
            let distance = Math.sqrt(
              Math.pow(userCoordinates[0] - spotCoordinates[0], 2) +
              Math.pow(userCoordinates[1] - spotCoordinates[1], 2)
            );
  
            if (distance < closest.distance) {
              return { distance, feature: current };
            } else {
              return closest;
            }
          }, { distance: Infinity, feature: null });
  
          let closestSpotFeature = closestSpot.feature;
          // setSpotOnMp(closestSpotFeature.properties.spotname);
          setSpotOnMp(closestSpotFeature.properties.spot_id);
          // console.log('closest spots')
        },
        (error) => {
          console.error(error);
        }
      );
    }


  };



  let filteredOptions = activeSpots
  .filter((feature) => {
 
    const normalizeString = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    };
    let searchTermNormalized = normalizeString(searchTerm);
    let spotNameNormalized = normalizeString(feature.properties.spotname);
    
    let searchTermRegex = new RegExp(`\\b${searchTermNormalized}`, 'i');
    
    return searchTermRegex.test(spotNameNormalized);
    
    
    // return searchTermRegex.test(feature.properties.spotname.toLowerCase());

    // return spotName.includes(searchTerm.toLowerCase());
    // return spotName.includes(searchTermRegex);
  })
  .map((feature) => (
    <option
      key={feature.properties.spot_id}
      value={feature.properties.spot_id}
      selected={spot === feature.properties.spot_id}
    >
      {feature.properties.spotname}
    </option>
  ));
  
  return (
    <Container fluid>
      <InstallBanner/>
      <AlerteMessage/>
      
      {/*<ImageUpload/>*/}
      {/* <Row>
        <Form>
          <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch_"
              // label="Check this switch"
              checked={fcast_hist}
              // onChange={setfcast_hist(!fcast_hist)}
              onChange={(e) => setfcast_hist(e.target.checked)}
          />
        </Form>
      </Row> */}
      <Row>


        <Col sm={12} lg={4}>  

         
        

        {/* <Row>
          <Select
            options={activeSpots.map(feature => ({
              value: feature.properties.spot_id,
              label: feature.properties.spotname
            }))}
            // value={activeSpots.find(feature => feature.properties.spot_id === spot)}
            value={activeSpots.find(feature => feature.properties.spot_id === spot)}
            onChange={option => setSpot(option.value)}
            isSearchable={true}
            // placeholder={activeSpots.find(feature => feature.properties.spot_id === spot)?.properties.spotname || "Sélectionner un spot"}
            // placeholder={"Sélectionner un spot"}
            // placeholder={null} // Ajoutez cette ligne pour supprimer le placeholder
            // // defaultValue={spot ? { value: spot.properties.spot_id, label: spot.properties.spotname } : null}
            // defaultValue={activeSpots.find(feature => feature.properties.spot_id === spot)?.properties.spotname || "Sélectionner un spot"}

          />
        </Row>    */}
 

        <Row>
    
        <Col>
        <Form.Label>{t("Chart.spotselect")}: &nbsp; {!showSearch &&<><BsSearch size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/></>}   {showSearch &&<><BsViewList size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/> </>}  &emsp; <Info fcast_hist={fcast_hist} setfcast_hist={setfcast_hist}/> </Form.Label> {/*&nbsp; &nbsp; <BsGeoFill size={22} color='#0D6EFD' onClick={handlePositionClick}/>*/}
          {/* <Form.Label>Séléctioner un spot: &nbsp; {!showSearch &&<><BsSearch size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/> mode liste</>}   {showSearch &&<><BsViewList size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/> mode recherche</>}</Form.Label> */}
          {/* <Form.Label>Séléctioner un spot: <BsGeoFill size={22} color='#0D6EFD' onClick={handleButtonClick}/> {!showSearch &&<BsSearch size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/>}   {showSearch &&<BsViewList size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/>}</Form.Label> */}
        </Col>
          </Row>
          {showSearch &&
          <Row>
          <Col>
            <Select
              options={activeSpots.map(feature => ({
                value: feature.properties.spot_id,
                label: feature.properties.spotname
              }))}
              // value={activeSpots.find(feature => feature.properties.spot_id === spot)}
              // value={activeSpots.find(feature => feature.properties.spot_id === spot)}
              value={spot}
              onChange={option => setSpot(option.value)}
              isSearchable={true}
              // placeholder={activeSpots.find(feature => feature.properties.spot_id === spot)?.properties.spotname || "Sélectionner un spot"}
              // placeholder={"Sélectionner un spot"}
              // placeholder={null} // Ajoutez cette ligne pour supprimer le placeholder
              // // defaultValue={spot ? { value: spot.properties.spot_id, label: spot.properties.spotname } : null}
              // defaultValue={activeSpots.find(feature => feature.properties.spot_id === spot)?.properties.spotname || "Sélectionner un spot"}

            />
            {/* <FormControl
                type="text"
                placeholder="Chercher un spot"
                value={searchTerm}
                onChange={handleInputChange}
              /> */}
              {/* <br></br> */}
            </Col>
        </Row>
        }
        {!showSearch &&
        <Row>

        <div>
        {/* <FormControl
            type="text"
            placeholder="Chercher un spot"
            value={searchTerm}
            onChange={handleInputChange}
          /> */}
        {/* <Form.Label>Séléctioner un spot:</Form.Label> */}
          {/* <FormControl
            type="text"
            placeholder="Chercher un spot"
            value={searchTerm}
            onChange={handleInputChange}
          /> */}

          <Form.Select size="lg" onChange={() => setSpot(event.target.value)} >
          {/* <Form.Select size="lg" onChange={handleChangeSpot}> */}
            <option>{t("Chart.spotselect")}</option>
              
              {searchTerm !== '' && 
              <optgroup label="Recherche">
                {filteredOptions}
              </optgroup>
              }
            {searchTerm === '' && (
              <>
              
                {generateSpotOptions(source_list)}
                {/* {generateSpotOptions('gva')} */}
                {/* {generateSpotOptions('gigi')}
                {generateSpotOptions('TrueWindSpeed')}
                {generateSpotOptionsposition('METAR')}
                {generateSpotOptions('Vengeron')}
                {generateSpotOptions('Windspots')} */}
              </>
            )}
            

          </Form.Select>
        </div>
        {/* </Col> */}
        </Row>
        }
        {/* <Row>
          <br></br>
        </Row>
        <Row>
          <iframe width="560" height="267" src="https://www.youtube.com/embed/QP4CdQ0J2Uk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
          style={{
            border: "1px solid #CED4DA", //#0D6EFD",
            borderRadius: "10px", // Bordure avec un arrondi aux coins
            overflow: "hidden", // Empêche le contenu de déborder de la bordure
            margin: "0", // Supprime les marges par défaut
            padding: "0" // Supprime le padding par défaut
          }}></iframe>
        </Row> */}
        


        <Row>
          <Station selectstation={selectstation} spot={spot} pointSource={pointSource} timeAgo={moment(received_time[received_time.length - 1], 'DD/MM/YYYY, hh:mm:ss').fromNow()}/>
        </Row>


        <Row>
          {/* <Dirsvg received_time={received_time[received_time.length - 1]} received_at={received_at[received_at.length - 1]} received_date={received_date[received_date.length - 1]} Wind_ave={Wind_ave[Wind_ave.length - 1]} Wind_3sgust={Wind_3sgust[Wind_3sgust.length - 1]} Wind_3smin={Wind_3smin[Wind_3smin.length - 1]} Wind_stdev={Wind_stdev[Wind_stdev.length - 1]} Dir_ave={Dir_ave[Dir_ave.length - 1]} Dir_3sgust={Dir_3sgust[Dir_3sgust.length - 1]} Dir_stdev={Dir_stdev[Dir_stdev.length - 1]} gust_3stime={gust_3stime[gust_3stime.length - 1]} Pressure={Pressure[Pressure.length - 1]} Hum_SHT={Hum_SHT[Hum_SHT.length - 1]} TempC_DS={TempC_DS[TempC_DS.length - 1]} TempC_SHT={TempC_SHT[TempC_SHT.length - 1]} WTMP={WTMP[WTMP.length - 1]} Temp_Red={Temp_Red[Temp_Red.length - 1]} Temp_White={Temp_White[Temp_White.length - 1]} /> */}
          <Dirsvg 
          received_time={received_time.slice().reverse().find(value => value !== null && !isNaN(value))} 
          received_at={received_at.slice().reverse().find(value => value !== null && !isNaN(value))} 
          received_date={received_date.slice().reverse().find(value => value !== null && !isNaN(value))} 
          WBGT={WBGT.slice().reverse().find(value => value !== null && !isNaN(value))}
          WBT={WBT.slice().reverse().find(value => value !== null && !isNaN(value))}
          TG={TG.slice().reverse().find(value => value !== null && !isNaN(value))}
          Wind_ave={Wind_ave.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Wind_3sgust={Wind_3sgust.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Wind_3smin={Wind_3smin.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Wind_stdev={Wind_stdev.slice().reverse().find(value => value !== null && !isNaN(value))} 
          // Dir_ave={Dir_ave.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Dir_ave={Dir_ave.slice().reverse().find(value => value !== null)} 
          Dir_3sgust={Dir_3sgust.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Dir_stdev={Dir_stdev.slice().reverse().find(value => value !== null && !isNaN(value))} 
          gust_3stime={gust_3stime.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Pressure={Pressure.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Hum_SHT={Hum_SHT.slice().reverse().find(value => value !== null && !isNaN(value))} 
          TempC_DS={TempC_DS.slice().reverse().find(value => value !== null && !isNaN(value))} 
          TempC_SHT={TempC_SHT.slice().reverse().find(value => value !== null && !isNaN(value))} 
          WTMP={WTMP.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Temp_Red={Temp_Red.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Temp_White={Temp_White.slice().reverse().find(value => value !== null && !isNaN(value))}

          Type={Type.slice().reverse().find(value => value !== null && !isNaN(value))}
          T_min={T_min.slice().reverse().find(value => value !== null && !isNaN(value))}
          T_max={T_max.slice().reverse().find(value => value !== null && !isNaN(value))}
          Irradiation={Irradiation.slice().reverse().find(value => value !== null && !isNaN(value))}
          Irr_max={Irr_max.slice().reverse().find(value => value !== null && !isNaN(value))}

          Rain={totalRain}
          Rain_time={Rain_time.slice().reverse().find(value => value !== null && !isNaN(value))}
          Frost_alert={Frost_alert.slice().reverse().find(value => value !== null && !isNaN(value))}
          Heater_on={Heater_on.slice().reverse().find(value => value !== null && !isNaN(value))}
          RainIntensityCorrection={RainIntensityCorrection.slice().reverse().find(value => value !== null && !isNaN(value))}
          
          Rain_rate={Rain_rate.slice().reverse().find(value => value !== null && !isNaN(value))}
          
          DewP={DewP.slice().reverse().find(value => value !== null && !isNaN(value))}
          Visib={Visib.slice().reverse().find(value => value !== null && !isNaN(value))}
          vertVis={vertVis.slice().reverse().find(value => value !== null && !isNaN(value))}
          Snow={Snow.slice().reverse().find(value => value !== null && !isNaN(value))}



          WVHT={WVHT.slice().reverse().find(value => value !== null && !isNaN(value))} // - Hauteur significative des vagues ={en mètres)
          DPD={DPD.slice().reverse().find(value => value !== null && !isNaN(value))} // - Période de vague dominante ={en secondes)
          APD={APD.slice().reverse().find(value => value !== null && !isNaN(value))} // - Période moyenne de vague ={en secondes)
          MWD={MWD.slice().reverse().find(value => value !== null && !isNaN(value))} // - Direction moyenne des vagues ={en degrés)
      
          PTDY={PTDY.slice().reverse().find(value => value !== null && !isNaN(value))} // - Changement de pression en 3 heures ={en hPa)
      
      
      
          TIDE={TIDE.slice().reverse().find(value => value !== null && !isNaN(value))} // - Niveau de la marée
          hw={hw.slice().reverse().find(value => value !== null && !isNaN(value))} // - marée haute
          hw_time={hw_time.slice().reverse().find(value => value !== null && !isNaN(value))} // - heure marée haute
          lw={lw.slice().reverse().find(value => value !== null && !isNaN(value))} // - marée basse
          lw_time={lw_time.slice().reverse().find(value => value !== null && !isNaN(value))} // - heure marée basse
      
      

          hours={hours}

          M={M}
          CAV={CAV}
          
          RiskLim={-CAV + 53.5 - 8.9 * Math.log10(parseInt(M))}
          ExtremLim={-CAV + 55.1 - 10.2 * Math.log10(parseInt(M))}
          HighLim={-CAV + 56.7 - 11.5 * Math.log10(parseInt(M))}
          LowLim={-CAV + 59.9 - 14.1 * Math.log10(parseInt(M))}

          // ColdLim={ColdLim.slice(1, 2)}
          ColdLim={5}
          />



        </Row>

        {(() => {
          //if (Dir_ave.some(value => value !== null && !isNaN(value))||Dir_3sgust.some(value => value !== null && !isNaN(value))||Wind_ave.some(value => value !== null && !isNaN(value))||Wind_3sgust.some(value => value !== null && !isNaN(value))) {
          if (0==1) {
              return (
              <div>
                <br></br>
                <Row>
                  <WindStat
                  received_at={received_at}
                  Wind_ave={Wind_ave}
                  Wind_3sgust={Wind_3sgust}
                  Wind_3smin={Wind_3smin}
                  Wind_stdev={Wind_stdev}
                  Dir_ave={Dir_ave}
                  Dir_3sgust={Dir_3sgust}
                  Dir_stdev={Dir_stdev}
                  gust_3stime={gust_3stime}
                  Pressure={Pressure}
                  Hum_SHT={Hum_SHT}
                  TempC_DS={TempC_DS}
                  TempC_SHT={TempC_SHT}
                  WTMP={WTMP}
                  Temp_Red={Temp_Red}
                  Temp_White={Temp_White}

                  Type={Type}
                  T_min={T_min}
                  T_max={T_max}
                  Irradiation={Irradiation}
                  Irr_max={Irr_max}
              
                  Rain={Rain}
                  Rain_time={Rain_time}
                  Frost_alert={Frost_alert}
                  Heater_on={Heater_on}
                  RainIntensityCorrection={RainIntensityCorrection}
                  />
                
                </Row>
              </div>
            )
          }
        })()}

        <Row>
        {(() => {
          if (0==0) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_safe} options={options_safe}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}
        </Row>

        {/* <Row>

          <Col>
            <Form.Label>{t("Chart.Metabolisme")}</Form.Label>
            {/* <Form.Select aria-label="Default select example" onChange={() => setM(event.target.value)}> *}
            <Form.Select
            size="sm" onChange={() => change_M(event.target.value, event.target.key)}
            style={{ overflow: 'hidden' }}>
              {/* <option>Open this select menu</option> *}
              <option selected={M_key =="0"} key="0" value="115">Type 0: Repos - 115 W</option>
              <option selected={M_key =="1"} key="1" value="180">Type 1: Faible - 180 W</option>
              <option selected={M_key =="2"} key="2" value="300">Type 2: Modéré - 300 W</option>
              <option selected={M_key =="3"} key="3" value="415">Type 3: Élevé - 415 W</option>
              <option selected={M_key =="4"} key="4" value="520">Type 4: Très élevé - 520 W</option>
            </Form.Select>
            <br></br>
            <br></br>
          </Col>
          <Col>
            <Form.Label>CAV [°C-WBGT]</Form.Label>
            <Form.Select 
            size="sm" 
            onChange={() => change_CAV(event.target.value, event.target.key)}
            style={{ overflow: 'hidden' }}>
              {/* <option>Open this select menu</option> *}
              <optgroup label={t("info.CAV.title.sc")}></optgroup>
              <option selected={CAV_key =="0"} key="0" value="0">0 - Vêtements de travail</option>
              <option selected={CAV_key =="1"} key="1" value="0">0 - Combinaisons</option>
              <option selected={CAV_key =="0"} key="2" value="0">0 - Combinaisons en SMS non tissé en une seule couche</option>
              <option selected={CAV_key =="3"} key="3" value="2">2 - Combinaisons en polyoléfine non tissee en une seule couche</option>
              <option selected={CAV_key =="4"} key="4" value="4">4 - Tablier pare-vapeur à manches longues et combinaisons longues</option>
              <option selected={CAV_key =="5"} key="5" value="3">3 - Double couche de vêtements tissés</option>
              <option selected={CAV_key =="6"} key="6" value="10">10 - Combinaisons pare-vapeur en une seule couche, sans cagoule</option>
              {/* <option value="11">11 - Combinaisons pare-vapeur à cagoule en une seule couche</option> *}
              <option selected={CAV_key =="7"} key="7" value="12">12 - Pare-vapeur au-dessus de combinaisons, sans cagoule</option>
              {/* <option value="+1">Cagoule</option> *}
              <optgroup label={t("info.CAV.title.ac")}></optgroup>
              <option selected={CAV_key =="8"} key="8" value="1">1 - Vêtements de travail + cagoule</option>
              <option selected={CAV_key =="9"} key="9" value="1">1 - Combinaisons + cagoule</option>
              <option selected={CAV_key =="10"} key="10" value="1">1 - Combinaisons en SMS non tissé en une seule couche + cagoule</option>
              <option selected={CAV_key =="11"} key="11" value="3">3 - Combinaisons en polyoléfine non tissee en une seule couche + cagoule</option>
              <option selected={CAV_key =="12"} key="12" value="5">5 - Tablier pare-vapeur à manches longues et combinaisons longues + cagoule</option>
              <option selected={CAV_key =="13"} key="13" value="4">4 - Double couche de vêtements tissés + cagoule</option>
              {/* <option value="10">11 - Combinaisons pare-vapeur en une seule couche, sans cagoule</option> *}
              <option selected={CAV_key =="14"} key="14" value="12">12 - Combinaisons pare-vapeur à cagoule en une seule couche</option>
              {/* <option value="12">13 - Pare-vapeur au-dessus de com-binaisons, sans cagoule</option> *}
            </Form.Select>
            {/* <Form.Label>Range</Form.Label>
            <Form.Range /> *}
            
            <br></br>
            <br></br>
          </Col>
        
        </Row> */}


        {/* <Row>
          <Clock montre={0}/>
          <br></br>
        </Row> */}
      </Col>

      <Col sm={12} lg={8}>  
      <Row>


      <Col>
            <Form.Label htmlFor="metabol">{t("Chart.Metabolisme")} [W]:</Form.Label>
            {/* <Form.Select aria-label="Default select example" onChange={() => setM(event.target.value)}> */}
            {/*event.target.key*/}
            <Form.Select
            id="metabo_id"
            name="metabo_name"
            size="sm" onChange={() => change_M(event.target.value, event.target.selectedIndex)} 
            style={{ overflow: 'hidden' }}>
              {/* <option>Open this select menu</option> */}
              {/* <option selected={M_key ==0} key="0" value="115">#0: Repos - 115 W</option>
              <option selected={M_key ==1} key="1" value="180">#1: Faible - 180 W</option>
              <option selected={M_key ==2} key="2" value="300">#2: Modéré - 300 W</option>
              <option selected={M_key ==3} key="3" value="415">#3: Élevé - 415 W</option>
              <option selected={M_key ==4} key="4" value="520">#4: Très élevé - 520 W</option> */}
              <option selected={M_key ==0} key="0" value="115">{M_types[0]}</option>
              <option selected={M_key ==1} key="1" value="180">{M_types[1]}</option>
              <option selected={M_key ==2} key="2" value="300">{M_types[2]}</option>
              <option selected={M_key ==3} key="3" value="415">{M_types[3]}</option>
              <option selected={M_key ==4} key="4" value="520">{M_types[4]}</option>
            </Form.Select>
            <br></br>
            <br></br>
          </Col>
          <Col>
            <Form.Label htmlFor="cav">CAV [°C-WBGT]:</Form.Label>
            <Form.Select
            id="cav_id"
            name="cav_name" 
            size="sm" 
            onChange={() => change_CAV(event.target.value, event.target.selectedIndex)}
            style={{ overflow: 'hidden' }}>

              {/* <optgroup label={t("info.CAV.title.sc")}></optgroup>
              <option selected={CAV_key ==0} key="0" value="0">0 - Vêtements de travail</option>
              <option selected={CAV_key ==1} key="1" value="0">0 - Combinaisons</option>
              <option selected={CAV_key ==2} key="2" value="0">0 - Combinaisons en SMS non tissé en une seule couche</option>
              <option selected={CAV_key ==3} key="3" value="2">2 - Combinaisons en polyoléfine non tissee en une seule couche</option>
              <option selected={CAV_key ==4} key="4" value="4">4 - Tablier pare-vapeur à manches longues et combinaisons longues</option>
              <option selected={CAV_key ==5} key="5" value="3">3 - Double couche de vêtements tissés</option>
              <option selected={CAV_key ==6} key="6" value="10">10 - Combinaisons pare-vapeur en une seule couche, sans cagoule</option>
              <option selected={CAV_key ==7} key="7" value="12">12 - Pare-vapeur au-dessus de combinaisons, sans cagoule</option>
              <optgroup label={t("info.CAV.title.ac")}></optgroup>
              <option selected={CAV_key ==8} key="8" value="1">1 - Vêtements de travail + cagoule</option>
              <option selected={CAV_key ==9} key="9" value="1">1 - Combinaisons + cagoule</option>
              <option selected={CAV_key ==10} key="10" value="1">1 - Combinaisons en SMS non tissé en une seule couche + cagoule</option>
              <option selected={CAV_key ==11} key="11" value="3">3 - Combinaisons en polyoléfine non tissee en une seule couche + cagoule</option>
              <option selected={CAV_key ==12} key="12" value="5">5 - Tablier pare-vapeur à manches longues et combinaisons longues + cagoule</option>
              <option selected={CAV_key ==13} key="13" value="4">4 - Double couche de vêtements tissés + cagoule</option>
              <option selected={CAV_key ==14} key="14" value="11">11 - Combinaisons pare-vapeur à cagoule en une seule couche</option>
              <option selected={CAV_key ==15} key="15" value="18">18 - test</option>
              <option selected={CAV_key ==16} key="16" value="25">25 - test</option> */}
              <optgroup label={t("info.CAV.title.sc")}></optgroup>
              <option selected={CAV_key ==0} key="0" value="0">{CAV_types[0]}</option>
              <option selected={CAV_key ==1} key="1" value="0">{CAV_types[1]}</option>
              <option selected={CAV_key ==2} key="2" value="0">{CAV_types[2]}</option>
              <option selected={CAV_key ==3} key="3" value="2">{CAV_types[3]}</option>
              <option selected={CAV_key ==4} key="4" value="4">{CAV_types[4]}</option>
              <option selected={CAV_key ==5} key="5" value="3">{CAV_types[5]}</option>
              <option selected={CAV_key ==6} key="6" value="10">{CAV_types[6]}</option>
              <option selected={CAV_key ==7} key="7" value="12">{CAV_types[7]}</option>
              <optgroup label={t("info.CAV.title.ac")}></optgroup>
              <option selected={CAV_key ==8} key="8" value="1">{CAV_types[8]}</option>
              <option selected={CAV_key ==9} key="9" value="1">{CAV_types[9]}</option>
              <option selected={CAV_key ==10} key="10" value="1">{CAV_types[10]}</option>
              <option selected={CAV_key ==11} key="11" value="3">{CAV_types[11]}</option>
              <option selected={CAV_key ==12} key="12" value="5">{CAV_types[12]}</option>
              <option selected={CAV_key ==13} key="13" value="4">{CAV_types[13]}</option>
              <option selected={CAV_key ==14} key="14" value="11">{CAV_types[14]}</option>
              <option selected={CAV_key ==15} key="15" value="18">{CAV_types[15]}</option>
              <option selected={CAV_key ==16} key="16" value="25">{CAV_types[16]}</option>

            </Form.Select>
            {/* <Form.Label>Range</Form.Label>
            <Form.Range /> */}
            
            <br></br>
            <br></br>
          </Col>
        



        <Col sm={12} lg={6}>
          {/* <DateTimeRangeFieldValue
            DateTimeRange={DateTimeRange} setDateTimeRange={setDateTimeRange}
            hours={hours} setHours={setHours}
          /> */}
          <DateTimeRangeFieldValue
            DateTimeRange={DateTimeRange} setDateTimeRange={setDateTimeRange}
            hours={hours} setHours={setHours}
          />
          <br></br>
        </Col>



        {/* <ToggleButtonGroup type="radio" name="options" defaultValue={6} value={hours} onChange={() => setHours(event.target.value)}> */}
        
        {hours>0 &&
          <ToggleButtonGroup type="radio" name="option" defaultValue={6}
            onChange={() => {
              setHours(event.target.value);
              if(event.target.value>0){
                setDateTimeRange([dayjs().subtract(event.target.value, 'hours'), dayjs()])
              }
              else{
                setDateTimeRange([dayjs().subtract(24, 'hours'), dayjs().subtract(event.target.value, 'hours')])
              }
              }
            }
          >
            
            <ToggleButton variant="outline-primary" id="tbg-radio-1" value={2}>
              {t("Chart.2h")}
            </ToggleButton>

            {/* <ToggleButton variant="outline-primary" id="tbg-radio-2" value={3}>
              3 h
            </ToggleButton> */}
            
            <ToggleButton variant="outline-primary" id="tbg-radio-3" value={6}>
              {t("Chart.6h")}
            </ToggleButton>
            <ToggleButton variant="outline-primary" id="tbg-radio-4" value={12}>
              {t("Chart.12h")}
            </ToggleButton>
            <ToggleButton variant="outline-primary" id="tbg-radio-5" value={24}>
              {t("Chart.24h")}
            </ToggleButton>
            <ToggleButton variant="outline-primary" id="tbg-radio-6" value={-48}>
              {t("Chart.2d_prev")}
            </ToggleButton>

          </ToggleButtonGroup>
        }

        {hours<=0 &&
          <ToggleButtonGroup type="radio" name="options" defaultValue={0}
            onChange={() => { if(event.target.value){
              setHours(event.target.value);
              setDateTimeRange([dayjs().subtract(event.target.value, 'hours'), dayjs()])
            } }}
          >
            
            <ToggleButton variant="outline-primary" id="tbg-radio-1" value={0}>
              {t("Chart.Perso")}
            </ToggleButton>

            <ToggleButton variant="outline-primary" id="tbg-radio-2" value={6}>
              {t("Chart.2h3h6h12h24h")}
            </ToggleButton>
            
          </ToggleButtonGroup>
        }
        
      </Row>


      <Row>

        {(() => {
          // if (WBGT.some(value => value !== null && !isNaN(value))) {
          // if (hours<=0) {
          if (1==1) {
            return (
              <div>
                  {/* <br></br> */}

                <ExportButton data_WBGT_small={data_WBGT_small} options_WBGT_small={options_WBGT_small_pdf} data_Temp={data_Temp} options_Temp={options_Temp_pdf} DateTimeRange={DateTimeRange} WBGT={WBGT} pointSource={pointSource} spot={spot} M={M} M_key={M_key} M_types={M_types} CAV={CAV} CAV_key={CAV_key} CAV_types={CAV_types} WBGT_received_time={WBGTreceivedtime} received_timeTempHum={receivedtimeTempHum}

                 />

              </div>
              // <div>
              //   <br></br>
              //   <div style={{height:"60vh"}} >
              //     <Line data={data_WBGT_small} options={options_WBGT_small}/>
              //   </div>
              //   <br></br>
              // </div>
            )
          }
        })()}
        {(() => {
          if (1==1) {
          // if (WBGT.some(value => value !== null && !isNaN(value))) {
              return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_WBGT_small} options={options_WBGT_small}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}


        {(() => {
          if (Wind_ave.some(value => value !== null && !isNaN(value))||Wind_3sgust.some(value => value !== null && !isNaN(value))||Wind_3smin.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Wspd} options={options_SPD}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}
        {(() => {
          if (Dir_ave.some(value => value !== null && !isNaN(value))||Dir_3sgust.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Wdir} options={options_Dir}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}

        {(() => {
          if (Dir_ave.some(value => value !== null && !isNaN(value))||Dir_3sgust.some(value => value !== null && !isNaN(value))||Wind_ave.some(value => value !== null && !isNaN(value))||Wind_3sgust.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <Row>
                  <Col sm={12} lg={2}></Col>
                  <Col sm={12} lg={8}>
                    <WindStat
                    received_at={received_at}
                    Wind_ave={Wind_ave}
                    Wind_3sgust={Wind_3sgust}
                    Wind_3smin={Wind_3smin}
                    Wind_stdev={Wind_stdev}
                    Dir_ave={Dir_ave}
                    Dir_3sgust={Dir_3sgust}
                    Dir_stdev={Dir_stdev}
                    gust_3stime={gust_3stime}
                    TempC_DS={TempC_DS}
                    TempC_SHT={TempC_SHT}
                    WTMP={WTMP}
                    Temp_Red={Temp_Red}
                    Temp_White={Temp_White}
  
                    Type={Type}
                    T_min={T_min}
                    T_max={T_max}
                    Pressure={Pressure}
                    Hum_SHT={Hum_SHT}
                    Irradiation={Irradiation}
                    Irr_max={Irr_max}
                
                    Rain={Rain}
                    Rain_time={Rain_time}
                    Frost_alert={Frost_alert}
                    Heater_on={Heater_on}
                    RainIntensityCorrection={RainIntensityCorrection}
                    />
                  </Col>
                  <Col sm={12} lg={2}></Col>
                </Row>
              </div>
            )
          }
        })()}

        {(() => {
          if (WVHT.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_WVHT} options={options_WVHT}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}
        {(() => {
          if (DPD.some(value => value !== null && !isNaN(value))||APD.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_PD} options={options_PD}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}
        {(() => {
          if (MWD.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_MWD} options={options_MWD}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}
        
        {(() => {
          if (DPD.some(value => value !== null && !isNaN(value))&&APD.some(value => value !== null && !isNaN(value))) {
          // if (1==1) {
              return (
                <div>
                <br></br>
                <div style={{height:"60vh"}} >
                <Wave_line
                    // WVHT={WVHT.slice().reverse().find(value => value !== null && !isNaN(value))} 
                    DPD={DPD.slice().reverse().find(value => value !== null && !isNaN(value))} 
                    APD={APD.slice().reverse().find(value => value !== null && !isNaN(value))}                     
                    // MWD={MWD.slice().reverse().find(value => value !== null && !isNaN(value))} 
                    // WVHT={5} 
                    // DPD={12} 
                    // APD={3}                     
                    // MWD={120} 
                    />
                </div>
                <br></br>
              </div>
            )
          }
        })()}

        {(() => {
          if (Temp_White.some(value => value !== null && !isNaN(value))||WTMP.some(value => value !== null && !isNaN(value))||Temp_Red.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Eau} options={options_EAU}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}

        {(() => {
          if (TempC_SHT.some(value => value !== null && !isNaN(value))) {
              return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Temp} options={options_Temp}/>
                </div>
                <br></br>
              </div>

            )
          }
        })()}
        {(() => {
          // if (Hum_SHT.some(value => value !== null && !isNaN(value))) {
          if (Hum_SHT.some(value => value !== null && !isNaN(value))) {
            
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Hum} options={options_Hum}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}
        {(() => {
          if (Pressure.some(value => value !== null && !isNaN(value))) {
            
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Press} options={options_Press}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}
        {(() => {
          if (Irradiation.some(value => value !== null && !isNaN(value))) {
            
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Soleil} options={options_Sol}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}
        {(() => {
          if (Rain.some(value => value !== null && !isNaN(value))) {
            
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Bar data={data_Pluie} options={options_Pluie}/>
                </div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Averse} options={options_Averse}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}
        
        {(() => {
          // if (WBGT.some(value => value !== null && !isNaN(value))) {
          if (WBGT.some(value => value !== null && !isNaN(value))) {
              return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_WBGT} options={options_WBGT}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}


        {(() => {
          if (rssi.some(value => value !== null && !isNaN(value)) && loggedInUser=='Guillaume') {
              return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_rssi} options={options_rssi}/>
                </div>
                <br></br>
              </div>

            )
          }
        })()}

        {(() => {
          if (BatV.some(value => value !== null && !isNaN(value)) && loggedInUser=='Guillaume') {
              return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_BatV} options={options_BatV}/>
                </div>
                <br></br>
              </div>

            )
          }
        })()}

        

{/* 
        {(() => {
          // if (WBGT.some(value => value !== null && !isNaN(value))) {
          // if (WBGT.some(value => value !== null && !isNaN(value))) {
          if (1==1) {
              return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_annot} options={options_annot}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()} */}




      </Row>
    </Col>
      </Row>

      <Contact />
        <br></br>
        <br></br>
        <br></br>
    </Container>
  );
};

export default Linechart;



