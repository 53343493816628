import React, { useRef, useState, useEffect } from 'react';
import "./i18n"; //import this
import { useTranslation } from "react-i18next";

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';


import MapIcon from '@mui/icons-material/Map';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';
import WindPowerRoundedIcon from '@mui/icons-material/WindPowerRounded';
import { WiThermometer, WiThermometerExterior, WiThermometerInternal, WiSandstorm, WiStrongWind, WiHumidity, WiBarometer, WiRaindrop, WiRaindrops, WiDaySunny } from "react-icons/wi";
import { TbWind, TbWindmillFilled, TbWindmill } from "react-icons/tb";
import { FiWind } from "react-icons/fi";
import { GiWindsock,GiHighTide, GiLowTide} from "react-icons/gi";
import { MdOutlineWindPower } from "react-icons/md";
import { RiWaterPercentFill, RiWaterPercentLine } from "react-icons/ri";

import { IoWater, IoWaterOutline, IoThermometerOutline } from "react-icons/io5";
import { MdOutlineWaterDrop, MdWaterDrop } from "react-icons/md";
import { CiPercent } from "react-icons/ci";
import { HiMiniSignal } from "react-icons/hi2";


import { BsBatteryHalf, BsCircleFill, BsWind, BsWater, BsFillDropletFill, BsDropletHalf, BsDroplet, BsPercent, BsThermometerHalf, BsFillRocketTakeoffFill, BsFillSunriseFill, BsFillSunsetFill, BsTsunami } from "react-icons/bs";
import { PiNavigationArrowFill, PiWaveSineBold } from "react-icons/pi";
import { VscCircleFilled } from "react-icons/vsc";


import SwissLayer from "./layers/SwissLayer";


import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';


import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import moment from 'moment';
import 'moment/locale/fr'; // Importez la localisation que vous souhaitez utiliser, par exemple 'fr' pour le français
moment.utc(); // Définit le mode UTC
// moment.locale(); // Active la localisation automatique en utilisant la région par défaut du navigateur



import swissbathy3d from '../image/ch.layer/swissbathy3d.png';
import swissalti3d from '../image/ch.layer/swissalti3d.png';

import patrouilledesglaciers from '../image/ch.layer/patrouilledesglaciers.png';

import icao from '../image/ch.layer/icao.png'; // Ajoutez l'importation pour l'image 'icao'
import segelflugkarte from '../image/ch.layer/segelflugkarte.png'; // Ajoutez l'importation pour l'image 'segelflugkarte'
import swissmilpilotschart from '../image/ch.layer/swissmilpilotschart.png';

import winter from '../image/ch.layer/winter.png'; // Ajoutez l'importation pour l'image 'pixelkarte'
import skitouren from '../image/ch.layer/skitouren.png'; // Ajoutez l'importation pour l'image 'skitouren'
import schneeschuhrouten from '../image/ch.layer/schneeschuhrouten.png'; // Ajoutez l'importation pour l'image 'schneeschuhrouten'
import swisstlm3d from '../image/ch.layer/swisstlm3d.png'; // Ajoutez l'importation pour l'image 'swisstlm3d'
import wanderland from '../image/ch.layer/wanderland.png'; // Ajoutez l'importation pour l'image 'wanderland'
import veloland from '../image/ch.layer/veloland.png'; // Ajoutez l'importation pour l'image 'veloland'
import mountainbikeland from '../image/ch.layer/mountainbikeland.png'; // Ajoutez l'importation pour l'image 'mountainbikeland'
import landeskarte from '../image/ch.layer/landeskarte.png'; // Ajoutez l'importation pour l'image 'mountainbikeland'

// import swissmilpilotschart from '../image/ch.layer/swissmilpilotschart.png';
// import swissmilpilotschart from '../image/ch.layer/swissmilpilotschart.png';


import { BorderColor } from '@mui/icons-material';
import { layouts } from 'chart.js';

import Info from './Component/info';


import topo from '../image/map/topo.png';
import swissimage from '../image/map/image.png';
import outdoor from '../image/map/outdoor.png';
import smooth from '../image/map/smooth.png';
import dark from '../image/map/dark.png';

import topo_style from '../styles/swisstopo-basiskarte.json';
import swissimage_style from '../styles/swisstopo-basiskarte-imagery.json';

import smooth_style from '../styles/alidade_smooth.json';
import dark_style from '../styles/alidade_smooth_dark.json';
import outdoor_style from '../styles/outdoors.json';



// const topo_style = 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json';
// const swissimage_style = 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte-imagery.vt/style.json';

// // .COM
// const smooth_style = 'https://tiles.stadiamaps.com/styles/alidade_smooth.json?api_key=a2a467aa-8dcf-4ee2-939f-6185f11f1876';
// const dark_style = 'https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json?api_key=a2a467aa-8dcf-4ee2-939f-6185f11f1876';
// const outdoor_style = 'https://tiles.stadiamaps.com/styles/outdoors.json?api_key=a2a467aa-8dcf-4ee2-939f-6185f11f1876';
// // 

// // .CH
// const smooth_style = 'https://tiles.stadiamaps.com/styles/alidade_smooth.json?api_key=1c25ff37-7d68-4f3b-88a5-9f818af91bb5';
// const dark_style = 'https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json?api_key=1c25ff37-7d68-4f3b-88a5-9f818af91bb5';
// const outdoor_style = 'https://tiles.stadiamaps.com/styles/outdoors.json?api_key=1c25ff37-7d68-4f3b-88a5-9f818af91bb5';
// // 


var width = 256; // The image will be 64 pixels square
var height = 256; // The image will be 64 pixels square
var bytesPerPixel = 4; // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
var data = new Uint8Array(width * height * bytesPerPixel);

for (var x = 0; x < width; x++) {
    for (var y = 0; y < height; y++) {
        var offset = (y * width + x) * bytesPerPixel;
        if (
            ((x - 128) < y / 3 && x > 0) && // Top bar of the arrow
            ((x - 128) > -y / 3 && x > 0) && // Top bar of the arrow
            ((x - 128) < -y / 0.7 + 240 || x > 128) && // Top bar of the arrow
            ((x - 128) > y / 0.7 - 240 || x < 128) // Top bar of the arrow
        ) {
            // Draw a solid red arrow
            data[offset + 0] = 255; // red
            data[offset + 1] = 100; // green
            data[offset + 2] = 10; // blue
            data[offset + 3] = 255; // alpha
        } else {
            // Draw a transparent pixel
            data[offset + 0] = 0; // red
            data[offset + 1] = 0; // green
            data[offset + 2] = 0; // blue
            data[offset + 3] = 0; // alpha
        }
    }
}




const Map = ({
    loggedInUser,
    centerLongitude,
    centerLatitude,
    zoom,
    pitch,
    bearing,
    exaggeration,
    markerLongitude,
    markerLatitude,
    nom,
    spotname,

    onMarkerClick,
    onMove,

    pointSource,

    changeURL,
    changeMapLayer,
    changeSwissLayer,

    defstyleUrl,
    defMapLayer,
    defselectedSwissLayers,

    M,
    SetM,
    CAV,
    SetCAV,

}) => {
    const { t } = useTranslation();

    

    // const [HighLim, setHighLim] = useState( -CAV + 56.7 - 11.5 * Math.log10(parseInt(M))); 
    // let HighLim =  -CAV + 56.7 - 11.5 * Math.log10(parseInt(M)); 

    const RiskLim = -CAV + 53.5 - 8.9 * Math.log10(parseInt(M)); 
    const HighLim = -CAV + 56.7 - 11.5 * Math.log10(parseInt(M)); 
    const LowLim = -CAV + 59.9 - 14.1 * Math.log10(parseInt(M));
    const ColdLim = 5;

  
    const mapContainerRef = useRef(null);
    const [styleUrl, setStyleUrl] = useState(defstyleUrl);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const styleOptions = [
        
        // Définissez ici les options du menu déroulant (étiquette et valeur du style)
        // { label: 'Topo', value: 'https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/all_week.geojson        ', photo: topo },

        // { label: 'Topo', value: 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json', photo: topo },
        // { label: 'Image', value: 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte-imagery.vt/style.json', photo: swissimage },
        // { label: 'dark', value: 'https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json', photo: dark },
        // { label: 'smooth', value: 'https://tiles.stadiamaps.com/styles/alidade_smooth.json', photo: smooth },
        // { label: 'outdoors', value: 'https://tiles.stadiamaps.com/styles/outdoors.json', photo: outdoor },  
        
        { label: 'Topo', value: topo_style, photo: topo },
        { label: 'Image', value: swissimage_style, photo: swissimage },
        { label: 'dark', value: dark_style, photo: dark },
        { label: 'smooth', value: smooth_style, photo: smooth },
        { label: 'outdoors', value: outdoor_style, photo: outdoor }, 

        // { label: 'outdoors', value: 'https://tiles.stadiamaps.com/styles/outdoors.json', photo: outdoor },        

        // Ajoutez autant d'options que nécessaire
    ];
    const handleStyleOptionClick = (value) => {
        setStyleUrl(value);
        changeURL(value);
        handleClose();
    };


    const SwissLayerIds = [
        
        { 
            label: 'swissbathy3d', 
            value: 'ch.swisstopo.swissbathy3d-reliefschattierung', 
            photo: swissbathy3d,
            opacity: 1,
        },
        
        { 
            label: 'swiss alti 3d', 
            value: 'ch.swisstopo.swissalti3d-reliefschattierung', 
            photo: swissalti3d,
            opacity: 0.6,
        },
        { 
            label: 'Patrouille des Glaciers', 
            value: 'ch.vbs.patrouilledesglaciers-a_rennen', 
            photo: patrouilledesglaciers,
            opacity: 1,
        },

        // { 
        //     label: 'ICAO Luftfahrtkarten', 
        //     value: 'ch.bazl.luftfahrtkarten-icao', 
        //     photo: icao,
        //     opacity: 1,
        // },
        // { 
        //     label: 'Segelflugkarte', 
        //     value: 'ch.bazl.segelflugkarte', 
        //     photo: segelflugkarte,
        //     opacity: 1,
        // },
        { 
            label: 'Swiss Mil Pilots Chart', 
            value: 'ch.vbs.swissmilpilotschart', 
            photo: swissmilpilotschart,
            opacity: 1,
        },

        


        // { 
        //     label: 'Winter', 
        //     value: 'ch.swisstopo.pixelkarte-farbe-winter', 
        //     photo: winter,
        //     opacity: 1,
        // },
        // { 
        //     label: 'Winter', 
        //     value: 'ch.swisstopo-karto.skitouren', 
        //     photo: skitouren,
        //     opacity: 1,
        // },
        { 
            label: 'Winter', 
            value: ['ch.swisstopo-karto.skitouren','ch.swisstopo-karto.schneeschuhrouten'], 
            photo: topo,
            opacity: 1,
        },

        { 
            label: 'Pixelkarte Farbe Winter', 
            value: ['ch.swisstopo.pixelkarte-farbe-winter','ch.swisstopo-karto.skitouren','ch.swisstopo-karto.schneeschuhrouten', 'ch.vbs.patrouilledesglaciers-z_rennen', 'ch.vbs.patrouilledesglaciers-a_rennen'], 
            photo: winter,
            opacity: 1,
        },
        // { 
        //     label: 'Skitouren', 
        //     value: 'ch.swisstopo-karto.skitouren', 
        //     photo: skitouren,
        //     opacity: 1,
        // },
        // { 
        //     label: 'Schneeschuhrouten', 
        //     value: 'ch.swisstopo-karto.schneeschuhrouten', 
        //     photo: schneeschuhrouten,
        //     opacity: 1,
        // },
        
        { 
            label: 'Swisstlm3d Wanderwege', 
            value: 'ch.swisstopo.swisstlm3d-wanderwege', 
            photo: swisstlm3d,
            opacity: 1,
        },
        { 
            label: 'Astra Wanderland', 
            value: 'ch.astra.wanderland', 
            photo: wanderland,
            opacity: 1,
        },
        { 
            label: 'Astra Veloland', 
            value: 'ch.astra.veloland', 
            photo: veloland,
            opacity: 1,
        },
        { 
            label: 'Astra Mountainbikeland', 
            value: 'ch.astra.mountainbikeland', 
            photo: mountainbikeland,
            opacity: 1,
        },

        { 
            label: 'landeskarte', 
            value: 'ch.swisstopo.landeskarte-farbe-10', 
            photo: landeskarte,
            opacity: 1,
        },
        // ... Ajoutez d'autres IDs de couche ici
    ];


    const [selectedSwissLayers, setSelectedSwissLayers] = useState(defselectedSwissLayers);
    
    // const [selectedSwissLayers, setSelectedSwissLayers] = useState(["ch.swisstopo.swissbathy3d-reliefschattierung"]);
    // const [selectedSwissLayers, setSelectedSwissLayers] = useState(['ch.swisstopo.swissbathy3d-reliefschattierung', 'ch.swisstopo.swissalti3d-reliefschattierung']);
    

    const handleSwissLayerClick = (layerId) => {
        setSelectedSwissLayers(prevLayers => {
            if (prevLayers.includes(layerId.label)) {
                return [];
            } else {
                return [layerId.label];
            }
        });
        handleClose();
    }
    





    const [layerVisibility, setLayerVisibility] = useState(defMapLayer);
    // const [SwisslayerVisibility, setSwissLayerVisibility] = useState([]);

    // const handleLayerToggle = (layer) => {
    //     setLayerVisibility((prevVisibility) => ({
    //       ...prevVisibility,
    //       [layer]: !prevVisibility[layer],
    //     }));
    //     handleClose();
    // };
    const handleLayerToggle = (layer) => {
        setLayerVisibility((prevVisibility) => {
          // Créez un nouvel objet pour mettre à jour la visibilité de la couche sélectionnée
          const updatedVisibility = {
            ...prevVisibility,
            [layer]: !prevVisibility[layer],
          };
      
          // Désélectionnez toutes les autres couches
          for (const key in updatedVisibility) {
            if (key !== layer) {
              updatedVisibility[key] = false;
            }
          }
      
          return updatedVisibility;
        });
      
        handleClose();
    };


    // const [textFont, setTextFont] = useState(['Frutiger Neue Condensed Regular']);

      

    // const getLabelLayerProperties = (styleUrl) => {
    //     if (styleUrl.includes('geo.admin')) {
    //       return {
    //         'text-font': ['Frutiger Neue Condensed Regular'],
    //         /* Autres propriétés spécifiques à Swisstopo */
    //       };
    //     }
    //     return {
    //       'text-font': ['Stadia Semibold'], // Ou toute autre valeur par défaut que vous préférez
    //     };
    //   };
      


    //useRef
    const [map, setMap] = useState(null);

    // Définition des propriétés communes pour les couches
    const CircleLayerProperties = {
        type: 'circle',
        source: 'points',
        paint: {
        //'circle-translate': [10, 10],
        "circle-color": "#000000",
        "circle-opacity": 1, //0.2,
        'circle-stroke-color': "#000000",
        'circle-stroke-width': {
            'base': 1,
            'type': 'exponential',
            'stops': [
                [1, 0],
                [4, 0],
                [8, 0.5],
                [25, 3],                
                ]
            },
        "circle-radius": {
            'base': 1,
            'type': 'exponential',
            'stops': [
                [1, 4],
                [8, 12],
                [25, 72],                
                ]
            },
        },
    };
    // Définition des propriétés communes pour les couches
    const LabelLayerProperties = {
        type: 'symbol',
        source: 'points',
        layout: {
        // 'text-field': 'windave',
        // 'text-font': ['Stadia Semibold'],
        // Stadia Regular
        // Stadia Italic
        // Stadia Bold
        // Stadia Semibold
        //'text-font': ['Noto Sans Arabic Light'],
        // 'text-font': ['Swisstopo Standard'],
        'text-font': ['Frutiger Neue Condensed Regular'],
        
        'text-size': {
            'base': 1,
            'type': 'exponential',
            'stops': [
                [1, 0],
                [4, 0],
                [8, 6],
                [25, 36],                
                ]
            },
        }
    };

    const HaloLayerProperties = {
        type: 'circle',
        source: 'points',
        paint: {
          'circle-blur': 1.2, //0.99,
            //   'circle-color': '#11B4FF',//'#11D2DA',//'#1156DA',//'#11b4da',
          'circle-color': '#FFFFFF',//'#11D2DA',//'#1156DA',//'#11b4da',
        //   'circle-opacity': 0.1,
          'circle-opacity': {
            'base': 1,
            'type': 'exponential',
            'stops': [
                [1, 0],
                [8, 0.5],
                [25, 1],                
                ]
            },
            //   'circle-radius': 50,
          'circle-radius': {
            'base': 1,
            'type': 'exponential',
            'stops': [
                // [1, 8],
                // [8, 24],
                // [25, 144], 
                [1, 15],
                [8, 24],
                [25, 144],               
                ]
            },
        //   'circle-stroke-width': 4,
        //   // 'circle-stroke-opacity': 0.8,
        //   'circle-stroke-color': '#fff'
        }
    };

    const HalowindLayer = {
        'id': 'halowind',
        'filter': ['any',
            ['!=', ['get', 'windir'], null],
            ['!=', ['get', 'windave'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.wind ? 'visible' : 'none',
        }
    };



    const HalorssiLayer = {
        'id': 'halorssi',
        'filter': ['any',
            ['!=', ['get', 'rssi'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.rssi ? 'visible' : 'none',
        }
    };
    const HaloBatVLayer = {
        'id': 'haloBatV',
        'filter': ['any',
            ['!=', ['get', 'BatV'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.BatV ? 'visible' : 'none',
        }
    };



    const HalowbgtLayer = {
        'id': 'halowbgt',
        'filter': ['any',
            ['!=', ['get', 'WBGT'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.wbgt ? 'visible' : 'none',
        }
    };

    const HalotempLayer = {
        'id': 'halotemp',
        'filter': ['any',
            ['!=', ['get', 'TA'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.temp ? 'visible' : 'none',
        }
    };
    const HalohumLayer = {
        'id': 'halohum',
        'filter': ['any',
            ['!=', ['get', 'RH'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.hum ? 'visible' : 'none',
        }
    };
    const HalopressLayer = {
        'id': 'halopress',
        'filter': ['any',
            ['!=', ['get', 'QNH'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.press ? 'visible' : 'none',
        }
    };
    const HaloluxLayer = {
        'id': 'halolux',
        'filter': ['any',
            ['!=', ['get', 'Irradiation'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.lux ? 'visible' : 'none',
        }
    };
    const HalowaterLayer = {
        'id': 'halowater',
        'filter': ['any',
            ['!=', ['get', 'WTMP'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.water ? 'visible' : 'none',
        }
    };
    const HalowaveLayer = {
        'id': 'halowave',
        'filter': ['any',
            ['!=', ['get', 'WVHT'], null],
            ['!=', ['get', 'MWD'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.wave ? 'visible' : 'none',
        }
    };
    const HalowaveTLayer = {
        'id': 'halowaveT',
        'filter': ['any',
            ['!=', ['get', 'DPD'], null],
            ['!=', ['get', 'APD'], null],
            ['!=', ['get', 'MWD'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.waveT ? 'visible' : 'none',
        }
    };

    const HalotideLayer = {
        'id': 'halotide',
        'filter': ['any',
            ['!=', ['get', 'TIDE'], null],
        ],
        ...HaloLayerProperties,
        layout: {
            // ...HaloLayerProperties.layout,
            visibility: layerVisibility.tide ? 'visible' : 'none',
        }
    };

    



// // // // // // // // // // // // // // Arrow // // // // // // // // // // // // // // // // // // // // //
    const cercleLayer = {
        ...CircleLayerProperties,
        'id': 'cercle',
        filter: ['!=', ['get', 'windave'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'windave']],
                0,
                'rgb(255, 255, 255)',
                2,
                'rgb(155, 255, 255)',
                8,
                'rgb(102, 247, 241)',
                15,
                'rgb(3, 255, 0)',
                20,
                'rgb(255, 239, 2)',
                25,
                'rgb(255, 51, 44)',
                30,
                'rgb(255, 8, 199)',
                35,
                'rgb(255, 0, 255)',
                40,
                'rgb(150, 50, 255)',
                45,
                'rgb(60, 60, 255)',
                50,
                'rgb(0, 0, 255)',
                55,
                'rgb(0, 0, 0)',
            ],

        },
        layout: {
            visibility: layerVisibility.wind ? 'visible' : 'none',
        }
    };
    const symbolsLayer = {
        'id': 'symbols',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'windir'], null],
        layout: {
            ...LabelLayerProperties.layout,
            // 'text-field': '{RH} %',
            'text-allow-overlap': true,
            'text-field': '',// pointer
            'text-font': ['Material Icons Regular'],
            'text-rotate': ['+', ['to-number', ['get', 'windir']], 180], // windDirection is the variable used for the orientation
            // 'text-anchor':'center',
            // 'text-anchor':'bottom',
            // 'text-anchor':'top',
            'text-offset': [0,0.1],
            'text-size': {
                'base': 1,
                'type': 'exponential',
                'stops': [
                    [1, 0],
                    [2, 0],
                    [4, 12],
                    [8, 18],
                    [25, 96],            
                    ]
                },
            visibility: layerVisibility.wind ? 'visible' : 'none',
        }
    };    

    // const symbolsLayer = {
    //     'id': 'symbols',
    //     'type': 'symbol',
    //     'source': 'points',
    //     filter: ['!=', ['get', 'windave'], null],
    //     'paint': {
    //         "icon-opacity": 0.9, //0.2,
    //         'icon-color': "#000000",
    //     },
    //     layout: {
    //         'icon-image': 'arrow',
    //         'icon-rotate': ['+', ['to-number', ['get', 'windir']], 180], // windDirection is the variable used for the orientation
    //         'icon-allow-overlap': true,

    //         'icon-size': {
    //             'base': 1,
    //             'type': 'exponential',
    //             'stops': [
    //                 [2, 0],
    //                 [4, 0.0375],
    //                 [8, 0.075],
    //                 [25, 0.45]
    //             ]
    //         },
    //         visibility: layerVisibility.wind ? 'visible' : 'none',
    //     },    
    // };
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // 


    const rssiLayer = {
        'id': 'rssi',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'rssi'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'rssi']],
                0, 'rgb(0, 0, 255)',   // Bleu foncé
                4, 'rgb(148, 0, 211)', // Violet
                8, 'rgb(255, 0, 0)',   // Rouge
                12, 'rgb(255, 165, 0)', // Orange
                16, 'rgb(255, 255, 0)', // Jaune
                25, 'rgb(0, 255, 0)',   // Vert
            ],

        },
        layout: {
            visibility: layerVisibility.rssi ? 'visible' : 'none',
        }
    };

    const rssiLayerLab = {
        'id': 'rssiLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'rssi'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{rssi}',
            // 'text-field': ['format', ['get', 'rssi'], { 'min-fraction-digits': 0, 'max-fraction-digits': 0 }],
            visibility: layerVisibility.rssi ? 'visible' : 'none',
        }
    };

    const BatVLayer = {
        'id': 'BatV',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'BatV'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'BatV']],
                2, 'rgb(0, 0, 255)',    // Bleu foncé
                2.4, 'rgb(148, 0, 211)', // Violet
                2.7, 'rgb(255, 0, 0)',   // Rouge
                3, 'rgb(255, 165, 0)', // Orange
                3.3, 'rgb(255, 255, 0)', // Jaune
                3.6, 'rgb(0, 255, 0)',   // Vert
            ],
            // 'circle-color': [
            //     'case',
            //         ['>', ['to-number', ['get', 'BatV']], 3.6], 'rgb(0, 150, 0)',  // Blanc si BatV < 0

            //         ['interpolate', ['linear'], ['to-number', ['get', 'BatV']],
            //             3.6, 'rgb(0, 255, 0)',   // Vert
            //             3.3, 'rgb(255, 255, 0)', // Jaune
            //             3, 'rgb(255, 165, 0)', // Orange
            //             2.7, 'rgb(255, 0, 0)',   // Rouge
            //             2.4, 'rgb(148, 0, 211)', // Violet
            //             2, 'rgb(0, 0, 255)'    // Bleu foncé
            //         ]
            // ],
            
        },
        layout: {
            visibility: layerVisibility.BatV ? 'visible' : 'none',
        }
    };
    const BatVLayerLab = {
        'id': 'BatVLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'BatV'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{BatV} V',
            // 'text-field': ['format', ['get', 'BatV'], { 'min-fraction-digits': 0, 'max-fraction-digits': 0 }],
            visibility: layerVisibility.BatV ? 'visible' : 'none',
        }
    };


    const wbgtLayer = {
        'id': 'wbgt',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'WBGT'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'case',
                ['>=', ['to-number', ['get', 'WBGT']], RiskLim],
                'rgb(255, 0, 0)', // Rouge si WBGT >= RiskLim
                ['all',
                    ['<', ['to-number', ['get', 'WBGT']], RiskLim],
                    ['>=', ['to-number', ['get', 'WBGT']], HighLim]
                ],
                'rgb(255, 150, 0)', // Orange si HighLim <= WBGT < RiskLim
                ['all',
                    ['<', ['to-number', ['get', 'WBGT']], HighLim],
                    ['>=', ['to-number', ['get', 'WBGT']], LowLim]
                ],
                'rgb(255, 225, 0)', // Jaune si LowLim <= WBGT < HighLim
                ['<', ['to-number', ['get', 'WBGT']], LowLim],
                'rgb(0, 255, 0)', // Vert si WBGT < LowLim
                'rgb(0, 0, 0)' // Autre (noir par exemple)
            ],
        },
        layout: {
            visibility: layerVisibility.wbgt ? 'visible' : 'none',
        }
    };

    const wbgtLayerLab = {
        'id': 'wbgtLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'WBGT'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{WBGT}°C',
            visibility: layerVisibility.wbgt ? 'visible' : 'none',
        }
    };



    const tempLayer = {
        'id': 'temp',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'TA'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'case',
                    ['<', ['to-number', ['get', 'TA']], 0], 'rgb(255, 255, 255)',  // Blanc si TA < 0

                    ['all', 
                        ['>=', ['to-number', ['get', 'TA']], 0], 
                        ['<=', ['to-number', ['get', 'TA']], ColdLim]
                    ],
                    'rgb(125, 255, 255)',  // Bleu clair si 0 <= TA <= ColdLim

                    ['interpolate', ['linear'], ['to-number', ['get', 'TA']],
                        ColdLim, 'rgb(0, 255, 0)',   // Vert
                        25, 'rgb(255, 255, 0)', // Jaune
                        30, 'rgb(255, 165, 0)', // Orange
                        35, 'rgb(255, 0, 0)',   // Rouge
                        40, 'rgb(148, 0, 211)', // Violet
                        50, 'rgb(0, 0, 255)'    // Bleu foncé
                    ]
            ],
            

            // 'circle-color': [
            //     'interpolate',
            //     ['linear'],
            //     ['to-number', ['get', 'TA']],
            //     -50, 'rgb(50, 0, 60)',
            //     -25, 'rgb(0, 0, 60)',
            //     -10, 'rgb(0, 0, 255)',
            //     -5, 'rgb(0, 122, 255)',
            //     0, 'rgb(0, 255, 255)',

            //     5, 'rgb(100, 255, 190)',
            //     10, 'rgb(190, 255, 100)',

            //     // 15, 'rgb(255, 255, 0)',

            //     20, 'rgb(0, 255, 0)',

            //     25, 'rgb(255, 255, 0)',

            //     30, 'rgb(255, 122, 0)',

            //     35, 'rgb(255, 0, 0)',
            //     40, 'rgb(255, 0, 255)',
            //     45, 'rgb(125, 0, 255)',
            //     55, 'rgb(40, 0, 0)',
            // ],
        },
        layout: {
            visibility: layerVisibility.temp ? 'visible' : 'none',
        }
    };
    const tempLayerLab = {
        'id': 'tempLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'TA'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{TA}°C',
            // 'text-field': ['format', ['get', 'TA'], { 'min-fraction-digits': 0, 'max-fraction-digits': 0 }],
            visibility: layerVisibility.temp ? 'visible' : 'none',
        }
    };

    const humLayer = {
        'id': 'hum',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'RH'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'RH']],
                0,
                'rgb(255, 255, 255)',
                100,
                'rgb(0, 0, 255)'
            ],   
        },
        layout: {
            // visibility: 'visible',
            visibility: layerVisibility.hum ? 'visible' : 'none',
        },
    };
    const humLayerLab = {
        'id': 'humLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'RH'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{RH} %',
            // 'text-field': '',// pointer
            // 'text-anchor':'center',
            // 'text-anchor':'bottom',
            // 'text-anchor':'top',
            visibility: layerVisibility.hum ? 'visible' : 'none',
        }
    };

    const pressLayer = {
        'id': 'press',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'QNH'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'QNH']],
                986, 'rgb(100, 0, 155)',
                1005, 'rgb(0, 0, 255)',
                1018, 'rgb(0, 255, 0)',
                1028, 'rgb(255, 0, 0)',
                1037, 'rgb(155, 0, 100)',

            ],   
        },
        layout: {
            // visibility: 'visible',
            visibility: layerVisibility.press ? 'visible' : 'none',
        },
    };
    const pressLayerLab = {
        'id': 'pressLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'QNH'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{QNH} hPa',
            visibility: layerVisibility.press ? 'visible' : 'none',
        }
    };

    const luxLayer = {
        'id': 'lux',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'Irradiation'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'Irradiation']],
                0, 'rgb(0, 0, 155)',
                10000, 'rgb(255, 255, 0)',

            ],   
        },
        layout: {
            // visibility: 'visible',
            visibility: layerVisibility.lux ? 'visible' : 'none',
        },
    };
    const luxLayerLab = {
        'id': 'luxLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'Irradiation'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{Irradiation} W/m²',
            visibility: layerVisibility.lux ? 'visible' : 'none',
        }
    };

    const eauLayer = {
        'id': 'eau',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'WTMP'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'WTMP']],
                0, 'rgb(0, 255, 255)',
                10, 'rgb(0, 0, 255)',
                20, 'rgb(0, 255, 0)',
                30, 'rgb(255, 0, 0)',
                40, 'rgb(155, 0, 100)',

            ],   
        },
        layout: {
            // visibility: 'visible',
            visibility: layerVisibility.water ? 'visible' : 'none',
        },
    };
    const eauLayerLab = {
        'id': 'eauLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'WTMP'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{WTMP} °C',
            visibility: layerVisibility.water ? 'visible' : 'none',
        }
    };

    const waveLayer = {
        'id': 'wave',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'WVHT'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'WVHT']],
                0, 'rgb(255, 255, 255)',
                0.25, 'rgb(155, 255, 255)',
                0.5, 'rgb(102, 247, 241)',
                1, 'rgb(3, 255, 0)',
                1.5, 'rgb(255, 239, 2)',
                2, 'rgb(255, 51, 44)',
                3, 'rgb(255, 8, 199)',
                4, 'rgb(255, 0, 255)',
                5, 'rgb(150, 50, 255)',
                7, 'rgb(60, 60, 255)',
                10, 'rgb(0, 0, 255)',
                15, 'rgb(0, 0, 0)',
            ],   
        },
        layout: {
            // visibility: 'visible',
            visibility: layerVisibility.wave ? 'visible' : 'none',
        },
    };

    const waveLayerLab = {
        'id': 'waveLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'MWD'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-allow-overlap': true,
            'text-field': '',
            // 'text-field': '',// pointer
            'text-font': ['Material Icons Regular'],
            'text-rotate': ['+', ['to-number', ['get', 'MWD']], 180], // windDirection is the variable used for the orientation
            // 'text-anchor':'center',
            // 'text-anchor':'bottom',
            // 'text-anchor':'top',
            'text-offset': [0,0.1],
            'text-size': {
                'base': 1,
                'type': 'exponential',
                'stops': [
                    [1, 0],
                    [2, 0],
                    [4, 12],
                    [8, 18],
                    [25, 96],                
                    ]
                },
            visibility: layerVisibility.wave ? 'visible' : 'none',
        }
    };



    const waveTLayer = {
        'id': 'waveT',
        ...CircleLayerProperties,
        filter: ['any',
            ['!=', ['get', 'DPD'], null],
            ['!=', ['get', 'APD'], null],
        ],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'DPD']],
                0, 'rgb(255, 255, 255)',
                2, 'rgb(155, 255, 255)',
                3, 'rgb(102, 247, 241)',
                5, 'rgb(3, 255, 0)',
                6, 'rgb(255, 239, 2)',
                8, 'rgb(255, 51, 44)',
                10, 'rgb(255, 8, 199)',
                13, 'rgb(255, 0, 255)',
                17, 'rgb(150, 50, 255)',
                20, 'rgb(60, 60, 255)',
                25, 'rgb(0, 0, 255)',
                30, 'rgb(0, 0, 0)',
            ], 
            'circle-stroke-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'APD']],
                0, 'rgb(255, 255, 255)',
                2, 'rgb(155, 255, 255)',
                3, 'rgb(102, 247, 241)',
                5, 'rgb(3, 255, 0)',
                6, 'rgb(255, 239, 2)',
                8, 'rgb(255, 51, 44)',
                10, 'rgb(255, 8, 199)',
                13, 'rgb(255, 0, 255)',
                17, 'rgb(150, 50, 255)',
                20, 'rgb(60, 60, 255)',
                25, 'rgb(0, 0, 255)',
                30, 'rgb(0, 0, 0)',
            ],
            'circle-stroke-width': {
                'base': 1,
                'type': 'exponential',
                'stops': [
                    // autre stroke
                    // [1, 0],
                    // [4, 0],
                    // [8, 0.5],
                    // [25, 3],

                    // radius
                    // [1, 4],
                    // [8, 12],
                    // [25, 72],
                    [1, 1],
                    [8, 4],
                    [25, 18],              
                    ]
                }, 
        },
        layout: {
            // visibility: 'visible',
            visibility: layerVisibility.waveT ? 'visible' : 'none',
        },
    };

    const waveTLayerLab = {
        'id': 'waveTLab',
        ...LabelLayerProperties,
        filter: ['any',
            // ['!=', ['get', 'DPD'], null],
            // ['!=', ['get', 'APD'], null],
            ['!=', ['get', 'MWD'], null],
        ],
        layout: {
            ...LabelLayerProperties.layout,
            'text-allow-overlap': true,
            'text-field': '',
            // 'text-field': '',// pointer
            'text-font': ['Material Icons Regular'],
            'text-rotate': ['+', ['to-number', ['get', 'MWD']], 180], // windDirection is the variable used for the orientation
            // 'text-anchor':'center',
            // 'text-anchor':'bottom',
            // 'text-anchor':'top',
            'text-offset': [0,0.1],
            'text-size': {
                'base': 1,
                'type': 'exponential',
                'stops': [
                    [1, 0],
                    [2, 0],
                    [4, 12],
                    [8, 18],
                    [25, 96],                
                    ]
                },
            visibility: layerVisibility.waveT ? 'visible' : 'none',
        }
    };


    const tideLayer = {
        'id': 'tide',
        ...CircleLayerProperties,
        filter: ['!=', ['get', 'TIDE'], null],
        'paint': {
            ...CircleLayerProperties.paint,
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'TIDE']],
                -800, 'rgb(100, 0, 155)',
                -200, 'rgb(0, 0, 255)',
                0, 'rgb(0, 255, 0)',
                200, 'rgb(255, 0, 0)',
                800, 'rgb(155, 0, 100)',
            ],   
        },
        layout: {
            // visibility: 'visible',
            visibility: layerVisibility.tide ? 'visible' : 'none',
        },
    };

    const tideLayerLab = {
        'id': 'tideLab',
        ...LabelLayerProperties,
        filter: ['!=', ['get', 'TIDE'], null],
        layout: {
            ...LabelLayerProperties.layout,
            'text-field': '{TIDE} cm',
            visibility: layerVisibility.tide ? 'visible' : 'none',
        }
    };
    // wave
    // const RainLayer = {
    //     'id': 'hum',
    //     ...CircleLayerProperties,
    //     filter: ['!=', ['get', 'QNH'], null],
    //     'paint': {
    //         ...CircleLayerProperties.paint,
    //         'circle-color': [
    //             'interpolate',
    //             ['linear'],
    //             ['to-number', ['get', 'QNH']],
    //             913,
    //             'rgb(0, 255, 25)',
    //             1013,
    //             'rgb(0, 0, 25)',
    //             1113,
    //             'rgb(255, 0, 25)',

    //         ],   
    //     },
    //     layout: {
    //         // visibility: 'visible',
    //         visibility: layerVisibility.press ? 'visible' : 'none',
    //     },
    // };
    



    const layersToUpdate = [
        {
            name: 'halowind',
            layer: HalowindLayer,
            visibilityKey: 'wind',
        },
        {
            name: 'halorssi',
            layer: HalorssiLayer,
            visibilityKey: 'rssi',
        },
        {
            name: 'haloBatV',
            layer: HaloBatVLayer,
            visibilityKey: 'BatV',
        },
        {
            name: 'halowbgt',
            layer: HalowbgtLayer,
            visibilityKey: 'wbgt',
        },
        {
            name: 'halotemp',
            layer: HalotempLayer,
            visibilityKey: 'temp',
        },
        {
            name: 'halohum',
            layer: HalohumLayer,
            visibilityKey: 'hum',
        },
        {
            name: 'halopress',
            layer: HalopressLayer,
            visibilityKey: 'press',
        },
        {
            name: 'halolux',
            layer: HaloluxLayer,
            visibilityKey: 'lux',
        },
        {
            name: 'halowater',
            layer: HalowaterLayer,
            visibilityKey: 'water',
        },
        {
            name: 'halowave',
            layer: HalowaveLayer,
            visibilityKey: 'wave',
        },
        {
            name: 'halowaveT',
            layer: HalowaveTLayer,
            visibilityKey: 'waveT',
        },
        {
            name: 'halotide',
            layer: HalotideLayer,
            visibilityKey: 'tide',
        },
        {
          name: 'cercle',
          layer: cercleLayer,
          visibilityKey: 'wind',
        },
        {
          name: 'symbols',
          layer: symbolsLayer,
          visibilityKey: 'wind',
        },
        {
            name: 'rssi',
            layer: rssiLayer,
            visibilityKey: 'rssi',
        },
        {
            name: 'rssiLab',
            layer: rssiLayerLab,
            visibilityKey: 'rssi',
        },
        {
            name: 'BatV',
            layer: BatVLayer,
            visibilityKey: 'BatV',
        },
        {
            name: 'BatVLab',
            layer: BatVLayerLab,
            visibilityKey: 'BatV',
        },
        {
            name: 'wbgt',
            layer: wbgtLayer,
            visibilityKey: 'wbgt',
        },
        {
            name: 'wbgtLab',
            layer: wbgtLayerLab,
            visibilityKey: 'wbgt',
        },
        {
            name: 'temp',
            layer: tempLayer,
            visibilityKey: 'temp',
        },
        {
            name: 'tempLab',
            layer: tempLayerLab,
            visibilityKey: 'temp',
        },
        {
            name: 'hum',
            layer: humLayer,
            visibilityKey: 'hum',
        },
        {
            name: 'humLab',
            layer: humLayerLab,
            visibilityKey: 'hum',
        },
        {
            name: 'press',
            layer: pressLayer,
            visibilityKey: 'press',
        },
        {
            name: 'pressLab',
            layer: pressLayerLab,
            visibilityKey: 'press',
        },
        {
            name: 'lux',
            layer: luxLayer,
            visibilityKey: 'lux',
        },
        {
            name: 'luxLab',
            layer: luxLayerLab,
            visibilityKey: 'lux',
        },
        {
            name: 'eau',
            layer: eauLayer,
            visibilityKey: 'water',
        },
        {
            name: 'eauLab',
            layer: eauLayerLab,
            visibilityKey: 'water',
        },

        {
            name: 'wave',
            layer: waveLayer,
            visibilityKey: 'wave',
        },
        {
            name: 'waveLab',
            layer: waveLayerLab,
            visibilityKey: 'wave',
        },
        {
            name: 'waveT',
            layer: waveTLayer,
            visibilityKey: 'waveT',
        },
        {
            name: 'waveTLab',
            layer: waveTLayerLab,
            visibilityKey: 'waveT',
        },
        {
            name: 'tide',
            layer: tideLayer,
            visibilityKey: 'tide',
        },
        {
            name: 'tideLab',
            layer: tideLayerLab,
            visibilityKey: 'tide',
        },
          
        // Ajoutez d'autres objets pour les autres couches avec leurs IDs respectifs
      ];




    




    useEffect(() => {
        if (map) {

                map.getSource('points').setData(pointSource.data);
                // console.log('point update')

        }
    }, [pointSource]);




    useEffect(() => {

        console.log('newMap')

        // console.log('newmap')
        // Initialize the map with the initial values
        const newMap = new maplibregl.Map({
            container: mapContainerRef.current,
            style: styleUrl,
            center: [centerLongitude, centerLatitude],
            zoom: zoom,
            pitch: pitch,
            bearing: bearing,
            exaggeration: 8,
        });
          
        // Set the map state
        setMap(newMap);
        

        newMap.addControl(new maplibregl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }));

      
        // Add the symbols layer with the initial source
        newMap.on('style.load', function() {
            


            // https://www.swisstopo.admin.ch/fr/geodata.html

            // const layerId = "ch.swisstopo.swissbathy3d-reliefschattierung";
            // const layerId = "ch.swisstopo.swissalti3d-reliefschattierung_monodirektional";
            // const layerId = "ch.swisstopo.swissalti3d-reliefschattierung";
            // const layerId = "ch.swisstopo.pixelkarte-farbe";
            // const layerId = "ch.swisstopo.swissimage";

            // const layerId = "ch.swisstopo.swissimage-product_1946";
            // const layerId = "ch.swisstopo.swisssurface3d-reliefschattierung-multidirektional";
            // const layerId = "ch.swisstopo.swisssurface3d-reliefschattierung_monodirektional";



            // const layerId = "ch.bafu.hydrologie-wassertemperaturmessstationen";
            // const layerId = "ch.bafu.nabelstationen";
            // const layerId = "ch.bafu.bundesinventare-bln";
            // const layerId = "ch.bafu.bundesinventare-bln";
            // const layerId = "ch.swisstopo.relief.vt";
            // ch.swisstopo.geologie-geologische_3dmodelle";
            // const layerId = "ch.swisstopo.pixelkarte-farbe-winter";
            // const layerId = "ch.swisstopo.landeskarte-farbe-10";
            
            

            // url: `https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg`
            // const layerId = "ch.vbs.swissmilpilotschart";
            // const layerId = "ch.vbs.patrouilledesglaciers-a_rennen";
            // const layerId = "ch.swisstopo.swisstlm3d-wanderwege";
            
            // const layerId = "ch.meteoschweiz.messwerte-sonnenscheindauer-kumuliert-10min";
            // const layerId = "ch.bafu.gewaesserschutz-badewasserqualitaet";

            // const layerId = "ch.swisstopo-karto.schneeschuhrouten";
            // const layerId = "ch.swisstopo-karto.skitouren";
            // const layerId = "ch.astra.skatingland";
            // const layerId = "ch.astra.wanderland";
            // const layerId = "ch.astra.veloland";
            // const layerId = "ch.astra.mountainbikeland";
            // const layerId = "ch.vbs.grunddispositiv-zeus";

            // const layerId = "ch.swisstopo.landeskarte-farbe-10";

            // const layerId = "ch.swisstopo-karto.hangneigung";
            // const layerId = "ch.swisstopo.hangneigung-ueber_30";
            
            // const layerId = "ch.swisstopo.geologie-gletschermaechtigkeit";
            // const layerId = "ch.swisstopo.geologie-gletscherausdehnung";
            // const layerId = "ch.swisstopo.geologie-eiszeit-lgm-raster";

            // const layerId = "ch.bazl.luftfahrtkarten-icao";
            // const layerId = "ch.bazl.segelflugkarte";
            // const layerId = "ch.bfs.arealstatistik-1997";
            
            // const layerId = "ch.swisstopo.vec200-miscellaneous";
            // const layerId = "ch.swisstopo.lubis-luftbilder_farbe";
            // const layerId = "ch.swisstopo.pixelkarte-farbe-winter";

            // newMap.addLayer({
            //     id: "swissbathy3d",
            //     type: "raster",
            //     source: {
            //       type: "raster",
            //       tiles: [
            //         // `https://wms0.geo.admin.ch/?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=${layerId}&LANG=en`
            //         `https://wms.geo.admin.ch?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=${layerId}`
            //       ],
            //       tileSize: 256
            //     },
            //     paint: {
            //         "raster-opacity":0.5,
            //     }
            //   });

              



            newMap.addSource('points', pointSource);
            newMap.addImage('arrow', { width: width, height: height, data: data }, { "sdf": "true" });

            


            // newMap.addSource('swissbathy3d-source', {
            //     'type': 'raster',
            //     'tiles': [
            //         'https://wms0.geo.admin.ch/?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=ch.swisstopo.swissbathy3d-reliefschattierung&LANG=en'
            //         // 'https://wms0.geo.admin.ch/?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=ch.swisstopo.swissbathy3d-reliefschattierung&LANG=en'
            //     ],
            //     'tileSize': 256
            // });
            // newMap.addLayer(
            //     {
            //         'id': 'swissbathy3d-layer',
            //         'type': 'raster',
            //         'source': 'swissbathy3d-source',
            //         'paint': {}
            //     },
            //     'aeroway_fill'
            // );
            
            // newMap.addLayer(symbolsLayer);
            // newMap.setLayoutProperty('cercle', 'visibility', layerVisibility.wind ? 'visible' : 'none');
            // newMap.setLayoutProperty('symbols', 'visibility', layerVisibility.wind ? 'visible' : 'none');

            // newMap.addLayer(tempLayer);
            // // newMap.addLayer(tempLayerLab);
            // // newMap.setLayoutProperty('temp', 'visibility', layerVisibility.temp ? 'visible' : 'none');
            // // newMap.setLayoutProperty('tempLab', 'visibility', layerVisibility.temp ? 'visible' : 'none');

            // newMap.addLayer(humLayer);
            // // newMap.addLayer(humLayerLab);
            // newMap.setLayoutProperty('hum', 'visibility', layerVisibility.hum ? 'visible' : 'none');
            // // newMap.setLayoutProperty('humLab', 'visibility', layerVisibility.hum ? 'visible' : 'none');

            // newMap.addLayer(pressLayer);
            // // newMap.addLayer(pressLayerLab);
            // newMap.setLayoutProperty('press', 'visibility', layerVisibility.press ? 'visible' : 'none');
            // // newMap.setLayoutProperty('pressLab', 'visibility', layerVisibility.press ? 'visible' : 'none');

            // newMap.addLayer(luxLayer);
            // // newMap.addLayer(luxLayerLab);
            // newMap.setLayoutProperty('lux', 'visibility', layerVisibility.lux ? 'visible' : 'none');
            // // newMap.setLayoutProperty('luxLab', 'visibility', layerVisibility.lux ? 'visible' : 'none');

            // newMap.addLayer(eauLayer);
            // // newMap.addLayer(eauLayerLab);
            // newMap.setLayoutProperty('eau', 'visibility', layerVisibility.water ? 'visible' : 'none');
            // // newMap.setLayoutProperty('eauLab', 'visibility', layerVisibility.water ? 'visible' : 'none');


            SwissLayerIds.forEach(layer => {
                const visibility = selectedSwissLayers.includes(layer.label) ? 'visible' : 'none';
                // newMap.setLayoutProperty(layer.label, 'visibility', visibility);
                newMap.addLayer({
                    id: layer.label,
                    type: "raster",
                    source: {
                      type: "raster",
                      tiles: [
                        // `https://wms0.geo.admin.ch/?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=${layerId}&LANG=en`
                        `https://wms.geo.admin.ch?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=${layer.value}`
                      ],
                      tileSize: 256
                    },
                    paint: {
                        "raster-opacity":layer.opacity,
                    },
                  });
                newMap.setLayoutProperty(layer.label, 'visibility', visibility);
            });




            
            // newMap.addLayer(cercleLayer);


            layersToUpdate.forEach((layerData) => {
                const { name, layer, visibilityKey } = layerData;
                const updatedLayer = {
                  ...layer,
                  layout: {
                    ...layer.layout,
                    visibility: layerVisibility[visibilityKey] ? 'visible' : 'none',
                    // ...getLabelLayerProperties(styleUrl), // Obtenir les propriétés de la couche de texte
                    },
                };
                console.log('updatedLayer.layout.text-font'); // Vérifiez ici si updatedLayer.layout est correctement défini
                console.log(updatedLayer.layout['text-font']); // Vérifiez ici si updatedLayer.layout est correctement défini
                
                // newMap.setLayoutProperty(name, 'text-font', updatedLayer.layout['text-font']);
                newMap.addLayer(layer);
                newMap.setLayoutProperty(name, 'visibility', updatedLayer.layout.visibility);
                // newMap.setLayout(name, updatedLayer.layout); // Utilisez map.setLayout pour définir toutes les propriétés de mise en page



                newMap.on('click', name, function (e) {
                    onMarkerClick(e.features[0].properties.spot_id, e.features[0].geometry.coordinates[0], e.features[0].geometry.coordinates[1], newMap.getZoom(), styleUrl);
                    newMap.flyTo({
                        center: e.features[0].geometry.coordinates
                    });
                });

                // Change the cursor to a pointer when it enters a feature in the 'symbols' layer.
                newMap.on('mouseenter', name, function (e) {
                    newMap.getCanvas().style.cursor = 'pointer';
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    // WVHT - Hauteur significative des vagues (en mètres)
                    // DPD - Période de vague dominante (en secondes)
                    // APD - Période moyenne de vague (en secondes)
                    // MWD - Direction moyenne des vagues (en degrés)
                    const {received_at, spot_id, rssi, BatV, windave, Wind_3sgust, WBGT, TA, RH, QNH, Irradiation, dewpoint, spotname, WVHT, DPD, APD, MWD, WTMP, TIDE} = e.features[0].properties;
                    // Remove any existing popup before creating a new one
                    popup.remove();

                    // let popupContent = `<strong>${spotname}</strong> ${moment.utc(received_at, 'YYYY-MM-DD, hh:mm').fromNow()}`;
                    
                    // if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(received_at)) {
                    //     received_at = received_at + ':00';
                    //   }
                    
                    let popupContent = `<strong>${spotname}</strong> ${moment.utc(received_at, 'YYYY-MM-DD, hh:mm:ss').fromNow()}`;

                    if (windave !== undefined) {
                        popupContent += `<br>Vent moyen: <strong>${windave}</strong> kts`;
                    }

                    if (Wind_3sgust !== undefined) {
                        popupContent += `<br>Rafale: <strong>${Wind_3sgust}</strong> kts`;
                    }

                    // if (rssi !== undefined) {
                    //     popupContent += `<br>rssi: <strong>${rssi}</strong> 0-31`;
                    // }
                    // if (BatV !== undefined) {
                    //     popupContent += `<br>BatV: <strong>${BatV}</strong> V`;
                    // }

                    if (WBGT !== undefined) {
                        popupContent += `<br>WBGT: <strong>${WBGT}</strong> °C`;
                    }

                    if (TA !== undefined) {
                        popupContent += `<br>`+t("Map.button.Temperature")+`: <strong>${TA}</strong> °C`;
                    }

                    if (RH !== undefined) {
                        popupContent += `<br>`+t("Map.button.Hum")+`: <strong>${RH}</strong> %`;
                    }

                    if ( QNH!== undefined) {
                        popupContent += `<br>Pression: <strong>${QNH}</strong> hPa`;
                    }
                    
                    if ( Irradiation!== undefined) {
                        popupContent += `<br>Ensoleillement: <strong>${Irradiation}</strong> W/m²`;
                    }

                    if ( dewpoint!== undefined) {
                        popupContent += `<br>Point de rosée: <strong>${dewpoint}</strong> °C`;
                    }

                    // if ( MWD!== undefined && MWD!==null) {
                    //     popupContent += `<br>Vages direction: <strong>${MWD}</strong> °`;
                    // }
                    if ( WTMP!== undefined) {// WTMP - Température de l'eau (en degrés)
                        popupContent += `<br>Température de l'eau: <strong>${WTMP}</strong> °C`;
                    }

                    if ( WVHT!== undefined) {// WVHT - Hauteur significative des vagues (en mètres)
                        popupContent += `<br>Hauteur significative des vagues: <strong>${WVHT}</strong> m`;
                    }
                    if ( DPD!== undefined) {// DPD - Période de vague dominante (en secondes)
                        popupContent += `<br>Période de vague dominante: <strong>${DPD}</strong> s`;
                    }
                    if ( APD!== undefined) {// APD - Période moyenne de vague (en secondes)
                        popupContent += `<br>Période moyenne de vague: <strong>${APD}</strong> s`;
                    }
                    if ( MWD!== undefined) {// MWD - Direction moyenne des vagues (en degrés)
                        popupContent += `<br>Direction moyenne des vagues: <strong>${MWD}</strong> °`;
                    }

                    if ( TIDE!== undefined) {// TIDE - Niveau de la marée (en cm)
                        popupContent += `<br>Niveau de la marée: <strong>${TIDE}</strong> cm`;
                    }
                    


                    // Utilisez popupContent dans la méthode setHTML
                    popup.setHTML(popupContent);
                    // Create a new popup and store it in the variable popup
                    popup.setLngLat(coordinates)
                        // .setHTML(`<strong>${spot_id}</strong><br>${spotname}<br>Wind Average: ${windave} kts`)
                        .addTo(newMap);
                    });

                    // Change it back to a pointer when it leaves.
                    newMap.on('mouseleave', name, function () {
                    newMap.getCanvas().style.cursor = '';
                        popup.remove();
                    });



                });


                

                
  
                
                // Create a popup, but don't add it to the map yet.
                const popup = new maplibregl.Popup({
                    closeButton: false,
                    closeOnClick: false
                });

                newMap.on('move', function () {
                    onMove(
                        newMap.getCenter().lng,
                        newMap.getCenter().lat,
                        newMap.getZoom()
                        );
                });


            });
        
    }, []);


    // useEffect(() => {
    //     changeMapLayer(layerVisibility);
    // }, [layerVisibility]);
    useEffect(() => {
        changeMapLayer(layerVisibility);
        
        if (map) {

            layersToUpdate.forEach((layerData) => {
                const { name, layer, visibilityKey } = layerData;
                const updatedLayer = {
                  ...layer,
                  layout: {
                    ...layer.layout,
                    visibility: layerVisibility[visibilityKey] ? 'visible' : 'none',
                    // ...getLabelLayerProperties(styleUrl), // Obtenir les propriétés de la couche de texte
                    },
                };
                console.log('updatedLayer.layout'); // Vérifiez ici si updatedLayer.layout est correctement défini
                console.log(updatedLayer.layout); // Vérifiez ici si updatedLayer.layout est correctement défini
                
            
                map.setLayoutProperty(name, 'visibility', updatedLayer.layout.visibility);
                // map.setLayout(name, updatedLayer.layout); // Utilisez map.setLayout pour définir toutes les propriétés de mise en page
                // map.setLayoutProperty(name, 'text-font', updatedLayer.layout['text-font']);
            });
        }
    }, [layerVisibility]);



    // useEffect(() => {
    //     if (map) {
    //         SwissLayerIds.forEach(layer => {
    //             const visibility = selectedSwissLayers.includes(layer.label) ? 'visible' : 'none';
    //             map.setLayoutProperty(layer.label, 'visibility', visibility);

    //     //   .forEach((layerData) => {
    //     //     const { name, layer, visibilityKey } = layerData;
    //     //     const updatedLayer = {
    //     //       ...layer,
    //     //       layout: {
    //     //         ...layer.layout,
    //     //         visibility: layerVisibility[visibilityKey] ? 'visible' : 'none',
    //     //         // ...getLabelLayerProperties(styleUrl), // Obtenir les propriétés de la couche de texte
    //     //         },
    //     //     };
    //     //   .forEach(layer => {
    //     //     const visibility = selectedSwissLayers.includes(layer.label) ? 'visible' : 'none';
    //     //     map.setLayoutProperty(layer.value, 'visibility', visibility);
    //       });
    //     }
    // }, [selectedSwissLayers]);

    useEffect(() => {
        if (map) {
            SwissLayerIds.forEach(layer => {
                const visibility = selectedSwissLayers.includes(layer.label) ? 'visible' : 'none';
                console.log('selectedSwissLayers')
                console.log(selectedSwissLayers)
                console.log('visibility')
                console.log(visibility)
                console.log('layer')
                console.log(layer)
                map.setLayoutProperty(layer.label, 'visibility', visibility);
            });
            changeSwissLayer(selectedSwissLayers);
        }
    }, [selectedSwissLayers]);
    
      







 
    

    useEffect(() => {

        // if (styleUrl.includes('geo.admin')) {
        //     setTextFont(['Frutiger Neue Condensed Regular']);
        //   }
        // else {
        //     setTextFont(['Stadia Semibold']);
        //   }

        if (map) {
            console.log('map')

            // Update the map when the styleUrl changes
            map.setStyle(styleUrl);
            map.on('style.load', function() {
                const MappointSource = map.getSource('points');
                if (MappointSource) {
                    MappointSource.setData(pointSource.data);
                    // console.log('point update')
                } else {
                    map.addSource('points', pointSource);
                    map.addImage('arrow', { width: width, height: height, data: data }, { "sdf": "true" });
                    console.log('point add')
                }



                SwissLayerIds.forEach(layer => {
                    const visibility = selectedSwissLayers.includes(layer.label) ? 'visible' : 'none';
                    // map.setLayoutProperty(layer.label, 'visibility', visibility);
                    map.addLayer({
                        id: layer.label,
                        type: "raster",
                        source: {
                          type: "raster",
                          tiles: [
                            // `https://wms0.geo.admin.ch/?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=${layerId}&LANG=en`
                            `https://wms.geo.admin.ch?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=${layer.value}`
                          ],
                          tileSize: 256
                        },
                        paint: {
                            "raster-opacity":0.5,
                        }
                      });
                    map.setLayoutProperty(layer.label, 'visibility', visibility);
                });



                layersToUpdate.forEach((layerData) => {
                    const { name, layer, visibilityKey } = layerData;
                    const updatedLayer = {
                    ...layer,
                    layout: {
                        ...layer.layout,
                        visibility: layerVisibility[visibilityKey] ? 'visible' : 'none',
                        // ...getLabelLayerProperties(styleUrl), // Obtenir les propriétés de la couche de texte
                    },
                    };
                    // map.addLayer(layer)
                    map.removeLayer(layer);
                    map.addLayer(layer);
                    map.setLayoutProperty(name, 'visibility', updatedLayer.layout.visibility);

                })


                


            })
          
        }
    }, [styleUrl]);


    // useEffect(() => {
    //     const interval = setInterval(() => { //refreash toutes les x secondes
    //         if(map){
    //         const activeLayers = map.getStyle().layers.filter(layer => {
    //             return map.getLayoutProperty(layer.id, 'visibility') === 'visible';
    //           });
              
    //           console.log('Couches actives :', activeLayers);
    //         }
    //     }, 2000); //refreash toutes les x secondes
    //     if(map){
    //     const activeLayers = map.getStyle().layers.filter(layer => {
    //         return map.getLayoutProperty(layer.id, 'visibility') === 'visible';
    //       });
          
    //       console.log('Couches actives :', activeLayers);
    //     }
    //       return () => clearInterval(interval); // refetch les données lorsque hours est mis à jour
    //   }, []);

    

    
return (
        <Container fluid>
            {/* <Row style={{ position: 'absolute', top: 10, left: 10, zIndex: 2 }}>
                <ToggleButtonGroup type="radio" name="options" defaultValue={styleUrl} onChange={() => handleStyleUrlChange(event.target.value)}>
                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-1" value={'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json'}>
                        OpenStreetMap
                    </ToggleButton>*}

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-2" value={'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json'}>
                        Topo
                    </ToggleButton>

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-3" value={'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte-imagery.vt/style.json'}>
                        Image
                    </ToggleButton>

                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-5" value={'https://tiles.stadiamaps.com/styles/osm_bright.json'}>
                        bright
                    </ToggleButton>*}

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-6" value={'https://tiles.stadiamaps.com/styles/outdoors.json'}>
                        outdoors
                    </ToggleButton>

                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-7" value={'https://tiles.stadiamaps.com/styles/alidade_satellite.json'}>
                        satellite
                    </ToggleButton>*}

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-8" value={'https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json'}>
                        dark
                    </ToggleButton>

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-9" value={'https://tiles.stadiamaps.com/styles/alidade_smooth.json'}>
                        smooth
                    </ToggleButton>


                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-4" value={'https://demotiles.maplibre.org/style.json'}>
                        maplibre
                    </ToggleButton>*}
                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-4" value={'https://api.maptiler.com/maps/outdoor-v2/style.json?key=eCz7oVi0RFfas03fdaFu'}>
    Outdoor
</ToggleButton>*}

                </ToggleButtonGroup>

            </Row> */}

            {/* <Row style={{ position: 'absolute', top: 40, left: 10, zIndex: 3 }}> */}
            <Row style={{ position: 'absolute', top: 25, left: 25, zIndex: 3 }}>
                <Info/>
            </Row>


            <Row style={{ position: 'absolute', bottom: 70, left: 25, zIndex: 3 }}>

            <>
            
                <Button variant="secondary" size="sm" style={{width:'70px', height:'45px', borderRadius:'25px'}} onClick={handleShow}>
                {/* <Button variant="primary" size="sm" className="rounded-circle"> */}
                    <MoreVertIcon/>
                    
                    {layerVisibility.wind && <GiWindsock size={25}/>}
                    {layerVisibility.wbgt && <VscCircleFilled size={25}/>}
                    {layerVisibility.temp && <WiThermometer size={25}/>}
                    {layerVisibility.hum && <WiHumidity size={25}/>}
                    {layerVisibility.press && <WiBarometer size={25}/>}
                    {layerVisibility.lux && <WiDaySunny size={25}/>}
                    {layerVisibility.eau && <WiRaindrop size={25}/>}
                    {layerVisibility.wave && <PiWaveSineBold size={25}/>}
                    {layerVisibility.tide && <GiLowTide size={25}/>}
                    {layerVisibility.rssi && <HiMiniSignal size={25} />}
                    {layerVisibility.BatV && <BsBatteryHalf size={25} />}
                    

                    

                </Button>

                <Offcanvas show={show} onHide={handleClose} placement={'bottom'} style={{height:'80%'}}> {/*'start' | 'end' | 'top' | 'bottom' */}
                    <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Container>
                            <Row>
                                <h3>
                                    Options
                                    <br></br>
                                    <br></br>
                                </h3>
                            </Row>


                            <Row>
                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check1"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.wind}
                                // onChange={(e) => setCheckedWind(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('wind')}
                                >
                                <GiWindsock style={{width:'80%', height:'80%',color:'#'}}/>
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Wind')}</p>
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>

                                </Col>


                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check2"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.wbgt}
                                value="1"
                                // onChange={(e) => setCheckedTemp(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('wbgt')}
                                >
                                    <VscCircleFilled style={{width:'80%', height:'80%',color:'#'}}/>
                                    {/* <BsThermometerHalf style={{width:'80%', height:'80%',color:'#'}}/> */}
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.WBGT')} <p style={{ fontSize: "0.4em", color: "gray", textAlign: 'right', fontWeight:"150" }}> </p> </p>
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>

                                </Col>


                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check3"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.temp}
                                value="1"
                                // onChange={(e) => setCheckedTemp(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('temp')}
                                >
                                    <WiThermometer style={{width:'80%', height:'80%',color:'#'}}/>
                                    {/* <BsThermometerHalf style={{width:'80%', height:'80%',color:'#'}}/> */}
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Temp')}</p>
                                    {/* <p style={{ fontSize: "0.4em", color: "gray", textAlign: 'right', fontWeight:"150" }}>Beta</p>  */}
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>

                                </Col>



                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check4"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.hum}
                                value="1"
                                // onChange={(e) => setCheckedHum(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('hum')}
                                >
                                    <WiHumidity style={{width:'80%', height:'80%',color:'#'}}/>
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Hum')}</p>
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>
                                </Col>

                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check5"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.press}
                                value="1"
                                // onChange={(e) => setCheckedPress(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('press')}
                                >
                                    <WiBarometer style={{width:'80%', height:'80%',color:'#'}}/>
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Press')}</p>
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>
                                </Col>
                                
                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check6"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.lux}
                                value="1"
                                // onChange={(e) => setCheckedLux(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('lux')}
                                >
                                    <WiDaySunny style={{width:'80%', height:'80%',color:'#'}}/>
                                    {/* <IoWaterOutline style={{width:'80%', height:'80%',color:'#'}}/> */}
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Lux')}</p>

                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>
                                </Col>

                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check7"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.water}
                                value="1"
                                // onChange={(e) => setCheckedWater(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('water')}
                                >
                                    <WiRaindrop style={{width:'80%', height:'80%',color:'#'}}/>
                                    {/* <IoWaterOutline style={{width:'80%', height:'80%',color:'#'}}/> */}
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Water')}</p>
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>
                                </Col>


                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check8"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.wave}
                                value="1"
                                // onChange={(e) => setCheckedWater(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('wave')}
                                >
                                    <BsTsunami style={{width:'80%', height:'80%',color:'#'}}/>
                                    {/* <IoWaterOutline style={{width:'80%', height:'80%',color:'#'}}/> */}
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Wave')}</p>
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>
                                </Col>

                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check9"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.waveT}
                                value="1"
                                // onChange={(e) => setCheckedWater(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('waveT')}
                                >
                                    <PiWaveSineBold style={{width:'80%', height:'80%',color:'#'}}/>
                                    {/* <IoWaterOutline style={{width:'80%', height:'80%',color:'#'}}/> */}
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Period')}</p>
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>
                                </Col>


                                <Col xs={4} md={4} lg={2}>
                                <div>
                                <ToggleButton
                                id="toggle-check10"
                                type="checkbox"
                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                checked={layerVisibility.tide}
                                value="1"
                                // onChange={(e) => setCheckedWater(e.currentTarget.checked)}
                                onChange={() => handleLayerToggle('tide')}
                                >
                                    <GiLowTide style={{width:'80%', height:'80%',color:'#'}}/>
                                    {/* <IoWaterOutline style={{width:'80%', height:'80%',color:'#'}}/> */}
                                    <p style={{fontSize:'0.7em'}}>{t('Map.button.Tide')}</p>
                                </ToggleButton>
                                <br></br>
                                <br></br>
                                </div>
                                </Col>

                                {loggedInUser == 'Guillaume' &&
                                <>
                                    <Col xs={4} md={4} lg={2}>
                                        <div>
                                            <ToggleButton
                                                id="toggle-check11"
                                                type="checkbox"
                                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                                checked={layerVisibility.rssi}
                                                // onChange={(e) => setCheckedWind(e.currentTarget.checked)}
                                                onChange={() => handleLayerToggle('rssi')}
                                            >
                                            <HiMiniSignal style={{width:'80%', height:'80%',color:'#'}}/>
                                                <p style={{fontSize:'0.7em'}}>{t('Map.button.rssi')}</p>
                                            </ToggleButton>
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </Col>
  
                                    <Col xs={4} md={4} lg={2}>
                                        <div>
                                            <ToggleButton
                                                id="toggle-check12"
                                                type="checkbox"
                                                variant="outline-primary" size="lg" style={{width:'100%', height:'100%', color:'#', borderRadius:"10px" }}
                                                checked={layerVisibility.BatV}
                                                // onChange={(e) => setCheckedWind(e.currentTarget.checked)}
                                                onChange={() => handleLayerToggle('BatV')}
                                            >
                                            <BsBatteryHalf style={{width:'80%', height:'80%',color:'#'}}/>
                                                <p style={{fontSize:'0.7em'}}>{t('Map.button.BatV')}</p>
                                            </ToggleButton>
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </Col>

                                </>
                                    
                                }


                                
                            </Row>
                        

                            <hr/>
                            <br></br>
                            <Row>
                                <h3>
                                {t('Map.h.Map')}
                                    <br></br>
                                    <br></br>
                                </h3>
                            </Row>
                            <Row>
                            
                            {styleOptions.map((styleOption) => (
                                // <Col  sm={12} lg={8}>
                                <Col xs={6} md={4} lg={2}>
                                <img src={`${styleOption.photo}`} alt=""
                                style={{
                                    width:'100%', 
                                    borderRadius:'10%', 
                                    border: styleUrl === styleOption.value ? '4px solid #FF5923' : '1px solid #0D6EFD',
                                }}
                                onClick={() => handleStyleOptionClick(styleOption.value)}
                                /> 
                                {/* #6C757D */}
                                    {/* <Form.Check
                                        type="radio"
                                        id={`custom-radio-${styleOption.value}`}
                                        label={styleOption.label}
                                        checked={styleUrl === styleOption.value}
                                        /> */}
                                    <br></br>
                                    <br></br>

                                </Col>
                            ))}
                            </Row>
                        

                            <hr/>
                            <br></br>
                            <Row>
                                <h4>Swisstopo</h4><p>{t('Map.h.swisslayer')}</p>
                                    <br></br>
                                    <br></br>
                                
                            </Row>
                            <Row>
                                {SwissLayerIds.map(layer => (
                                // <Col xs={6} md={4} lg={2} key={layer.value}>
                                <Col xs={4} md={2} lg={1} key={layer.value}>
                                <img 
                                        src={layer.photo} 
                                        alt={layer.label}
                                        style={{
                                            width:'100%', 
                                            borderRadius:'10%', 
                                            border: selectedSwissLayers.includes(layer.label) ? '4px solid #FF5923' : '1px solid #0D6EFD',
                                        }}
                                        // onClick={() => handleSwissLayerClick(layer.value)}
                                        onClick={() => handleSwissLayerClick(layer)}
                                    /> 

                                    <br /><br />
                                </Col>
                                ))}
                            </Row>

                            
                        </Container>

                        
                    </Offcanvas.Body>


                </Offcanvas>
            </>

            </Row>

            <Row style={{ position: 'absolute', bottom: 70, left: 25, zIndex: 3 }}>
      
            {/* <Button variant="secondary" size="sm" style={{width:'45px', height:'45px', borderRadius:'50%'}} onClick={handleShow}>
            {/* <Button variant="primary" size="sm" className="rounded-circle"> *}
                <MoreVertIcon/>
            </Button> */}

                {/* <MoreHorizSharpIcon/> */}


            {/* <Button style={{borderRadius:'70%'}}> */}
            {/* <Button class="rounded-circle">
                <MoreVertIcon/>
            </Button> */}

            {/* <div class="rounded-circle">
            <MoreVertIcon/>
            </div> */}

            </Row>
            <Row>

                <div
                    ref={mapContainerRef}
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 55,
                        zIndex: 1
                    }}
                />
            </Row>

        </Container>


    );
};

export default Map;

