{
    "language": "العربية",
    "locale": "ar-SA",

    "install": {
        "title": "قم بتثبيت تطبيق Heatector!",
        "text": "يتم تثبيت Heatector بسهولة على الكمبيوتر أو الهاتف. التطبيق لا يشغل مساحة، لأنه تطبيق ويب!",
        "button": "انقر لتثبيت التطبيق!"
    },

    "contact": {
        "phone": "هاتف",
        "mail": "بريد إلكتروني",
        "button": "انقر لتثبيت التطبيق!"
    },

    "nav": {
        "Direct": "مباشر",
        "Map": "خريطة",
        "Montre": "ساعة"
    },

    "info": {
        "legend": {
            "header": "الأساطير",
            "varbl": "المتغير",
            "descr": "الوصف",

            "hist_forecast": "تاريخ التوقعات",

            "wbt": "درجة حرارة المصباح الرطب",
            "tg": "درجة حرارة الكرة السوداء",
            "ta": "درجة حرارة الهواء",
            "rh": "الرطوبة النسبية",

            "wbgt-M": "WBGT - الأيض",

            "redlimit": "الحد التجريبي",
            "orangelimit": "الحد للأفراد المتأقلمين",
            "yellowlimit": "الحد للأفراد غير المتأقلمين",
            "safezone": "منطقة الأمان",
            "coldzone": "منطقة البرد",
            "coldnote": "انتباه لتنفيذ بعض المواد",
            "M": "تقدير الأيض الطاقي",
            "CAV": "قيمة التعديل للملابس"
        },

        "M": {
            "#0": "راحة",
            "#1": "منخفض",
            "#2": "معتدل",
            "#3": "مرتفع",
            "#4": "مرتفع جدًا"
        },

        "ME": {
            "header": "تصنيف مستويات الأيض الطاقي وفقًا لمعيار ISO 8996",
            "title": {
                "#0": "راحة",
                "#1": "أيض منخفض",
                "#2": "أيض معتدل",
                "#3": "أيض مرتفع",
                "#4": "أيض مرتفع جدًا"
            },
            "text": {
                "#0": "راحة، وضعية جلوس مريحة",
                "#1": "عمل يدوي خفيف (الكتابة، الطباعة، الرسم، الخياطة، المحاسبة)؛ عمل اليدين والذراعين (أدوات صغيرة، التفتيش، التجميع أو فرز المواد الخفيفة)؛ عمل الذراعين والساقين (قيادة مركبة في ظروف عادية، تشغيل مفتاح القدم أو الدواسة).",
                "#2": "عمل يدوي مستمر لليدين والذراعين (التسمير، الملء)؛ عمل الذراعين والساقين (المناورة على مواقع الشاحنات أو المعدات)؛ عمل الذراعين والجذع (العمل بمطرقة هوائية، توصيل المركبات، التجصيص، التعامل مع المواد الثقيلة بشكل متقطع، إزالة الأعشاب الضارة، الحفر، قطف الفواكه أو الخضروات؛ دفع أو سحب عربات خفيفة أو عربات يدوية؛ المشي بسرعة 2.5 إلى 5.5 كم/ساعة على سطح مستو؛ الحدادة).",
                "#3": "عمل شاق للجذع والذراعين؛ نقل المواد الثقيلة؛ الحفر؛ العمل بالمطرقة؛ نشر، تخطيط أو نحت الأخشاب الصلبة؛ الجز اليدوي؛ الحفر؛ المشي بسرعة 5.5 إلى 7 كم/ساعة على سطح مستو.",
                "#4": "نشاط مكثف بوتيرة سريعة قريبة من الحد الأقصى؛ العمل بالفأس؛ الحفر أو الحفر بوتيرة مكثفة؛ صعود السلالم، منحدر أو سلم؛ المشي بسرعة قصيرة؛ الجري على سطح مستو؛ المشي بسرعة أكبر من 7 كم/ساعة على سطح مستو."
            },
            "richtext": {
                "#0": "راحة، وضعية جلوس مريحة",
                "#1": "عمل يدوي خفيف (الكتابة، الطباعة، الرسم، الخياطة، المحاسبة)؛ عمل اليدين والذراعين (أدوات صغيرة، التفتيش، التجميع أو فرز المواد الخفيفة)؛ عمل الذراعين والساقين (قيادة مركبة في ظروف عادية، تشغيل مفتاح القدم أو الدواسة).<br/><br/> الوقوف: الحفر (أجزاء صغيرة)؛ الطحن (أجزاء صغيرة)؛ اللف؛<br/> لف المولدات الصغيرة؛ التصنيع بأدوات منخفضة الطاقة؛ المشي العرضي على سطح مستو (سرعة تصل إلى 2.5 كم/ساعة).",
                "#2": "عمل يدوي مستمر لليدين والذراعين (التسمير، الملء)؛ عمل الذراعين والساقين (المناورة على مواقع الشاحنات أو المعدات)؛ عمل الذراعين والجذع (العمل بمطرقة هوائية، توصيل المركبات، التجصيص، التعامل مع المواد الثقيلة بشكل متقطع، إزالة الأعشاب الضارة، الحفر، قطف الفواكه أو الخضروات؛ دفع أو سحب عربات خفيفة أو عربات يدوية؛ المشي بسرعة 2.5 إلى 5.5 كم/ساعة على سطح مستو؛ الحدادة).",
                "#3": "عمل شاق للجذع والذراعين؛ نقل المواد الثقيلة؛ الحفر؛ العمل بالمطرقة؛ نشر، تخطيط أو نحت الأخشاب الصلبة؛ الجز اليدوي؛ الحفر؛ المشي بسرعة 5.5 إلى 7 كم/ساعة على سطح مستو.<br/><br/>دفع أو سحب عربات يدوية محملة بشكل كبير؛ إزالة الرقائق من الأجزاء المصبوبة؛ وضع كتل الخرسانة.",
                "#4": "نشاط مكثف بوتيرة سريعة قريبة من الحد الأقصى؛ العمل بالفأس؛ الحفر أو الحفر بوتيرة مكثفة؛ صعود السلالم، منحدر أو سلم؛ المشي بسرعة قصيرة؛ الجري على سطح مستو؛ المشي بسرعة أكبر من 7 كم/ساعة على سطح مستو."
            },

            "#0": "راحة",
            "#1": "أيض منخفض",
            "#2": "أيض معتدل",
            "#3": "أيض مرتفع",
            "#4": "أيض مرتفع جدًا"
        },


        "CAV": {
            "header": "قيم التعديل لمؤشر WBGT للملابس (CAV) لمختلف الملابس بالدرجة °C-WBGT",
            "title": {
                "sc": "ملابس بدون غطاء رأس",
                "sc_a": "ملابس العمل",
                "sc_b": "ملابس كاملة",
                "sc_c": "ملابس غير منسوجة من SMS بطبقة واحدة",
                "sc_d": "ملابس غير منسوجة من بولي أوليفين بطبقة واحدة",
                "sc_e": "مئزر مقاوم للبخار بأكمام طويلة وملابس كاملة طويلة",
                "sc_f": "طبقة مزدوجة من الملابس المنسوجة",
                "sc_g": "ملابس مقاومة للبخار بطبقة واحدة، بدون غطاء رأس",
                "sc_h": "ملابس مقاومة للبخار بدون غطاء رأس",
    
                "ac": "ملابس مع غطاء رأس",
                "ac_a": "ملابس العمل + غطاء رأس",
                "ac_b": "ملابس كاملة + غطاء رأس",
                "ac_c": "ملابس غير منسوجة من SMS بطبقة واحدة + غطاء رأس",
                "ac_d": "ملابس غير منسوجة من بولي أوليفين بطبقة واحدة + غطاء رأس",
                "ac_e": "مئزر مقاوم للبخار بأكمام طويلة وملابس كاملة طويلة + غطاء رأس",
                "ac_f": "طبقة مزدوجة من الملابس المنسوجة + غطاء رأس",
                "ac_g": "ملابس مقاومة للبخار بطبقة واحدة مع غطاء رأس",

                "c": "غطاء رأس"
            },
            "text": {
                "sc_a": "ملابس العمل من القماش المنسوج هي الملابس المرجعية.",
                "sc_b": "قماش منسوج يحتوي على قطن معالج.",
                "sc_c": "عملية غير خاصة لتصنيع الأقمشة غير المنسوجة من البولي بروبيلين.",
                "sc_d": "قماش خاص مصنوع من البولي إيثيلين.",
                "sc_e": "يتم تصميم تكوين المئزر للالتفاف حول الجبهة والأجزاء الجانبية من الجسم لحمايته من الانسكابات العرضية للمواد الكيميائية.",
                "sc_f": "تعتبر عمومًا ملابس كاملة فوق ملابس العمل.",
                "sc_g": "التأثير الفعلي يعتمد على مستوى الرطوبة، وفي كثير من الحالات يكون التأثير طفيفًا.",
                "sc_h": "-",

                "ac_g": "التأثير الفعلي يعتمد على مستوى الرطوبة، وفي كثير من الحالات يكون التأثير طفيفًا.",

                "c": "ارتداء غطاء رأس مع أي طقم ملابس. يتم إضافة هذه القيمة إلى قيمة CAV للطقم بدون غطاء رأس أو معدات تنفس.",
                
                "note": "ملاحظة: بالنسبة للملابس التي تتمتع بمقاومة عالية للتبخر، هناك اعتماد على الرطوبة النسبية. تمثل قيم CAV القيمة المحتملة العالية."
            },

            "richtext": {
                "c": "ارتداء غطاء رأس مع أي طقم ملابس.<br/><br/><i>تتم إضافة هذه القيمة إلى قيمة CAV للطقم بدون غطاء رأس أو معدات تنفس.</i>",
                
                "note": "<i>ملاحظة: بالنسبة للملابس التي تتمتع بمقاومة عالية للتبخر، هناك اعتماد على الرطوبة النسبية. تمثل قيم CAV القيمة المحتملة العالية.</i>"
            }
        }
  },


  "Chart": {
    "spotselect": "اختر مكان",
    "spotnotfound": "المكان غير موجود",

    "Metabolisme": "الأيض",

    "start": "البداية",
    "end": "النهاية",


    "Perso": "شخصي",
    "2h3h6h12h24h": "2س - 6س - 12س - 24س",
    
    "2h": "2 س",
    "6h": "6 س",
    "12h": "12 س",
    "24h": "24 س",
    "2d_prev": "توقع 2 ج",


    "forecast": "التنبؤات"
      
  },


    "Map": {
        "h":{
            "Options": "خيارات",
            "Map": "خريطة",
            "swisslayer": "طبقات إضافية"
        },
        "button":{
            "Wind": "رياح",
            "WBGT": "WBGT",
            "Temp": "درجة حرارة",
            "Temperature": "درجة الحرارة",
            "Hum": "رطوبة",
            "Press": "ضغط",
            "Lux": "لكس",
            "Water": "ماء",
            "Wave": "موجة",
            "Period": "فترة",
            "Tide": "مد وجزر",


            "Gust": "عاصفة",
            "min_Wind": "الحد الأدنى للرياح",
            "Sunshine": "ضوء الشمس",
            "precip_intensity": "شدة الهطول",
            "Precip": "هطول",
            "avg_wave_period": "فترة الموجة المتوسطة",
            "dom_wave_period": "فترة الموجة السائدة",
            "wave_dir": "اتجاه الموجة المتوسطة",
            "wave_height": "ارتفاع الموجة الهامة"
        }
    },

    "ExportButton": {
        "export": "تصدير",
        "csv": "تصدير CSV",
        "excel": "تصدير Excel",
        "pdf": "تصدير PDF",
        "pdf_hot": "تصدير PDF - الحرارة",
        "pdf_cold": "تصدير PDF - البرد"
    },


    "Certif": {

        "cert_clim": "شهادة المناخ",
        "cert": "شهادة",
        "hot": "درجات حرارة عالية",
        "cold": "برودة شديدة",
        "lab": "القياسات المأخوذة باستخدام أجهزة استشعار درجة حرارة WBGT المبنية وفقًا لمعيار ISO 7243، والتي تخضع للاختبار² بواسطة مختبر معتمد وفقًا لمعيار ISO 1725.",

        "comp": "شركة",
        "ide": "IDE",
        "lieu": "اسم المكان",
        "long": "خط الطول",
        "lat": "خط العرض",
        "date": "تاريخ إصدار الشهادة",
        "start": "البداية",
        "end": "النهاية",
        "metabo": "الأيض الطاقي",
        "cav": "CAV⁴ (الملابس)",

        "exmp": "مثال",
        "comm": "تعليق",
        "shirt": "الملابس",

        "bp1": "¹ مؤشر درجة حرارة WBGT يقيس الإجهاد الحراري على الجسم، المرتبط بدرجة الحرارة والرطوبة والإشعاع الشمسي المباشر وغير المباشر.",
        "bp2": "² عند الطلب، يمكننا تزويدكم ببروتوكول القياس وإجراءات مراقبة الجودة لدينا.",

        "voir": "عرض",
        "Anx_a": "الملحق أ",
        "Anx_b": "الملحق ب",

        "redPeriode": "الفترات التي تتجاوز الحد للأفراد المتأقلمين",
        "orangePeriode": "الفترات التي تتجاوز الحد للأفراد غير المتأقلمين",
        "coldPeriode": "الفترات التي تكون فيها درجة الحرارة أقل من 5°C",



        "csvxls":{
            "date": "تاريخ",
            "limAcc": "الحد المتأقلم °C",
            "limNAcc": "الحد غير المتأقلم °C",
            "temp": "درجة الحرارة"
        }

    }
    

}
